import React, { useContext } from 'react'
import './css/Dashboard.css'
//import { motion } from 'framer-motion'
import "react-big-calendar/lib/css/react-big-calendar.css"
//import "react-datepicker/dist/react-datepicker.css"
// import {
//   Calendar,
//   dateFnsLocalizer
// } from "react-big-calendar"
// import format from "date-fns/format"
// import parse from "date-fns/parse"
// import startOfWeek from "date-fns/startOfWeek"
// import getDay from "date-fns/getDay"
import { IoAddOutline, IoMenuSharp } from "react-icons/io5";
//import DatePicker from "react-datepicker"


import { ToggleSidebarContext, UserContext } from '../Context/ContextProvider';
import DashboardHeader from '../dashboard/DashboardHeader'
import { Button } from '@mui/material'
import DashboardBody from '../dashboard/DashboardBody'
import DashboardSearch from '../dashboard/DashboardSearch'


// const locales = {
//   "en-US": require("date-fns/locale/en-US"),
// }

// const localizer = dateFnsLocalizer({
//   format,
//   parse,
//   getDay,
//   locales,
//   startOfWeek
// })

// const events = [
//   {
//     title: 'test',
//     allday: true,
//     start: new Date(2024, 1, 8),
//     end: new Date(2024, 1, 12)
//   }
// ]

export default function Dashboard() {
  const { userData } = useContext(UserContext)
  const companyId = userData?.company_id

  const { toggleSidebarValue, isToggleSidebarValue } = useContext(ToggleSidebarContext)
  //const { userData, isUserData } = useContext(UserContext)



  const toggle = () =>{
    isToggleSidebarValue(!toggleSidebarValue)
  }

  
  return (
    <div className='dashboard__container'>
      <PageTitle
        title = {'Dashboard'}
        onMenuClick={toggle}
      />

      <DashboardHeader />
      <DashboardSearch />
      <DashboardBody />

      {/* { loading ? 
      <div className='dashboard__contents'>
            
          <Calendar 
            localizer={localizer} 
            events={events} 
            startAccessor="start" 
            endAccessor="end"
            style={{ height: 550, margin: "40px"}}
            />

      </div>
      :
      <LoadFetch/>
      } */}
    </div>
  )
}

export const PageTitle = ({ title, onMenuClick, createDisable, onCreateClick }) => {
  return (
    <div className='page__title__db'>
      <Button
        disableRipple
        style={{
          background: 'transparent',
          color: 'black'
        }}
        startIcon={<IoMenuSharp />}
        onClick={onMenuClick}
      >
      </Button>
      <h2>{title}</h2>

      <Button 
        style={{
          backgroundColor:  'var(--primary__color)',
          textTransform: 'capitalize',
          marginLeft: '0.5rem',
          display: createDisable ? 'flex' : 'none'
        }}
        startIcon={<IoAddOutline/>}
        variant="contained"
        onClick={onCreateClick}
      >
        <span>Create</span>
      </Button>
    </div>
  );
};