import React from 'react'
import './css/Services.css'
import service_1 from '..//assets/service_2.webp'
import service_2 from '../assets/service_3.webp'
import service_3 from '../assets/service_4.webp'
//import service from '../assets/services_main.jpg'


export default function Services() {
  return (
    <>
    <div className='service-container'>
      <div className='service-title'>
            <h1>Services</h1>
      </div>
      <div className='card-container'>
        <div className='card-service'>
            <img src={service_1} alt="service_1" loading="lazy"/>
            <div className='card-content'>
                <h2>Drone Mapping</h2>
                <p>Lorem Ipsum is simply dummy text of the printing and 
                  typesetting industry. Lorem Ipsum has been the industry's.
                </p>
            </div>
        </div>
        <div className='card-service'>
            <img src={service_3} alt="service_3" loading="lazy"/>
            <div className='card-content'>
                <h2>Soil Nutrition</h2>
                <p>Lorem Ipsum is simply dummy text of the printing and 
                  typesetting industry. Lorem Ipsum has been the industry's.
                </p>
            </div>
        </div>
        <div className='card-service'>
            <img src={service_2} alt="service_2" loading="lazy"/>
            <div className='card-content'>
                <h2>Yield Forcasting</h2>
                <p>Lorem Ipsum is simply dummy text of the printing and 
                  typesetting industry. Lorem Ipsum has been the industry's.
                </p>
            </div>
        </div>
      </div>
    </div>
    </>
  )
}
