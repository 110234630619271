import React, { useState } from 'react'
import './css/InterventionHeader.css'
import { Tab, Tabs } from '@mui/material'

export default function InterventionHeader({ tabOpen, setTabOpen }) {

  const handleTabChange = (e, newVal) => {
    setTabOpen(newVal)
}

  return (
    <div className='intervention__header__container'>
        <Tabs
          value={tabOpen}
          onChange={handleTabChange}
        >
          <Tab style={{textTransform: 'capitalize'}} value={0} label="Intervention" {...a11yProps(0)}/>
          <Tab style={{textTransform: 'capitalize'}} value={1} label="Application" {...a11yProps(1)}/>
        </Tabs>
    </div>
  )
}


function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}