import { create } from "zustand";

export const useMapStore = create((set)=>({
    modalOpen: false,
    isModalOpen: (data) => {
        set({ modalOpen: data})
    }
}))

export const useCommulativeStore = create((set)=>({
    day: 3,
    setDay: (data) => {
        set({ day: data})
    }
}))