import React from 'react'
import { useContext, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import Cookies from 'universal-cookie';
import LoadFetch from '../feature/LoadFetch'

import { TokenAuth, loginContext, UserContext } from '../Context/ContextProvider'

export default function PrivateRoute({ children }) {
  const { tokenAuth, setTokenAuth } = useContext(TokenAuth)
  const { isLoggedIn } = useContext(loginContext)
  const { userData, isUserData } = useContext(UserContext)

  const navigate = useNavigate()
 
  useEffect(() => {
    try{
      if (!tokenAuth){

        navigate('/login');
  
      } else {
  
        const currentTime = Date.now() / 1000; // Convert to seconds
        const cookies = new Cookies()
        const decodedToken = jwt_decode(tokenAuth);
  
        if (decodedToken.exp < currentTime) {
          // Token has expired
          isLoggedIn(false)
          setTokenAuth(null); 
          isUserData(null)

          console.log("session is expired!!")
          cookies.remove("bansot_sess", { path: '/' })
          navigate("/login") // redirect to login if the session is expired
        }
      }
    } catch (error){
      <LoadFetch/>
    }
    
  }, [tokenAuth, setTokenAuth, navigate, isLoggedIn, userData, isUserData]);


  return !tokenAuth ? <Navigate to="/login" /> : children;
 }