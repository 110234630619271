import React, { useRef, useState } from 'react'
import '../components/css/Login.css'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import Cookies from 'universal-cookie';
import { UserContext, loginContext, TokenAuth } from '../Context/ContextProvider'
import { toast } from 'react-toastify'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import { ClipLoader } from 'react-spinners';


import { API_CONFIG } from '../config/ApiConfig'

export default function Login() {
    const [username, setUser] = useState("")
    const [password, setPass] = useState("")
    const [loading, isLoading] = useState(false)
    const navigate = useNavigate()
    
    const { loggedIn, isLoggedIn } = useContext(loginContext)
    const { isUserData, } = useContext(UserContext)
    const { setTokenAuth } = useContext(TokenAuth)

    const usernameRef = useRef(null)
    const passwordRef = useRef(null)
    
    let item = {username, password}
    const loginFetch = async (e) => {
        e.preventDefault();
        isLoading(true)
        
        try {
            const result = await fetch(`${API_CONFIG.HOST}/login/`, {
                
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": "application/json"
                },
                credentials: 'include',

                body: JSON.stringify(item)
            });
            
            const response = await result.json();
            
            // console.log(response)
            // console.log(result.status)
            
            if(result.ok){
                isLoggedIn(true)
                const cookies = new Cookies();
                cookies.set('bansot_sess', response.access_token, { path: '/' });

                ///localStorage.setItem('access_token', JSON.stringify(result))
                setTokenAuth(cookies)
                isUserData(jwt_decode(response.access_token))
            }else{
                isLoading(false)
            }

            if(!result.ok){
                usernameRef.current.value = ''
                passwordRef.current.value = ''

                toast.error('Wrong Credentials', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                    });
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    
    if(loggedIn){
        setTimeout(() => {
            
            isLoading(false)
            navigate('/app/farm'); // Redirect to the desired page
        }, 400);
    } 

  return (
    <div className='login-container'>
        <span className='login-img'></span>
        <div className='login-form-container'>
            <form>               
                <div className='login-fields'>
                    <div className='login__title'>
                        <h2>BANAT</h2>
                    </div>
                    <div className='login__description'>
                        <p>Banana Nutritional Aerial Technology</p>
                    </div>
                    <div className='input-fields'>
                        <TextField
                            fontFamily='Raleway'
                            required
                            type='text'
                            label='Username'
                            ref={usernameRef}
                            size='small'
                            onChange={(e) => setUser(e.target.value)}
                        />
                        <TextField
                            required
                            type='password'
                            label='Password'
                            ref={passwordRef}
                            autoComplete='off'
                            size='small'
                            onChange={(e) => setPass(e.target.value)}
                        />
                    </div>
                    
                    <div className='login__action'>
                        <Button 
                            style={{
                                backgroundColor:  'var(--primary__color)',
                                textTransform: 'capitalize',
                                padding: '0.5rem 0.0.5rem',
                                width: '100px'
                            }}
                            disabled={loading ? true : false}
                            loading={loading.toString()}
                            loadingposition="start" 
                            startIcon={ <ClipLoader color="white" loading={ loading } size={15}/>}
                            variant="contained"
                            onClick={loginFetch}
                            >
                            <span>Login</span>
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    </div>
  )
}
