import { create } from "zustand";

export const useChartStore = create((set)=>({
    chartData: null,
    setChartData: (data) => {
        set({ chartData: data})
    }
}))

export const useChartSearchStore = create((set)=>({
    gridSearchValue: '',
    setGridSearchValue: (data) => {
        set({ gridSearchValue: data})
    }
}))
