import { axiosInstance } from "./ApiMethods"
import { useMutation, useQuery } from "@tanstack/react-query";


// POST INTERVENTION APPLICATION
export const AddApplication = () =>{
    return useMutation({
      mutationKey: ['add-application'],
      mutationFn: async (data)=> {
        try{
          const res = axiosInstance.post(`intervention/application/create`, data, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          return res
        }catch(error){
          return error
        }
      }
    })
}


// GET REQUEST FOR ALLT HE LAYERS OF THE FARM
export const FetchApplications = (company_id) =>{
    return useQuery({
      queryKey: ['fetch-applications', company_id],
      queryFn: async () =>{
        try{
          const res = axiosInstance.get(`intervention/application/list/${company_id}`)
          return res.then(res=>res.data)
        }catch(error){
          return error
        }
      },    
      retry: false,
      refetchOnWindowFocus: false,
    })
}

// GET REQUEST FOR ALL INTERVENTION ACTIVITY
export const FetchInterventionActivityLogs = (company_id) =>{
    return useQuery({
      queryKey: ['fetch-intervention-logs', company_id],
      queryFn: async () =>{
        try{
          const res = axiosInstance.get(`intervention/activity/list/${company_id}`)
          return res.then(res=>res.data)
        }catch(error){
          return error
        }
      },    
      retry: false,
      refetchOnWindowFocus: false,
    })
}