import React, { useEffect } from 'react'
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Legend,
    Tooltip,
    Title,
} from 'chart.js'
import { Line } from 'react-chartjs-2';
import './css/LineChartView.css'
import { useChartStore } from '../../store/chartStore';

ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Legend,
    Tooltip,
    Title,
)

const useChartStoreSelector = (state) => ({
    chartData: state.chartData,
    setChartData: state.setChartData
})

export default function LineChartView({ getNutrientGridsList, tabOpen }) {

    // CUSTOM HOOK FOR USESTATE CHART STORE
    const { chartData, setChartData } = useChartStore(useChartStoreSelector)

    //console.log('chart data', chartData)
    //const nitrogenValues = getNutrientGridsList.data.map(level => parseFloat(level.nitrogen));
    
    useEffect(() => {
        if (getNutrientGridsList && getNutrientGridsList.length > 0) {
            // const labels = getNutrientGridsList.flatMap(grid =>
            //     grid.nutrient_related.map(() => grid.upload_raster_date)
            // );
            //console.log('labels ',labels)
            const uniqueDates = new Set();
            const labels = [];

            const ndviData = [];
            const variData = [];
            const gliData = [];
            const exgData = [];
            const nitrogenValues = [];

            getNutrientGridsList.forEach((grid) => {
                const date = grid.upload_raster_date;
                grid.nutrient_related.forEach((nutrient) => {
                    if (!uniqueDates.has(date)) {
                        uniqueDates.add(date);
                        labels.push(date);
                    } else {
                        labels.push(''); // Push an empty string for duplicate dates
                    }

                    ndviData.push(nutrient.ndvi*10);
                    variData.push(nutrient.vari*20);
                    gliData.push(nutrient.gli*20);
                    exgData.push(nutrient.exg/10);
                    nitrogenValues.push(nutrient.nitrogen);
                });
            });

            setChartData({
                labels,
                datasets: [
                    {
                        label: 'NDVI',
                        data: ndviData,
                        borderColor: 'rgba(255, 99, 132, 1)',
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        fill: false,
                        tension: 0.2
                    },
                    {
                        label: 'VARI',
                        data: variData,
                        borderColor: 'rgba(54, 162, 235, 1)',
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        fill: false,
                        tension: 0.2
                    },
                    {
                        label: 'GLI',
                        data: gliData,
                        borderColor: 'rgba(75, 192, 192, 1)',
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        fill: false,
                        tension: 0.2
                    },
                    {
                        label: 'EXG',
                        data: exgData,
                        borderColor: 'rgba(153, 102, 255, 1)',
                        backgroundColor: 'rgba(153, 102, 255, 0.2)',
                        fill: false,
                        tension: 0.2
                    },
                    {
                        label: 'Nitrogen',
                        data: nitrogenValues,
                        backgroundColor: 'rgba(249, 180, 45, 0.5)',
                        borderColor: 'rgba(249, 180, 45, 1)',
                        borderWidth: 2,
                        fill: false,
                        tension: 0.2
                    }
                ],
            });
        }
    }, [getNutrientGridsList, setChartData]);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Nutrient Levels',
            },
        },
        scales: {
            x: {
                title: {
                    display: false,
                    text: 'Samples',
                },
                ticks: {
                    maxRotation: 90,
                    minRotation: 90,
                    autoSkip: false,
                    callback: function(val, index) {
                      // This will display every label
                      return this.getLabelForValue(val);
                    }
                  },
                  grid: {
                    display: true
                  }
            },
            y: {
                title: {
                    display: true,
                    text: 'Nitrogen Levels',
                },
            },
        },
        maintainAspectRatio: false
    };

  return (
    <div 
        className='line__chartview__container'
        hidden ={tabOpen === 0 ? false : true}
    >
        {chartData && (
                <Line
                    data={chartData}
                    options={options}
                />
            )}
    </div>
  )
}
