import React, { useContext, useState } from 'react'
import './css/UsersTable.css'
import { useMediaQuery } from 'react-responsive';
import { Button } from '@mui/material';
import { FaExchangeAlt } from "react-icons/fa";
import { toTitleCase } from '../../utils/fileUtils';
import RoleModal from './RoleModal';
import { UserContext } from '../../Context/ContextProvider';

export default function UsersTable({ displayUsers, pageNumber, setPageNumber }) {
    const { userData } = useContext(UserContext)
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const [roleModal, isRoleModal] = useState(false)
    const [userId, setUserId] = useState(null)
    const [role, setRole] = useState(null)
   

  return (
    <div className='company__usersTable__container'>
        <table>
          <thead>
            <tr>
              <th style={{width: '60px'}}>Username</th>
              <th style={{width: '100px'}}>First name</th>
              <th style={{width: '100px'}}>Last name</th>
              <th style={{width: '60px'}}>Company</th>
              <th style={{width: '60px'}}>Role</th>
              <th style={{width: '80px'}}>Actions</th>
              {/* {userData?.role !== 'Manager' && (<th style={{width: '80px'}}>Actions</th>)} */}
            </tr>
          </thead>
          <tbody>
            { 
                displayUsers && displayUsers
                .sort((a,b)=> a.id < b.id ? 1 : -1 )
                    ?.map(item => {

                        const HandleRoleUpdate = (id, role) => {
                            setUserId((prevFarmUserId) => {
                              return id;
                            });
                            setRole((prevRole) => {
                              return role;
                            });
                            isRoleModal(true)
                        }
                        
                    return (
                        <tr key={item.id}>
                          
                          <td>{item.username}</td>
                          <td>{toTitleCase(item.first_name)}</td>
                          <td>{toTitleCase(item.last_name)}</td>
                          {
                            !isMobile &&(
                              <>
                                <td>{item.company.company_name}</td>
                                <td>{item.role}</td>
                              </>
                            ) 
                          }
                          <td>
                            <div className='userTable__actions'>
                                <Button
                                    disableRipple
                                    style={{
                                        background: 'transparent'
                                    }}
                                    onClick={()=>HandleRoleUpdate(item.id, item.role)}
                                >
                                    <FaExchangeAlt />
                                </Button>
                            </div>
                          </td>
                          {/* {
                            userData?.role !== 'Manager' &&
                          (<td>
                            <div className='userTable__actions'>
                                <Button
                                    disableRipple
                                    style={{
                                        background: 'transparent'
                                    }}
                                    onClick={()=>HandleRoleUpdate(item.id, item.role)}
                                >
                                    <FaExchangeAlt />
                                </Button>
                            </div>
                          </td>)
                          } */}
                        </tr>
                      )
                    })
                  }
          </tbody>
        </table>

        {!displayUsers?.length &&
                (<p className='records__warning'>No records found.</p>)
        }

        { 
            roleModal ?
                <RoleModal
                  isRoleModal = { isRoleModal }
                  userId = { userId }
                  role = { role }
                />
                :
                  ''
            }
      </div>
  )
}
