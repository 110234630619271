
import './App.css';
import { Routes, Route } from 'react-router-dom'
import Farm from './components/Farm';
import Dashboard from './components/Dashboard'
import Weather from './components/Weather'
import Create from './components/Create'

import Home from './main/Home'
import About from './main/About';
import Services from './main/Services'
import Contact from './main/Contact'
import Login from './components/Login'

import RootLayout from './layout/RootLayout';
import MainAppLayout from './layout/MainAppLayout';

import FarmView from './components/FarmView';
import DBEditFarm from './feature/DBEditFarm';
import PrivateRoute from './protectedRoutes/PrivateRoute';
import NotFound from './components/NotFound';
import AccountManage from './feature/AccountManage';

import { ContextProvider } from './Context/ContextProvider';
import Reports from './components/Reports';
import Intervention from './components/Intervention';
import WeatherHistory from './feature/WeatherHistory';
import { ToastContainer } from 'react-toastify';
import ChartView from './components/ChartView';


function App() {
  return (
     <div className='app-container'>
      <div className='main-page-content'>
        <ContextProvider>
          <Routes>
            <Route element={<RootLayout/>}>
              <Route path='/' element={<Home/>}/>
              <Route path='/About' element={<About/>}/>
              <Route path='/Services' element={<Services />}/>
              <Route path='/Contact' element={<Contact />}/>
            </Route>  
            
            <Route path='/login' element={<Login/>}/>
            
              
            <Route element={
                  <PrivateRoute>
                    <MainAppLayout/>
                  </PrivateRoute>
                }>
              <Route path="/app/dashboard" element={<Dashboard/>}/>
              <Route path='/app/farm' element={<Farm/>}/>

              <Route path='/app/farm/q' element={<FarmView/>}>
              </Route>

              <Route path="/app/farm/chart" element={<ChartView/>} />

              <Route path="/app/farm/edit" element={<DBEditFarm/>} />
              <Route path="/app/farm/create" element={<Create/>}/>
              <Route path="/app/weather" element={<Weather/>}/>
              <Route path="/app/weather/history" element={<WeatherHistory/>}/>
              <Route path="/app/accounts" element={<AccountManage/>}/>
              <Route path="/app/reports" element={<Reports/>}/>
              <Route path="/app/intervention" element={<Intervention/>}/>
              {/* <Route path="/app/survey" element={<Maps/>}/> */}
            </Route>

            <Route path="*" element={<NotFound/>}/>
          </Routes>
        </ContextProvider>
        <ToastContainer />
      </div> 
    </div>
  );
}

export default App;
