import React, { useContext, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ToggleSidebarContext, UserContext } from '../Context/ContextProvider'
import { FetchBlockDetails , UpdateBlockDetail } from '../api/ApiMethods';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { FaRegBuilding } from "react-icons/fa";
import RasterUploadModal from './RasterUploadModal';
import NutrientThreshold from './NutrientThreshold';
import './css/DBEditFarm.css'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select'

import { FaCloudMoonRain } from "react-icons/fa"
import { FaDroplet } from "react-icons/fa6"



import RasterListTable from './FarmComponent/RasterListTable';
import { useFormik } from 'formik';
import WeatherData from './Modal/WeatherData';
import SoilSensor from './Modal/SoilSensor';
import { PageTitle } from '../components/Dashboard';

export default function DBEditFarm() {
    const ButtonProperty = {
        size: 'small',
    }
    const { userData } = useContext(UserContext)


    const navigate = useNavigate()
    const [query] = useSearchParams()
    const bid = query.get('bid')
    const uid = query.get('uid')
    const company = query.get('company')
    
    // const { data: getUser } = FetchUser(uid)
    const { data: getBlockDetails, isLoading: isLoadingFarmDetails } = FetchBlockDetails(bid)
    //console.log(getBlockDetails)

    const { mutate: updateFarm, isLoading } = UpdateBlockDetail()
    
    const formik = useFormik({
        initialValues: {
            block_id : getBlockDetails?.data.block.id || '',
            //farmType:   getBlockDetails?.data.block.farmType || '',
            location:  getBlockDetails?.data.block.location || '',
            status:  getBlockDetails?.data.block.status || '',
        },
        enableReinitialize: true,
        onSubmit: values => {
            //console.log('values', values)
            const block_updated_data = new FormData();
            //farm_updated_data.append('farmType', values.farmType);
            block_updated_data.append('location', values.location);
            block_updated_data.append('status', values.status);
            block_updated_data.append('block_id', values.block_id);

            updateFarm(block_updated_data,{
                onSuccess: (data)=>{
                    // console.log(data)
                    toast.success('Update successfully.', {
                        position: "top-right",
                        autoClose: 2500,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                        pauseOnFocusLoss: false,
                        });
                    },
                    onError: (error)=>{
                        console.log(error)
                    }
                })
        }
    })

    const [openModalRaster, isOpenModalRaster] = useState(false)
    const [openModalNutrient, isOpenModalNutrient] = useState(false)
    const [weatherModal, setWeatherModal] = useState(false)
    const [sensorModal, setSensorModal] = useState(false)

    

    const { toggleSidebarValue, isToggleSidebarValue } = useContext(ToggleSidebarContext)

    const toggle = () =>{
      isToggleSidebarValue(!toggleSidebarValue)
    }

  return (
    <div className='db__edit__farm__container'>
        <PageTitle
            title={'Farm Details'}
            onMenuClick={toggle}
        />

        <div className='db__farm__body_container'>
            <div className='modal__farm_body'>
                <div className='edit__input_container'>
                    <div className='company__farm__title'>
                        <FaRegBuilding size={'2em'}/>
                        <h3> <strong> {company.toUpperCase()}</strong></h3>
                        <h4>{getBlockDetails?.data.block.farm.farmName}-{getBlockDetails?.data.block.blockName}</h4>

                            { 
                                userData?.role === 'Admin' || userData?.role === 'Manager' || userData?.role === 'Customer'?
                                <div className='sensors__modal__container'>
                                    <Button
                                        style={{
                                            textTransform: 'capitalize',
                                        }}
                                        disableRipple
                                        fullWidth
                                        variant="contained"
                                        startIcon={
                                        <FaCloudMoonRain 
                                            size={'18px'}
                                        />
                                        }
                                        onClick={()=>setWeatherModal(true)}
                                    >
                                        <span>Weather</span>
                                    </Button>

                                    <Button
                                        style={{
                                            textTransform: 'capitalize',
                                        }}
                                        disableRipple
                                        fullWidth
                                        variant="contained"
                                        startIcon={
                                        <FaDroplet 
                                            size={'18px'}
                                        />
                                        }
                                        onClick={()=>setSensorModal(true)}
                                    >
                                        <span>Sensors</span>
                                    </Button>
                                </div>
                            :
                            ''
                            }
                        </div>

                        <form onSubmit={formik.handleSubmit}>
                            <div className='input__fields__db'>
                                <div className='selected__inputs__modal'>
                                    <input  className='farm__id'
                                        type='number'
                                        name='farm_id'
                                        value={formik.values.id}
                                        onChange={formik.handleChange}
                                    />

                                    <div className='selected__input__row'>
                                        <TextField
                                            disabled = {isLoading || userData?.role === 'Drone Operator'}
                                            fullWidth 
                                            name = 'location'
                                            id = "outlined-basic" 
                                            label = "Location" 
                                            size={ButtonProperty.size}
                                            variant = "outlined" 
                                            value = {formik.values.location}
                                            onChange = {formik.handleChange}
                                        />
                                    </div>
                                            
                                    <div className='selected__input__row'>
                                        <TextField
                                            fullWidth 
                                            disabled = {isLoading || userData?.role === 'Drone Operator'}
                                            name = 'status'
                                            value = {formik.values.status}
                                            id = "outlined-basic" 
                                            label = "Status" 
                                            variant = "outlined" 
                                            size={ButtonProperty.size}
                                            onChange = {formik.handleChange}
                                        />
                                    </div>

                                </div>
                            </div>
                                
                            <div className='modal__farm__actions'>
                                <Button
                                    style={{
                                    backgroundColor:  'var(--no_background)',
                                    color: 'var(--fontColor__secondary)',
                                    textTransform: 'capitalize',
                                    boxShadow: 'none',
                                    }}
                                    disableRipple
                                    variant="contained"
                                    onClick={()=>navigate('/app/farm')}
                                    >
                                    <span>Back</span>
                                </Button>

                                { 
                                    userData?.role === 'Admin' || userData.role === 'Manager' || userData?.role === 'Customer'?
                                    <Button 
                                        style={{
                                        backgroundColor:  'var(--primary__color)',
                                        textTransform: 'capitalize'
                                        }}
                                        disabled={isLoading}
                                        loading={isLoading.toString()}
                                        loadingposition="start"
                                        startIcon={ <ClipLoader color="white" loading={ isLoading } size={15}/>}
                                        variant="contained"
                                        //onClick={ farmUpdateRequest }
                                        type='submit'
                                        >
                                        <span>Update</span>
                                    </Button>
                                :
                                ''    
                                }
                            </div>
                        </form>
                </div>

                <RasterListTable
                    getRasterDetails = { getBlockDetails?.data.grid }
                    getFarmDetails = { getBlockDetails?.data.block }
                    blockId = { bid }
                    modalValue ={ isOpenModalRaster }
                    isLoadingFarm = { isLoadingFarmDetails }
                />
            </div>
        </div>

        {
            openModalRaster && (
                <RasterUploadModal 
                    modalValue ={ isOpenModalRaster }
                    farmName = { getBlockDetails?.data.block.farm.farmName }
                    company = { company }
                    blockName = { getBlockDetails?.data.block.blockName }
                />
        )}

        {
            openModalNutrient && (
                <NutrientThreshold
                    modalValue ={ isOpenModalNutrient }
                    blockId = { bid }
                />
        )}

        {
            weatherModal && (
                <WeatherData 
                    uid={uid}
                    company={company}
                    setWeatherModal={setWeatherModal}
                />
            )
        }

        {
            sensorModal && (
                <SoilSensor 
                    setSensorModal={setSensorModal}
                />
            )
        }
    </div>
  )
}
