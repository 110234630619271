import './css/FarmViewMenu.css'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { GetGridLayerDetails, GetGridsList, GetNitrogenGrids } from '../api/ApiMethods'
import { FaRegEye } from "react-icons/fa"
//import { GEO_CONFIG } from '../config/ApiConfig';

import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, InputLabel, MenuItem, Select } from '@mui/material';
import { useRasterBoundsStore, useRasterDateStore, useRasterGridStore } from '../store/rasterStore';
import { BeatLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import AreaChartPercentage from './ChartViewComponent/AreaChartPercentage';
import { useMapStore } from '../store/mapStore';
import { useGridResultStore } from '../store/gridStore';
import { formatDate } from '../utils/fileUtils';

const useRasterSetDateSelector = (state)=> state.setDateSearch
const useRasterSetGridSelector = (state)=> state.setGridSearch
const useRasterDateSelector = (state)=> state.date
const useRasterGridSelector = (state)=> state.grid
const useRasterBoundSetLonLatSelector = (state)=> state.setLonLat
const useMapStoreSetModalSelector = (state)=> state.isModalOpen
const useGridResultStoreSelector = (state)=> state.setGridResult

export default function FarmViewMenu({ setLayerName, isMobile, isMapMenuOpen, 
    setVegetationStatus, vegetationStatus, setLayer , setGridChartModal, gridChartModal, 
    setNutrientGridData, setPoints, setGeoLoaderWFS, setWeather, setShouldFetch, shouldFetch, setSensor }) {
 
    const date = useRasterDateStore(useRasterDateSelector)
    const grid = useRasterGridStore(useRasterGridSelector)
    const setLonLat = useRasterBoundsStore(useRasterBoundSetLonLatSelector)
    const isModalOpen = useMapStore(useMapStoreSetModalSelector)
    const setGridResult = useGridResultStore(useGridResultStoreSelector)

    const [areaPercentage, setAreaPercentage] = useState('')
    const [areaSize, setAreaSize] = useState('')

    const [query] = useSearchParams()
    const bid = query.get('bid')
    const fid = query.get('fid')

    const { data: GetSearchGridLayer, isLoading: loaderMap, isFetching } = GetGridLayerDetails(fid, date, grid, shouldFetch)
    //console.log('GRID SEARCH LAYER: ', GetSearchGridLayer)
        
  //  const { data: getIndividualLayer } = GetIndividualLayer(fid) //menuId ? menuId : defaultLayerId
   // console.log('GIL:', getIndividualLayer)
    
    // const { data: getNitrogenGrids } = GetNitrogenGrids(bid)
    // console.log(getNitrogenGrids)
    
    const { data: getGridList, isLoading} = GetGridsList(bid)
   // console.log('parent',getGridList)

    useEffect(()=>{
        setLonLat(GetSearchGridLayer?.grid_data.bounds)
        setLayer(GetSearchGridLayer?.grid_data.raster_file)
        setWeather(GetSearchGridLayer?.commulative_weather_data)
        setSensor(GetSearchGridLayer?.commulative_sensor_data)
    },[GetSearchGridLayer, setLonLat, setLayer, loaderMap, setSensor, setWeather])


    // const handleGridChartData = (e) => {
    //     setGridChartModal(!gridChartModal)
    //     setNutrientGridData(getNitrogenGrids)
    // }

    const handleRGBInput = (e) => {
        let defaultRGBVal = e.target.value

        if (defaultRGBVal === 'RGB'){
            setLayer((prevState) => ({
                ...prevState,
                layername: GetSearchGridLayer?.grid_data.raster_file
            }))
            setVegetationStatus('')
        }
    }

    const handleRadioInput = (e) =>{

        let vegetationIndex = e.target.value
        //console.log("RADIO VALUE:= ", vegetationIndex)

        if(vegetationIndex === 'NDVI'){
            setPoints(GetSearchGridLayer?.grid_data.predicted_ndvi_related)
            setVegetationStatus('NDVI')
            setGeoLoaderWFS('')
            setAreaPercentage('')
            setAreaSize('')
        }

        if(vegetationIndex === 'VARI'){
            setLayerName(GetSearchGridLayer?.grid_data.vari_file)
            //isPlantHealth(true)
            setVegetationStatus('VARI')
            setPoints('')
            setAreaPercentage(GetSearchGridLayer?.grid_data.vari_area_category_percentage)
            setAreaSize(GetSearchGridLayer?.grid_data.vari_area_percentage)
        }

        if(vegetationIndex === 'GLI'){
            setVegetationStatus('GLI')
            setLayerName(GetSearchGridLayer?.grid_data.gli_file)
            setPoints('')
            setAreaPercentage(GetSearchGridLayer?.grid_data.gli_area_category_percentage)
            setAreaSize(GetSearchGridLayer?.grid_data.gli_area_percentage)
        }

        if(vegetationIndex === 'EXG'){
            setLayerName(GetSearchGridLayer?.grid_data.exg_file)
            setVegetationStatus('EXG')
            setPoints('')
            setAreaPercentage(GetSearchGridLayer?.grid_data.exg_area_category_percentage)
            setAreaSize(GetSearchGridLayer?.grid_data.gli_area_percentage)
        }
    }
    
    const handleNitrogenTableClick = () =>{
        isModalOpen(true)
        setGridResult(GetSearchGridLayer?.grid_data)
    }

    const finalDate = formatDate(GetSearchGridLayer?.grid_data.upload_raster_date)
   

  return (
    <div className='farmview__menu__container'>
        {
            getGridList?
                <MemoizedSmartGridSearch
                    getGridList = { getGridList }
                    isLoading = { isLoading }
                    setLayerName = { setLayerName }
                    setVegetationStatus = { setVegetationStatus }
                    setPoints = { setPoints }
                    setShouldFetch = { setShouldFetch }
                    
                />
            :''
        }

        {!isFetching?
        <div className='farmview__contents'>
            <div className='farm__details'>
                <h4>Grid Details</h4>
                <label><strong>Grid name:</strong> {GetSearchGridLayer?.grid_data.gridName}</label>
                {/* <label><strong>Location:</strong> {data.location}</label> */}
                <label><strong>Size:</strong> {GetSearchGridLayer?.grid_data.ha.toFixed(4) } ha</label>
                <label><strong>Flight Date:</strong> {finalDate}</label>
            </div>

            <div className='farmview__index'>
                <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label" className='farmview__index__title'>Vegetation Index</FormLabel>
                        <RadioGroup
                            aria-labelledby="default-radio-value"
                            value={'RGB'}
                            name="rgb-radio"
                            onChange={ handleRGBInput }
                            >
                            <FormControlLabel value="RGB" control={<Radio size='small'/>} label="RGB" />
                        </RadioGroup>

                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={ vegetationStatus }
                            name="radio-buttons-group"  
                            onChange={ handleRadioInput }
                        >
                        <div className='index__container'>
                            <div className='index__radio__control'>
                                {
                                    !GetSearchGridLayer?.predicted_ndvi_related ?
                                    <FormControlLabel value='NDVI' control={<Radio size='small'/>} label="NDVI" />  
                                :
                                    ''
                                }
                                <FormControlLabel value='VARI' control={<Radio size='small'/>} label="VARI" />
                                <FormControlLabel value="GLI" control={<Radio size='small'/>} label="GLI" />
                                <FormControlLabel value="EXG" control={<Radio size='small'/>} label="EXG" />
                            </div>
                        </div>
                    </RadioGroup>
                </FormControl>
            </div>
            
            <div className='farmview__nutrient__threshold'>
                <div className='nutrient__header__container'>
                    <h4>Nitrogen Grid Levels</h4>
                    {/* {getNitrogenGrids && getNitrogenGrids?.data && getNitrogenGrids?.data.length > 0 ? (
                    <Button
                        style={{
                            //backgroundColor:  'var(--primary__color)',
                            textTransform: 'capitalize',
                        }}
                        variant='outlined'
                        size='small'
                        startIcon={<FaRegEye />}
                        onClick={handleGridChartData}
                    >
                        View Chart/Table
                    </Button>
                    ) : ''
                    } */}

                     <Button
                        size='small'
                        variant='outlined'
                        disableRipple
                        style={{
                            textTransform: 'capitalize'
                        }}
                        onClick={handleNitrogenTableClick}
                    >
                        Nitrogen Table
                    </Button>
                </div>
                
                {/* <div className='nitrogen__summary_subheader'>
                    <h5>Summary</h5>
                    <Button
                        size='small'
                        variant='outlined'
                        disableRipple
                        style={{
                            textTransform: 'capitalize'
                        }}
                    >
                        Nitrogen Table
                    </Button>
                </div> */}

                <div className='nitrogen__body__summary'>
                    {
                        vegetationStatus && vegetationStatus !=='NDVI' ?
                        <AreaChartPercentage 
                            vegetationStatus = {vegetationStatus}
                            areaPercentage = { areaPercentage }
                            areaSize = { areaSize }
                        />
                        :
                        ''
                    }
                </div>

                <NutrientRecommendation />
            </div>

        </div>
        :
        <div className='farmview__contents'>
            <div className='farm__view__loader'>
                <BeatLoader 
                    color='green' 
                    loading={true}
                    size={'13px'}
                />
            </div>
        </div>
        
        }

    </div>
  )
}

const SmartGridSearch = ({getGridList, setShouldFetch, isLoading, setPoints, setVegetationStatus, setLayerName}) => {
    const ButtonProperty = {
        size: 'small',
    }
    const navigate = useNavigate()
    const [query] = useSearchParams()
    //const rid = query.get('rid')
    const bid = query.get('bid')
    const fid = query.get('fid')
    const company = query.get('company')
    const blockName = query.get('block-name')

    const setDateStore = useRasterDateStore(useRasterSetDateSelector)
    const setGridStore = useRasterGridStore(useRasterSetGridSelector)

    const [selectedGrid, setSelectedGrid] = useState('');
    const [selectedDate, setSelectedDate] = useState('');

    const handleGridChange = (e) => {
        setSelectedGrid(e.target.value);
        setSelectedDate(''); // Reset date when grid changes
        setShouldFetch(false)
    }

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
        setShouldFetch(false)
    }

    const searchLayer = () => {
        if(selectedDate === ''){
            toast.error('Provide a date.', {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                pauseOnFocusLoss: false,
                theme: "light",
            });
        }else{
            setDateStore(selectedDate)
            setGridStore(selectedGrid)
            setVegetationStatus('')
            setLayerName('')
            setPoints('')

            setShouldFetch(true)
            navigate(`/app/farm/q?fid=${fid}&bid=${bid}&company=${company}&block-name=${blockName}&gid=${selectedDate}`)
        }
    }

    if(isLoading){
        return <div className='smartGridSearch__loading'><BeatLoader color='green' loading={true} size={'10px'}/></div>
    }    

    return(    
        <div className='smartGridSearch__container'>
            <h4>Advanced Search</h4>
            <FormControl 
                fullWidth
                >
                <InputLabel 
                    id="grid-label"
                    shrink
                >
                    Grid
                </InputLabel>
                <Select
                    notched
                    labelId="grid-label"
                    id="grid-select"
                    value={selectedGrid}
                    label="grid"
                    name="gridLayer"
                    size={ButtonProperty.size}
                    onChange={handleGridChange}
                >
                {
                    Object.keys(getGridList)?.map(gridName => (
                        <MenuItem 
                            key={gridName} 
                            value={gridName}>
                            {gridName}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
         
            <FormControl 
                fullWidth
                >
                <InputLabel 
                    id="date-label"
                    shrink
                >
                    Date
                </InputLabel>
                <Select
                    notched
                    labelId="date-label"
                    id="date-select"
                    value={selectedDate}
                    label="date"
                    name="dateLayer"
                    size={ButtonProperty.size}
                    onChange={handleDateChange}
                    disabled={!selectedGrid}
                >
                    {selectedGrid && getGridList[selectedGrid]?.map(item => (
                        <MenuItem 
                            key={item.id} 
                            value={item.id}
                        >
                            {formatDate(item.upload_raster_date)}
                        </MenuItem>              
                    ))}
                </Select>
            </FormControl>

            <Button
                disabled={!selectedDate && !selectedGrid}
                fullWidth
                variant='contained'
                size={ButtonProperty.size}
                style={{
                textTransform: 'capitalize',
                }}
                onClick={searchLayer}
            >
                Search
            </Button>
        </div>
    )
}

// Memoize the SmartGridSearch component
const MemoizedSmartGridSearch = React.memo(SmartGridSearch);


const NutrientRecommendation = () => {
    return(
        <div className='nutrient__recommendation__container'>
            <h4>Nutrient Recommendation</h4>
            <table className='nutrient__recommendation__table'>
                <thead>
                    <tr>
                        <th>Low</th>
                        <th>Normal</th>
                        <th>High</th>
                        <th>Fertilizer</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>39g</td>
                        <td>45g</td>
                        <td>54g</td>
                        <td>Nitrogen</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}