import React, { useContext, useState } from 'react'
import './css/ReportContent.css'
import { FiPrinter } from "react-icons/fi";
import { Button, Tab, Tabs } from '@mui/material';
import { UserContext } from '../Context/ContextProvider';
import { useReportGridChartModal, useReportSetVegetationData, useReportStore } from '../store/reportStore';
import { formatDate, getCategoryColor } from '../utils/fileUtils';
import { GenerateReport } from '../api/ApiMethods';
import { ClipLoader } from 'react-spinners';
import { exportReportToXLSX } from '../export/export_xlsx';

export default function ReportContent() {
    const { userData } = useContext(UserContext)

    // Access state
    const farmName = useReportStore((state) => state.farmName);
    const blockName = useReportStore((state) => state.blockName);
    const gridName = useReportStore((state) => state.gridName);
    const month = useReportStore((state) => state.month);
    
    
    const [selectedIndex, setSelectedIndex] = useState('');

    const [tabOpen, setTabOpen] = useState(2)

    //const{ data: reportData } = useGenerateFarmReports(userData?.company_id, monthSelected, 2024)

    const { data: reportData, isLoading } = GenerateReport(userData?.company_id, farmName, blockName, gridName, month)

    const handleTabChange = (e, newVal) => {
        setTabOpen(newVal)
        
        if(newVal===3)
            setSelectedIndex('VARI')
        else if(newVal===4)
            setSelectedIndex('GLI')
        else if(newVal===5)
            setSelectedIndex('EXG')
    }

    if(isLoading){
        return(
            <div className='report__content__loader'>
                <ClipLoader color="green" loading={ true } size={35}/>
            </div>
        )
    }

    const handleExportXLXS = () => {
        console.log('exporting')
        console.log(reportData)
        
        if (reportData) {
            exportReportToXLSX(reportData);
        } else {
            console.error('No report data available to export');
        }
    }

  return (
    <div className='report__content__container'>

        <div className='report__content__actions'>
            <div className='report__tabs__container'>
                <Tabs 
                    value={tabOpen}
                    onChange={handleTabChange}>
                    <Tab value={2} label='NDVI' {...a11yProps(2)}/>
                    <Tab value={3} label='VARI' {...a11yProps(3)}/>
                    <Tab value={4} label='GLI' {...a11yProps(4)}/>
                    <Tab value={5} label='EXG' {...a11yProps(5)}/>
                </Tabs>
            </div>

            <Button
                size='large'
                variant='outlined'
            >
                <FiPrinter
                    onClick={handleExportXLXS}
                />
            </Button>
        </div>

        <div className='report__table__panel__container'>
            <ReportTablePanel 
                value={tabOpen}
                index={2}
            >
                <ReportNDVITableContainer
                    reportData={reportData}
                />
            </ReportTablePanel>

            <ReportTablePanel 
                value={tabOpen}
                index={3}
            >
                <ReportTable
                    selectedIndex={selectedIndex}
                    reportData={reportData}
                />
            </ReportTablePanel>

            <ReportTablePanel 
                value={tabOpen}
                index={4}
            >
                <ReportTable
                    selectedIndex={selectedIndex}
                    reportData={reportData}
                />
            </ReportTablePanel>

            <ReportTablePanel 
                value={tabOpen}
                index={5}
            >
                <ReportTable
                    selectedIndex={selectedIndex}
                    reportData={reportData}
                />
            </ReportTablePanel>
        </div>

        {/* <ReportTable
            reportData = {reportData}
        /> */}

        
    </div>
  )
}

function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

const ReportTablePanel = (props) => {
    const { children, value, index, ...other } = props;

    return(
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {children}
        </div>
    )
}

const ReportNDVITableContainer = ({reportData}) => {
    const setModalOpen = useReportGridChartModal((state)=> state.setModalOpen)
    const setData = useReportSetVegetationData((state) => state.setData)

    const handleGridChartModal = (predictions) => {
        console.log(predictions)
        setModalOpen(true)
        setData(predictions)
    }

    return(
        <div className='report__table__container'>
            <table>
                <thead>
                    <tr>
                        <th>Location</th>
                        <th>Flight Date</th>
                        <th style={{width: '10%'}}>NDVI</th>
                        <th>Predicted % of (N)</th>
                        <th>Category</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        reportData?.flatMap(farm => 
                            farm.block_list && typeof farm.block_list === 'object' && 
                            farm.block_list?.flatMap(block => 
                                block.grid_list && 
                                (Array.isArray(block.grid_list) ? (
                                    block.grid_list?.flatMap(grid => {
                                        const predictions = grid.predicted_ndvi_related

                                        return predictions.map((pred, index) => (
                                            <tr key={`${grid.id}-${pred.pred_ndvi_id}`}>
                                                {index === 0 && (
                                                    <>
                                                        <td 
                                                            rowSpan={predictions.length} 
                                                            style={{ 
                                                                verticalAlign: 'top', 
                                                                textAlign: 'center' 
                                                            }}
                                                        >
                                                            {`${farm.farmName}-${block.blockName}-${grid.gridName}`}
                                                        </td>
                                                        <td 
                                                            rowSpan={predictions.length} 
                                                            style={{ 
                                                                verticalAlign: 'top', 
                                                                textAlign: 'center' 
                                                            }}
                                                        >
                                                            {`${formatDate(grid.upload_raster_date)}`}
                                                        </td>
                                                    </>
                                                )}
                                                <td>{pred.ndvi.toFixed(3)}</td>
                                                <td>{pred.predicted_nitrogen}</td>
                                                <td>
                                                    <span
                                                        style={{ 
                                                            backgroundColor: getCategoryColor(pred.predicted_category),
                                                            padding: '0.5rem 0.8rem',
                                                            borderRadius: '2rem',
                                                            width:'70px',
                                                            color: 'black',
                                                            textTransform: 'capitalize',
                                                            fontWeight: '550'
                                                        }}
                                                    >
                                                        {pred.predicted_category}
                                                    </span>
                                                </td>
                                                {index === 0 && (
                                                    <td
                                                    rowSpan={predictions.length} 
                                                    style={{ 
                                                        verticalAlign: 'top', 
                                                        textAlign: 'center' 
                                                    }}
                                                    >
                                                        <Button
                                                            style={{
                                                                textTransform: 'capitalize',
                                                                background: 'transparent'
                                                            }}
                                                            disableRipple
                                                            size='small'
                                                            onClick={()=>handleGridChartModal(predictions)}
                                                        >
                                                            Chart
                                                        </Button>
                                                    </td>
                                                )}
                                                
                                            </tr>
                                        ));
                                    })
                                ) : (
                                    <tr key={`${farm.farmName}-${block.blockName}`}>
                                        <td 
                                            colSpan="1" 
                                            style={{ 
                                                verticalAlign: 'top', 
                                                textAlign: 'center' , 
                                                padding: '1rem' 
                                            }}
                                        >
                                            {`${farm.farmName}-${block.blockName}`}
                                        </td>
                                        <td 
                                            colSpan="1" 
                                            style={{ 
                                                textAlign: 'center', 
                                                padding: '1rem' }}
                                            >
                                            
                                        </td>
                                        <td 
                                            colSpan="4" 
                                            style={{ 
                                                textAlign: 'center', 
                                                padding: '1rem' }}
                                            >
                                            {block.grid_list}
                                        </td>
                                    </tr>
                                ))
                            )
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}


const ReportTable = ({reportData, selectedIndex}) => {
    const setModalOpen = useReportGridChartModal((state)=> state.setModalOpen)
    const setData = useReportSetVegetationData((state) => state.setData)

    const handleGridChartModal = (preditionList, ) => {
        console.log('preditionList', preditionList)
        setModalOpen(true)
        setData(preditionList)
    }

    return(
        <div className='report__table__container'>
            <table>
                <thead>
                    <tr>
                        <th>Location</th>
                        <th>Flight Date</th>
                        <th style={{width: '10%'}}>{selectedIndex}</th>
                        <th>Predicted % of (N)</th>
                        <th>Category</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        reportData?.flatMap(farm => 
                            farm.block_list && typeof farm.block_list === 'object' && 
                            farm.block_list?.flatMap(block => 
                                block.grid_list && 
                                (Array.isArray(block.grid_list) ? (
                                    block.grid_list?.flatMap(grid => {
                                        const predictions = grid.predicted_nitrogen_related.filter(
                                            pred => pred.vegetationIndex_type === selectedIndex
                                        );
                                        
                                        return predictions.map((pred, index) => (
                                            <tr key={`${grid.id}-${pred.pred_id}`}>
                                                {index === 0 && (
                                                    <>
                                                        <td 
                                                            rowSpan={predictions.length} 
                                                            style={{ 
                                                                verticalAlign: 'top', 
                                                                textAlign: 'center' 
                                                            }}
                                                        >
                                                            {`${farm.farmName}-${block.blockName}-${grid.gridName}`}
                                                        </td>
                                                        <td 
                                                            rowSpan={predictions.length} 
                                                            style={{ 
                                                                verticalAlign: 'top', 
                                                                textAlign: 'center' 
                                                            }}
                                                        >
                                                            {`${formatDate(grid.upload_raster_date)}`}
                                                        </td>
                                                    </>
                                                )}
                                                <td>{pred.index_value_mean.toFixed(3)}</td>
                                                <td>{pred.predicted_nitrogen.toFixed(2)}</td>
                                                <td>
                                                    <span
                                                        style={{ 
                                                            backgroundColor: getCategoryColor(pred.predicted_category),
                                                            padding: '0.5rem 0.8rem',
                                                            borderRadius: '2rem',
                                                            width:'70px',
                                                            color: 'black',
                                                            textTransform: 'capitalize',
                                                            fontWeight: '550'
                                                        }}
                                                    >
                                                        {pred.predicted_category}
                                                    </span>
                                                </td>
                                                {index === 0 && (
                                                    <td
                                                    rowSpan={predictions.length} 
                                                    style={{ 
                                                        verticalAlign: 'top', 
                                                        textAlign: 'center' 
                                                    }}
                                                    >
                                                        <Button
                                                            onClick={()=>handleGridChartModal(predictions)}
                                                        >
                                                            Chart
                                                        </Button>
                                                    </td>
                                                )}
                                                
                                            </tr>
                                        ));
                                    })
                                ) : (
                                    <tr key={`${farm.farmName}-${block.blockName}`}>
                                        <td 
                                            colSpan="1" 
                                            style={{ 
                                                verticalAlign: 'top', 
                                                textAlign: 'center' , 
                                                padding: '1rem' 
                                            }}
                                        >
                                            {`${farm.farmName}-${block.blockName}`}
                                        </td>
                                        <td 
                                            colSpan="1" 
                                            style={{ 
                                                textAlign: 'center', 
                                                padding: '1rem' }}
                                            >
                                            
                                        </td>
                                        <td 
                                            colSpan="4" 
                                            style={{ 
                                                textAlign: 'center', 
                                                padding: '1rem' }}
                                            >
                                            {block.grid_list}
                                        </td>
                                    </tr>
                                ))
                            )
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}