import React from 'react'
import './css/DashboardBody.css'
import { color } from '../config/mapColor'
import { RiCheckboxBlankFill } from 'react-icons/ri'
import { Button } from '@mui/material'

export default function DashboardBody() {
  return (
    <div className='dashboard__body__container'>
        <DashboardBodyItem/>
        <DashboardBodyItem/>
        <DashboardBodyItem/>
        <DashboardBodyItem/>
    </div>
  )
}

const DashboardBodyItem = () => {
    return(
        <div className='dashboard__body__item'>
            <div className='dashoard__col__item__title'>
                <h4>FARM NAME</h4>
            </div>
            <div className='dashoard__col__item'>
                <div className='vegetation__content'>
                    <h4>Area Distribution</h4>
                    <div className='vegetation__item'>
                        <div className='vegetation__item__mean__index'>
                            <p>Mean Index</p>
                            <p>
                                <RiCheckboxBlankFill color={color.VERY_LOW}/> 
                            </p>
                            <p>
                                <RiCheckboxBlankFill color={color.LOW}/> 
                            </p>
                            <p>
                                <RiCheckboxBlankFill color={color.MEDIUM}/> 
                            </p>
                            <p>
                                <RiCheckboxBlankFill color={color.HIGH}/> 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='dashoard__col__item'>
                <div className='vegetation__content'>
                    <h4>NDVI</h4>
                    <div className='vegetation__item'>
                        <div className='vegetation__item__mean__index'>
                            <p>Mean Index</p>
                            <p>
                                <RiCheckboxBlankFill color={color.VERY_LOW}/> 
                            </p>
                            <p>
                                <RiCheckboxBlankFill color={color.LOW}/> 
                            </p>
                            <p>
                                <RiCheckboxBlankFill color={color.MEDIUM}/> 
                            </p>
                            <p>
                                <RiCheckboxBlankFill color={color.HIGH}/> 
                            </p>
                        </div>
                        <div className='vegetation__item__mean__nitrogen'>
                            <p>Mean % of (N)</p>
                            <p>
                                <RiCheckboxBlankFill color={color.VERY_LOW}/> 
                            </p>
                            <p>
                                <RiCheckboxBlankFill color={color.LOW}/>
                            </p>
                            <p>
                                <RiCheckboxBlankFill color={color.MEDIUM}/>
                             </p>
                            <p>
                                <RiCheckboxBlankFill color={color.HIGH}/> 
                            </p>
                        </div>
                    </div>
                </div>
                <div className='dashboard__col__actions'>
                    <Button
                        variant='outlined'
                        disableRipple
                    >
                        NDVI
                    </Button>
                    <Button
                        variant='outlined'
                        disableRipple
                    >
                        VARI
                    </Button>
                    <Button
                        variant='outlined'
                        disableRipple
                    >
                        GLI
                    </Button>
                    <Button
                        variant='outlined'
                        disableRipple
                    >
                        EXG
                    </Button>
                </div>
            </div>
        </div>
    )
}