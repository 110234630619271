import React from 'react'
import '..//main/css/Footer.css'

export default function Footer() {
  return (
    <div className='footer-container'>
        <h5>© Multispectral Project 2023</h5>
    </div>
  )
}
