import { create } from "zustand";

export const useReportMonthParams = create((set)=>({
    month: '',
    setMonth: (data) => {
        set({ month: data})
    }
}))

export const useReportYearParams = create((set)=>({
    year: '',
    setYear: (data) => {
        set({ year: data})
    }
}))

export const useReportStore = create((set)=>({
    farmName: '--ALL--',
    blockName: '--ALL--',
    gridName: '--ALL--',
    month: '--ALL--',
    setFarmName: (data) => {
        set({ farmName: data})
    },
    setBlockName: (data) => {
        set({ blockName: data})
    },
    setGridName: (data) => {
        set({ gridName: data})
    },
    setMonth: (data) => {
        set({ month: data})
    },
}))

export const useReportGridChartModal = create((set)=>({
    modalOpen: false,
    setModalOpen: (data) => {
        set({ modalOpen: data})
    }
}))

export const useReportSetVegetationData = create((set)=>({
    vegetationData: false,
    setData: (data) => {
        set({ vegetationData: data})
    }
}))