import * as XLSX from 'xlsx';

export const exportReportToXLSX = (reportData) => {
  // Create a new workbook
  const wb = XLSX.utils.book_new();
  const worksheetNames = new Set();

  // Helper function to create a unique worksheet name
  const getUniqueWorksheetName = (baseName) => {
    let wsName = baseName;
    let suffix = 1;
    while (worksheetNames.has(wsName)) {
      wsName = `${baseName} (${suffix})`;
      suffix++;
    }
    worksheetNames.add(wsName);
    return wsName;
  };

  // Helper function to create and style a worksheet
  const createWorksheet = (farmName, blockName, gridName, gridData) => {
    const ws = XLSX.utils.aoa_to_sheet([
      ['Location', `${farmName}-${blockName}-${gridName}`],
      ['Flight Date', new Date(gridData.upload_raster_date).toLocaleDateString()],
      [''],
      ['Vegetation Index', 'Predicted Nitrogen', 'Category', 'Index Value (Mean)', 'Area Size']
    ]);

    // Add data rows
    const indices = ['VARI', 'GLI', 'EXG'];
    indices.forEach(index => {
      const predictions = gridData.predicted_nitrogen_related.filter(pred => pred.vegetationIndex_type === index);
      predictions.forEach(pred => {
        XLSX.utils.sheet_add_aoa(ws, [[
          index,
          pred.predicted_nitrogen,
          pred.predicted_category,
          pred.index_value_mean.toFixed(3),
          pred.area_size.toFixed(2)
        ]], { origin: -1 });
      });
      // Add an empty row after each index group
      XLSX.utils.sheet_add_aoa(ws, [['']], { origin: -1 });
    });

    // Set column widths and apply styling (same as before)
    // ... [The styling code remains the same as in the previous version]

    // Add NDVI data if available
    if (gridData.predicted_ndvi_related && gridData.predicted_ndvi_related.length > 0) {
      XLSX.utils.sheet_add_aoa(ws, [
        [''],
        ['NDVI Data'],
        ['NDVI', 'Predicted Nitrogen', 'Category']
      ], { origin: -1 });

      gridData.predicted_ndvi_related.forEach(ndvi => {
        XLSX.utils.sheet_add_aoa(ws, [[
          ndvi.marker_id,
          ndvi.ndvi,
          ndvi.predicted_nitrogen,
          ndvi.predicted_category,
        ]], { origin: -1 });
      });
    }

    return ws;
  };

  // Process the report data
  reportData.forEach(farm => {
    if (Array.isArray(farm.block_list)) {
      // If block_list is an array, process each block
      farm.block_list.forEach(block => {
        if (Array.isArray(block.grid_list)) {
          // If grid_list is an array, process each grid
          block.grid_list.forEach(grid => {
            const wsName = getUniqueWorksheetName(`${farm.farmName}-${block.blockName}-${grid.gridName}`);
            const ws = createWorksheet(farm.farmName, block.blockName, grid.gridName, grid);
            XLSX.utils.book_append_sheet(wb, ws, wsName);
          });
        } else if (typeof block.grid_list === 'object' && block.grid_list !== null) {
          // If grid_list is an object, treat it as a single grid
          const wsName = getUniqueWorksheetName(`${farm.farmName}-${block.blockName}-Grid`);
          const ws = createWorksheet(farm.farmName, block.blockName, 'Grid', block.grid_list);
          XLSX.utils.book_append_sheet(wb, ws, wsName);
        }
      });
    } else if (typeof farm.block_list === 'object' && farm.block_list !== null) {
      // If block_list is an object, treat it as a single block
      const block = farm.block_list;
      if (Array.isArray(block.grid_list)) {
        // If grid_list is an array, process each grid
        block.grid_list.forEach(grid => {
          const wsName = getUniqueWorksheetName(`${farm.farmName}-Block-${grid.gridName}`);
          const ws = createWorksheet(farm.farmName, 'Block', grid.gridName, grid);
          XLSX.utils.book_append_sheet(wb, ws, wsName);
        });
      } else if (typeof block.grid_list === 'object' && block.grid_list !== null) {
        // If grid_list is an object, treat it as a single grid
        const wsName = getUniqueWorksheetName(`${farm.farmName}-Block-Grid`);
        const ws = createWorksheet(farm.farmName, 'Block', 'Grid', block.grid_list);
        XLSX.utils.book_append_sheet(wb, ws, wsName);
      }
    }
  });

  // Generate XLSX file
  XLSX.writeFile(wb, 'FarmReport.xlsx');
};
