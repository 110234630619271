import React, { useContext, useState } from 'react'
import './css/Create.css'
import { useNavigate } from 'react-router-dom' 
import { AddFarm, FetchCompany, FetchUsers } from '../api/ApiMethods';
import { toast } from 'react-toastify';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
//import TextField from '@mui/material/TextField';
import "react-toastify/dist/ReactToastify.css"
import Button from '@mui/material/Button'
import { ClipLoader } from 'react-spinners';
import { TextField } from '@mui/material';
import { UserContext } from '../Context/ContextProvider';

export default function Create() {
  const { userData } = useContext(UserContext)
  //console.log(userData)
  const navigate = useNavigate()

  const [errorFields, iserrorFields] = useState({
    company: '',
    farmName: ''
  })

  const [form, setForm] = useState({
    company: "",
    farmName: ""
  })

  // get user data 
  //const { data: fetchUser } = FetchUsers()
  const { data: fetchCompany } = FetchCompany()
  const { mutate: addFarmPost, isLoading } = AddFarm()
  
  const handleInputChanges = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })

    iserrorFields((prevError) => ({
      ...prevError,
      [e.target.name]: ""
    }));
  };


  const createFarm = async(e) =>{
    e.preventDefault()
    console.log('form', form)

    const create_data = new FormData()

    for (const key in form) {
      create_data.append(key, form[key]);
    }

    create_data.append('role', userData.role)
    create_data.append('company_id', userData.company_id)
    create_data.append('user', userData.id)

    console.log(create_data)

    addFarmPost(create_data, {
      onSuccess: (data) =>{
        //console.log('onSuccess', data)
        setForm({
          company: "",
          farmName: ""
        })

        iserrorFields((prevState) => ({
          ...prevState,
          company: "",
          farmName: ""
        }))

        toast.success('Successfully created farm', {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          });
      },
      onError: (error) =>{ 
        //console.log('onError', error.response.data)

        
        if(error.response.data.message){
          iserrorFields((prevState) => ({
            ...prevState,
            company: true,
            farmName: ''
          }))

          toast.error('Please Provide Company', {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            pauseOnFocusLoss: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            });
            
        }

        if(error.response.data.farmName){
          iserrorFields((prevState) => ({
            ...prevState,
            company: '',
            farmName: error.response.data.farmName || '',
          }))

          toast.error('Please Fill up the fields.', {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            });
        }

        if(error.response.data.farm_exist){
          iserrorFields((prevState) => ({
            ...prevState,
            company: '',
            farmName: error.response.data.farm_exist || '',
          }))

          toast.error(error.response.data.farm_exist, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            });
        }

      }
    })
  }

  return (
    <div className='create-container'>
      <div className='create-contents'>
        <h3>Create Farm</h3>
        <form 
          onSubmit={createFarm}
          className='create-input-container'>
            {
              userData.role === 'Admin' ?
            <div className='company__select__container'>
              <FormControl 
                fullWidth
                error={errorFields.company ? true : false}>
                    <InputLabel id="company-label">Company</InputLabel>
                    <Select
                        disabled={isLoading}
                        labelId="company-label"
                        id="company-select"
                        value = {form.company}
                        label="company"
                        name="company"
                        onChange={handleInputChanges}
                        >
                        {
                          fetchCompany?.map( company => (
                            <MenuItem key={company.id} value={company.id}>{company.company_name}</MenuItem>
                            
                          ))
                        }
                    </Select>
              </FormControl>
            </div>
            :
            ''
            }

          <div className='create-farm-input'>
            <TextField 
              error = {errorFields.farmName ? true : false}
              fullWidth 
              name = 'farmName'
              value = {form?.farmName}
              id = "outlined-basic" 
              label = "Farm name" 
              variant = "outlined" 
              onChange = {handleInputChanges}
              />
          </div>

          {/* <div className='farm-type'>
            <FormControl 
                fullWidth
                error={errorFields.farmType ? true : false}>
                    <InputLabel id="farmType-label">Farm Type</InputLabel>
                    <Select
                      disabled = {isLoading}
                      labelId="farmType-label"
                      id="farmType-select"
                      value = {form.farmType}
                      label="farmType"
                      name="farmType"
                      onChange={handleInputChanges}
                      >
                      <MenuItem value={'Banana'}>Banana</MenuItem>
                      <MenuItem value={'Others'}>Others</MenuItem>
                    </Select>
                </FormControl>
          </div> */}

          <div className='create-buttons'>
            <Button
              style={{
                backgroundColor:  'var(--no_background)',
                color: 'var(--fontColor__secondary)',
                textTransform: 'capitalize',
                boxShadow: 'none',
              }}
              variant="contained"
              onClick={()=> navigate('/app/farm')}
              >
              <span>Back</span>
            </Button>

            <Button 
              style={{
                backgroundColor:  'var(--primary__color)',
                textTransform: 'capitalize'
              }}
              disabled={isLoading ? true : false}
              loading={isLoading.toString()}
              loadingposition="start" 
              startIcon={ <ClipLoader color="white" loading={ isLoading } size={15}/>}
              variant="contained"
              type='submit'
              >
              <span>Submit</span>
            </Button>
          </div>

        </form>
      </div>
    </div>
  )
}
