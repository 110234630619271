import React, { useEffect, useState } from 'react'
import './css/ChartView.css'
import ChartViewHeader from '../feature/ChartViewComponent/ChartViewHeader'
//import ChartViewContent from '../feature/ChartViewComponent/ChartViewContent'
import { useSearchParams } from 'react-router-dom'
import { GetNutrientGridsList } from '../api/ApiMethods'
import ChartViewTable from '../feature/ChartViewComponent/ChartViewTable'
import { BeatLoader } from 'react-spinners'
import LineChartView from '../feature/ChartViewComponent/LineChartView'
import { useChartSearchStore } from '../store/chartStore'
import { GetNutrientGridsLists } from '../api/nutrientAPI'
import WeatherLineChart from '../feature/ChartViewComponent/WeatherLineChart'
import SensorLineChart from '../feature/ChartViewComponent/SensorLineChart'
import { Tab, Tabs } from '@mui/material'

const useChartSearchStoreSelector = (state)=> state.gridSearchValue

export default function ChartView() {
    const [query] = useSearchParams()
    //console.log('render parent view content')
    const bid = query.get('bid')
    const [tabOpen, setTabOpen] = useState(0)

    const gridSearchValue = useChartSearchStore(useChartSearchStoreSelector)
    //console.log('grid search value: ', gridSearchValue)
    
    const { data: getGridList , isLoading: loadGrids} = GetNutrientGridsLists(bid)
    console.log('ggl',getGridList)

    const { data: getNutrientGridsList, refetch, isFetching } = GetNutrientGridsList(bid, gridSearchValue)
    //console.log('gngl', getNutrientGridsList)

    useEffect(() => {
      if (gridSearchValue) {
          refetch();
      }
    }, [gridSearchValue, refetch]);

    const handleTabOpen = (e, newval) => {
      setTabOpen(newval)
    }

  return (
    <div className='chartview__container'>
        <ChartViewHeader />

        <div className='chartview__content'>
          {
            loadGrids ?
            <div className='chart__loading'>
              <BeatLoader color='green' loading={true}/>
            </div>
            
          :
          <ChartViewTable
              getGridList = { getGridList }
              bid = { bid }
          />
          }

          <div className='chart__contents'>
            <div className='chart__tabs__container'>
              <Tabs
                value={tabOpen}
                onChange={handleTabOpen}
              >
                <Tab label="Nutrient"  />
                <Tab label="Weather"  />
                <Tab label="Sensor"  />
              </Tabs>
            </div>
            {
              isFetching ?
                <div className='chart__loading'>
                  <BeatLoader color='green' loading={true}/>
                </div>
              :
                <>
                  <LineChartView
                    tabOpen = { tabOpen } 
                    getNutrientGridsList = { getNutrientGridsList }
                  />
                  <WeatherLineChart 
                    tabOpen = { tabOpen } 
                    getNutrientGridsList = { getNutrientGridsList }
                  />
                  <SensorLineChart
                    tabOpen = { tabOpen } 
                    getNutrientGridsList = { getNutrientGridsList }
                  />
                </>
            }
          </div>
        </div>
    </div>
  )
}