import React, { useContext, useState } from 'react'
import './css/Reports.css'
import { ToggleSidebarContext } from '../Context/ContextProvider';
import { PageTitle } from './Dashboard';
import ReportHeader from '../reports/ReportHeader';
import ReportContent from '../reports/ReportContent';
import { useReportGridChartModal, useReportSetVegetationData } from '../store/reportStore';
import { Button, Tab, Tabs } from '@mui/material';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function Reports() {
    const { toggleSidebarValue, isToggleSidebarValue } = useContext(ToggleSidebarContext)
    const modalOpen = useReportGridChartModal((state)=> state.modalOpen)
   

    const toggle = () =>{
        isToggleSidebarValue(!toggleSidebarValue)
    }


  return (
    <div className='reports__container'>
        <PageTitle
          title={'Reports'}
          onMenuClick={toggle}
        />
        <ReportHeader
        
        />

        <ReportContent/>

        {modalOpen && <ReportGridChart />}
    </div>
  )
}


const ReportGridChart = () => {
  const setModalOpen = useReportGridChartModal((state)=> state.setModalOpen)
  const vegetationData = useReportSetVegetationData((state)=> state.vegetationData)
  const [tabOpen, setTabOpen] = useState(0)

  const handleChangeTab = (e, val) => {
    setTabOpen(val)
  }

  return(
      <div className='report__gridchart__container'>
        <div className='report__gridchart__content'>
          <div className='report__gridchart__header'>
            <Tabs
              value={tabOpen}
              onChange={handleChangeTab}
            >
              <Tab style={{textTransform: 'capitalize'}} value={0} label="Vegetation Indeces" />
              <Tab style={{textTransform: 'capitalize'}} value={1} label="Weather"/>
              <Tab style={{textTransform: 'capitalize'}} value={2} label="Soil Sensors"/>
            </Tabs>
          </div>

          <ReportVegetationCharts
            vegetationData = { vegetationData }
          />

          <div className='report__gridchart__actions'>
            <Button
              disableRipple
              style={{
                textTransform: 'capitalize',
                color: 'black',
                background: 'transparent'
              }}
              onClick={()=>setModalOpen(false)}
            >
              Close
            </Button>
          </div>
        </div>
      </div>
  )
}

const ReportVegetationCharts = ({ vegetationData }) => {
  const vegetationIndexType = vegetationData[0].vegetationIndex_type;

  const chartData = {
    labels: vegetationData?.map(item => `${item.grid_box_id ? item.grid_box_id : item.marker_id}`),
    datasets: [
      {
        label: 'Predicted Nitrogen',
        data: vegetationData?.map(item => item.predicted_nitrogen),
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        yAxisID: 'y',
      },
      {
        label: 'Mean',
        data: vegetationData?.map(item => item.index_value_mean ? item.index_value_mean.toFixed(3) : item.ndvi ),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        yAxisID: 'y1',
      },
    ],
  };

  const options = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: `Predicted Nitrogen and Index Value Mean of ${vegetationIndexType ? vegetationIndexType : 'NDVI'}`,
      },
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: 'Predicted Nitrogen',
        },
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false,
        },
        title: {
          display: true,
          text: 'Mean Value',
        },
      },
    },
  };

  return(
    <div className='report__vegetation__chart__conntainer'>
      <Line options={options} data={chartData} />
    </div>
  )
}