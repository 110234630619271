import React from 'react'
import './css/FarmDeleteModal.css'
import { DeleteFarmRequest } from '../../api/ApiMethods'
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button'
import { ClipLoader } from 'react-spinners'
import { PiWarningCircleLight } from "react-icons/pi"

export default function FarmDeleteModal({setDeleteModal, farmUserId}) {
    //console.log('dlteid', rasterId)
    const queryClient = useQueryClient()

    const { mutate: deleteFarm, isError, error, isLoading } = DeleteFarmRequest()
    //console.log(company)
    const handleDeleteRequest = (e) =>{
        e.preventDefault()

        const deleteFormData = new FormData()
        deleteFormData.append('farm_id', farmUserId)

        deleteFarm(deleteFormData,{
                onSuccess: (data) =>{
                    queryClient.invalidateQueries('fetch-farms', 'fetch-users')
                    setDeleteModal(false)

                    toast.success('Removed successfully.', {
                        position: "top-right",
                        autoClose: 2500,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        pauseOnFocusLoss: false,
                        theme: "light",
                        });
                }
            }
        )
    }

    if(isError){
        console.log(error.message)
    }

    const closeHandleModal = () => {
        setDeleteModal(false)
    }

  return (
    <div className='delete__modal__prompt__container'>
        <div className='delete__modal__content'>
            <div className='delete__header'>
                <div className='delete__icon'>
                    <PiWarningCircleLight/>
                </div>
                <label>Are you sure you want to delete?</label>
            </div>
            <div className='delete__actions'>
                <Button 
                    style={{
                        backgroundColor: 'var(--red)',
                        textTransform: 'capitalize'
                    }}
                    disabled={isLoading ? true : false}
                    loading={isLoading.toString()}
                    loadingposition="start"
                    startIcon={ <ClipLoader color="white" loading={ isLoading } size={15}/>}
                    variant="contained"
                    onClick={handleDeleteRequest}
                    >
                    Yes
                </Button>

                <Button 
                    style={{
                    backgroundColor:  'var(--bkg__primary)',
                    color: 'var(--fontColor__secondary)',
                     textTransform: 'capitalize'
                    }}
                        
                    variant="contained"
                    onClick={closeHandleModal}
                    >
                    <span>Cancel</span>
                </Button>
            </div>
        </div>
    </div>
  )
}
