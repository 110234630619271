import React from 'react'
import './css/DashboardSearch.css'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'

export default function DashboardSearch() {
    const ButtonProperty = {
        size: 'small',
    }

  return (
    <div className='dashboard__search__container'>
        <FormControl 
                fullWidth
                //</div>error={errorFields.company ? true : false}
            >
                <InputLabel 
                    id="Block-label"
                    shrink
                >
                    Block
                </InputLabel>
                    <Select
                        notched
                        //disabled={isLoading}
                        labelId="Block-label"
                        id="Block-select"
                        //value = {form.company}
                        label="Block"
                        name="Block"
                        size={ButtonProperty.size}
                        //onChange={handleInputChanges}
                    >
                        <MenuItem>2</MenuItem>
                        <MenuItem>3</MenuItem>
                        <MenuItem>2</MenuItem>
                        <MenuItem>2</MenuItem>
                    </Select>
            </FormControl>

            <FormControl 
                fullWidth
                //</div>error={errorFields.company ? true : false}
            >
                <InputLabel 
                    id="grid-label"
                    shrink
                >
                    Grid
                </InputLabel>
                    <Select
                        notched
                        //disabled={isLoading}
                        labelId="grid-label"
                        id="grid-select"
                        //value = {form.company}
                        label="grid"
                        name="grid"
                        size={ButtonProperty.size}
                        //onChange={handleInputChanges}
                    >
                        <MenuItem>VERY LOW</MenuItem>
                        <MenuItem>LOW</MenuItem>
                        <MenuItem>MEDIUM</MenuItem>
                        <MenuItem>HIGH</MenuItem>
                    </Select>
            </FormControl>
    </div>
  )
}
