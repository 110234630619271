import React, { useState } from 'react'
import './css/NutrientItemUpdate.css'
import { Button, TextField } from '@mui/material'
import { ClipLoader } from 'react-spinners'
import { UpdateNitrogen } from '../../api/nutrientAPI'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { motion } from 'framer-motion'

export default function NutrientItemUpdate({ setNutrientItemModal, nutrientItem }) {
    const ButtonProperty = {
        size: 'small',
        width: '85px',
        widthLonLat: '120px',
    }

    const queryClient = useQueryClient()
    
    const [nitrogen, setNitrogen] = useState({
        ndvi: nutrientItem.ndvi,
        vari: nutrientItem.vari,
        gli: nutrientItem.gli,
        exg: nutrientItem.exg,
        nitrogen: nutrientItem.nitrogen,
        longitude: nutrientItem.longitude,
        latitude: nutrientItem.latitude,
    })

    const [nitrogenError, setNitrogenError] = useState({
        ndvi: false,
        vari: false,
        gli: false,
        exg: false,
        nitrogen: false,
        longitude: false,
        latitude: false,
    })

    const { mutate: putNitrogen, isLoading } = UpdateNitrogen()

    const handleUpdateNitrogen = async() => {
        console.log(nitrogen)
        const nutrientData = new FormData()

        for (const key in nitrogen) {
            nutrientData.append(key, nitrogen[key]);
        }

        nutrientData.append('nutrient_id', nutrientItem.nutrient_id)

        putNitrogen(nutrientData, {
            onSuccess: (data) => {
                
                queryClient.invalidateQueries('get-nitrogen-threshold')
                toast.success('Update nitrogen successfully.', {
                    position: "top-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    pauseOnFocusLoss: false,
                    theme: "light",
                });
                
                setNutrientItemModal(false)

            },
            onError: (error) => {
                console.log(error)
            }

        })
    }
    const handleNitrogenChange = (e) => {
        setNitrogen({
            ...nitrogen,
            [e.target.name]: e.target.value,
        })

        setNitrogenError((prevGridError) => ({
            ...prevGridError,
            [e.target.name]: ""
          }));
    }

  return (
    <div className='nutrient__item__container'>
        <motion.div 
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3}}
            className='nutrient__item__content'>
            <h4>Update Nitrogen</h4>

            <div className='update__grid__input__field'>
                <div className='update__vegetation__container'>
                    <TextField 
                        error={nitrogenError.ndvi ? true : false}
                        id="standard-numbersic" 
                        label="NDVI" 
                        name='ndvi'
                        variant="outlined"
                        sx={{width: ButtonProperty.width}}
                        size={ButtonProperty.size}
                        inputProps={{type: 'number'}}
                        onChange={handleNitrogenChange}
                        value={nitrogen.ndvi}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField 
                        error={nitrogenError.vari ? true : false}
                        id="standard-numbersic" 
                        label="VARI" 
                        name='vari'
                        variant="outlined"
                        sx={{width: ButtonProperty.width}}
                        size={ButtonProperty.size}
                        inputProps={{type: 'number'}}
                        onChange={handleNitrogenChange}
                        value={nitrogen.vari}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField 
                        error={nitrogenError.gli ? true : false}
                        id="standard-numbersic" 
                        label="GLI" 
                        name='gli'
                        variant="outlined"
                        sx={{width: ButtonProperty.width}}
                        size={ButtonProperty.size}
                        inputProps={{type: 'number'}}
                        onChange={handleNitrogenChange}
                        value={nitrogen.gli}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField 
                        error={nitrogenError.exg ? true : false}
                        id="standard-numbersic" 
                        label="EXG" 
                        name='exg'
                        variant="outlined"
                        sx={{width: ButtonProperty.width}}
                        size={ButtonProperty.size}
                        inputProps={{type: 'number'}}
                        onChange={handleNitrogenChange}
                        value={nitrogen.exg}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>

                <div className='update__additional__grid__container'>        
                    <TextField 
                        error={nitrogenError.nitrogen ? true : false}
                        id="outlined-basic" 
                        label="Nitrogen" 
                        variant="outlined"
                        name='nitrogen'
                        sx={{width: ButtonProperty.width}}
                        size={ButtonProperty.size}
                        inputProps={{type: 'number'}}
                        onChange={handleNitrogenChange}
                        value={nitrogen.nitrogen}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField 
                        error={nitrogenError.longitude ? true : false}
                        id="outlined-basic" 
                        label="Longitude" 
                        variant="outlined"
                        name='longitude'
                        sx={{width: ButtonProperty.widthLonLat}}
                        size={ButtonProperty.size}
                        inputProps={{type: 'number'}}
                        onChange={handleNitrogenChange}
                        value={nitrogen.longitude}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField 
                        error={nitrogenError.latitude ? true : false}
                        id="outlined-basic" 
                        label="Latitude" 
                        variant="outlined"
                        name='latitude'
                        sx={{width: ButtonProperty.widthLonLat}}
                        size={ButtonProperty.size}
                        inputProps={{type: 'number'}}
                        onChange={handleNitrogenChange}
                        value={nitrogen.latitude}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>  
            </div>

            <div className='nutrient__update__actions'>
                <Button
                    style={{
                        backgroundColor:  'var(--no_background)',
                        color: 'var(--fontColor__secondary)',
                        textTransform: 'capitalize',
                        boxShadow: 'none',
                    }}
                    variant="contained"
                    onClick={()=>setNutrientItemModal(false)}
                    >
                    <span>Close</span>
                </Button>

                <Button 
                    style={{
                        backgroundColor:  'var(--primary__color)',
                        textTransform: 'capitalize'
                    }}
                    disabled={isLoading ? true : false }
                    loading={isLoading.toString()}
                    loadingposition="start" 
                    startIcon={ <ClipLoader color="white" loading={ isLoading } size={15}/>}
                    variant="contained"
                    onClick={handleUpdateNitrogen}
                    >
                    <span>Update</span>
                </Button>

            </div>
        </motion.div>
    </div>
  )
}
