import { Button, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import { ClipLoader } from 'react-spinners'
import './css/AddBlockModal.css'
import { motion } from 'framer-motion'
import { UserContext } from '../Context/ContextProvider'
import { toast } from 'react-toastify'
import { AddFarm } from '../api/ApiMethods'

export default function CreateFarm({ setFarmModal }) {

    const { userData } = useContext(UserContext)
    console.log(userData)

    const { mutate: addFarmPost, isLoading } = AddFarm()

    //const queryClient = useQueryClient()

    const [errorFields, iserrorFields] = useState({
        farmName: '',
      })
    
      const [form, setForm] = useState({
        farmName: "",
      })

    const handleInputChanges = (e) => {
        setForm({
          ...form,
          [e.target.name]: e.target.value,
        })
    
        iserrorFields((prevError) => ({
          ...prevError,
          [e.target.name]: ""
        }));
    };

    const createFarm = async(e) => {
        e.preventDefault()

        const create_data = new FormData()
        

        for (const key in form) {
            create_data.append(key, form[key]);
        }

        create_data.append('company', userData?.company_id)
        create_data.append('user', userData?.user_id)
    
        
        addFarmPost(create_data, {
            onSuccess: (data) =>{
              //console.log('onSuccess', data)
              setForm({
                farmName: ""
              })
      
              iserrorFields((prevState) => ({
                ...prevState,
                farmName: ""
              }))
      
              toast.success('Successfully created farm', {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
                });
                setFarmModal(false)
            },
            onError: (error) =>{ 
              //console.log('onError', error.response.data)
      
              
              if(error.response.data.message){
                iserrorFields((prevState) => ({
                  ...prevState,
                  farmName: ''
                }))
      
                toast.error('Please Provide Company', {
                  position: "top-right",
                  autoClose: 2500,
                  hideProgressBar: false,
                  closeOnClick: false,
                  pauseOnHover: false,
                  pauseOnFocusLoss: false,
                  draggable: false,
                  progress: undefined,
                  theme: "light",
                  });
                  
              }
      
              if(error.response.data.farmName){
                iserrorFields((prevState) => ({
                  ...prevState,
                  farmName: error.response.data.farmName || '',
                }))
      
                toast.error('Please Fill up the fields.', {
                  position: "top-right",
                  autoClose: 2500,
                  hideProgressBar: false,
                  closeOnClick: false,
                  pauseOnHover: false,
                  draggable: false,
                  progress: undefined,
                  theme: "light",
                  });
              }
      
              if(error.response.data.farm_exist){
                iserrorFields((prevState) => ({
                  ...prevState,
                  farmName: error.response.data.farm_exist || '',
                }))
      
                toast.error(error.response.data.farm_exist, {
                  position: "top-right",
                  autoClose: 2500,
                  hideProgressBar: false,
                  closeOnClick: false,
                  pauseOnHover: false,
                  draggable: false,
                  progress: undefined,
                  theme: "light",
                  });
              }
      
            }
          })
    }


  return (
    <div className='block__modal__container'>
        <motion.div 
            className='block__modal__content'
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3}}
            >
            <h3>Create Farm</h3>

            <div className='block__modal__fields'>
                <TextField 
                error = {errorFields.farmName ? true : false}
                fullWidth 
                name = 'farmName'
                value = {form?.farmName}
                id = "outlined-basic" 
                label = "Farm name" 
                variant = "outlined" 
                onChange = {handleInputChanges}
                />
            </div>

            <div className='block__modal__actions'>
                <Button
                style={{
                    backgroundColor:  'var(--no_background)',
                    color: 'var(--fontColor__secondary)',
                    textTransform: 'capitalize',
                    boxShadow: 'none',
                }}
                variant="contained"
                onClick={()=> setFarmModal(false)}
                >
                <span>Back</span>
                </Button>

                <Button 
                    style={{
                        backgroundColor:  'var(--primary__color)',
                        textTransform: 'capitalize'
                    }}
                    disabled={isLoading ? true : false}
                    loading={isLoading.toString()}
                    loadingposition="start" 
                    startIcon={ <ClipLoader color="white" loading={ isLoading } size={15}/>}
                    variant="contained"
                    onClick={createFarm}
                >
                <span>Submit</span>
                </Button>
            </div>
        </motion.div>
    </div>
  )
}
