import React, { useMemo } from 'react'
import './css/SensorLineChart.css'
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Legend,
    Tooltip,
    Title,
} from 'chart.js'
import { Line } from 'react-chartjs-2';

ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Legend,
    Tooltip,
    Title,
)

export default function SensorLineChart({ getNutrientGridsList, tabOpen  }) {

    const sensorData = useMemo(() => {
        return getNutrientGridsList
          ?.filter(grid => grid.sensor) // Filter out entries without weather data
          .map(grid => ({
            date: grid.upload_raster_date,
            moisture: grid.sensor.moisture,
            pH: grid.sensor.pH,
            temperature_pH: grid.sensor.temperature_pH,
            temperature_moisture: grid.sensor.temperature_moisture
          }))
          .sort((a, b) => new Date(a.date) - new Date(b.date)); // Sort by date
      }, [getNutrientGridsList]);

      //console.log('sdata', sensorData)

      const chartData = {
        labels: sensorData?.map(data => data.date),
        datasets: [
          {
            label: 'Moisture',
            data: sensorData?.map(data => data.moisture),
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1
          },
          {
            label: 'pH',
            data: sensorData?.map(data => data.pH),
            borderColor: 'rgb(255, 99, 132)',
            tension: 0.1
          },
          {
            label: 'Temperature (Moisture)',
            data: sensorData?.map(data => data.temperature_moisture),
            borderColor: 'rgb(255, 205, 86)',
            tension: 0.1
          },
          {
            label: 'Temperature (pH)',
            data: sensorData?.map(data => data.temperature_pH),
            borderColor: 'rgb(220, 205, 90)',
            tension: 0.1
          }
        ]
      };
    
      const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Sensors'
          }
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Date'
            },
            ticks: {
              maxRotation: 90,
              minRotation: 90,
              autoSkip: false,
              callback: function(val, index) {
                // This will display every label
                return this.getLabelForValue(val);
              }
            },
            grid: {
              display: true
            }
          },
          y: {
            title: {
              display: true,
              text: 'Value'
            },
            beginAtZero: true,
          }
        },
        maintainAspectRatio: false,
      };

  return (
    <div 
      className='sensor__lineChart__container'
      hidden ={tabOpen === 2 ? false : true}
      >
        {getNutrientGridsList &&
          (
            <Line options={options} data={chartData} />
          )
        }
    </div>
  )
}
