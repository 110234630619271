import React, { useState } from 'react'
import './css/GridChartModal.css'
import { Button } from '@mui/material'
import { Line } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Legend,
    Tooltip,
    Title,
} from 'chart.js'
import { FaTableList } from 'react-icons/fa6'
import { FaChartBar } from 'react-icons/fa'

ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Legend,
    Tooltip,
    Title,
)

export default function GridChartModal({ setGridChartModal, nutriendGridData }) {
    //console.log(nutriendGridData?.data)

    const [openGridTable, setOpenGridTable] = useState(true)
    const [openGridChart, setOpenGridChart] = useState(false)

    // Extracting the necessary data
    const dataPoints = nutriendGridData?.data.map((_, idx) => idx + 1);
    
    const indicesData = {
        ndvi: nutriendGridData.data.map(level => parseFloat(level.ndvi)*10),
        vari: nutriendGridData.data.map(level => parseFloat(level.vari)*10),
        gli: nutriendGridData.data.map(level => parseFloat(level.gli)*10),
        exg: nutriendGridData.data.map(level => parseFloat(level.exg)/10)
    }

    console.log(indicesData)

    const nitrogenValues = nutriendGridData.data.map(level => parseFloat(level.nitrogen));

    // Function to generate datasets
    const createDataset = (indexData) => [
        {
            label: 'Index',
            data: indexData,
            backgroundColor: 'rgba(4, 59, 92, 0.5)',
            borderColor: 'rgba(4, 59, 92, 1)',
            borderWidth: 2,
            fill: false,
            tension: 0.2
        },
        {
            label: 'Nitrogen',
            data: nitrogenValues,
            backgroundColor: 'rgba(249, 180, 45, 0.5)',
            borderColor: 'rgba(249, 180, 45, 1)',
            borderWidth: 2,
            fill: false,
            tension: 0.2
        }
    ];

    // Function to generate chart options
    const createOptions = (label, maxVal) => ({
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            }
        },
        layout: {
            padding: {
                top: 20, // Adding padding to accommodate the legend
            }
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: label
                },
                ticks: {
                    callback: value => value.toString()
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Values'
                },
                min: 0,
                max: maxVal + 0.1,
                ticks: {
                    callback: value => value.toFixed(2)
                }
            }
        }
    });

    // List of chart data
    const chartData = [
        { label: 'NDVI', data: createDataset(indicesData.ndvi), options: createOptions('NDVI', Math.max(...indicesData.ndvi, ...nitrogenValues)) },
        { label: 'VARI', data: createDataset(indicesData.vari), options: createOptions('VARI', Math.max(...indicesData.vari, ...nitrogenValues)) },
        { label: 'GLI', data: createDataset(indicesData.gli), options: createOptions('GLI', Math.max(...indicesData.gli, ...nitrogenValues)) },
        { label: 'EXG', data: createDataset(indicesData.exg), options: createOptions('EXG', Math.max(...indicesData.exg, ...nitrogenValues)) }
    ]

    const handleGridTableDisplay = () => {
        setOpenGridTable(false)
        setOpenGridChart(true)

    }

    const handleGridChartDisplay = () => {
        setOpenGridTable(true)
        setOpenGridChart(false)
    }

  return (
    <div className='fv__menu__blockChart__container'>
        
        <div className='gridChart__modal__cotaniner'>
            <div className='grid__header'>
                <h3>Weekly {openGridTable ? 'Chart': 'Table' }</h3>
                <div className='grid__header__actions'>
                    { 
                        openGridTable ?
                        <Button
                            style={{
                                textTransform: 'capitalize'
                            }}
                            variant='contained'
                            startIcon={
                                <FaTableList 
                                    size={'18px'}
                                />
                            }
                            onClick={handleGridTableDisplay}
                        >
                            Table
                        </Button>
                        : 
                        ''
                    }
                    
                    { 
                        openGridChart ? 
                        <Button
                            style={{
                                textTransform: 'capitalize'
                            }}
                            variant='contained'
                            startIcon={
                                <FaChartBar
                                    size={'18px'}
                                />
                            }
                            onClick={handleGridChartDisplay}
                            >
                            Chart
                        </Button>
                        : 
                        ''
                    }
                </div>
            </div>

            <div className='grid__chart__body'>
                    {/* <Line
                        data={ndviData}
                        options={optionsNDVI}
                    ></Line> */}
                {   
                    openGridTable ? 
                    chartData.map((chart, idx) => (
                        <div
                            key={idx}
                            className='grid__chart__contents'
                            style={{ width: '300px' }}
                        >
                            <Line
                                data={{ 
                                    labels: dataPoints, 
                                    datasets: chart.data 
                                }}
                                options={chart.options}
                            />
                        </div>
                    ))
                    :
                    <div className='grid__block__table'>
                    {
                        nutriendGridData && nutriendGridData?.data && nutriendGridData?.data.length > 0 ? (
                            <table className='farmview__nutrient_table'>
                                <thead>
                                    <tr>
                                        <th style={{width: '15%'}}>NDVI</th>
                                        <th style={{width: '15%'}}>VARI</th>
                                        <th style={{width: '15%'}}>GLI</th>
                                        <th style={{width: '15%'}}>EXG</th>
                                        <th style={{width: '15%'}}>% of N</th>
                                        <th style={{width: '25%'}}>Longitude</th>
                                        <th style={{width: '25%'}}>Latitude</th>
                                    </tr>
                                </thead>
                                <tbody className='farmview__nutrient__table__body'>
                                    {nutriendGridData?.data.map((grid, index) => (
                                        <tr key={index}>
                                            <td>{grid.ndvi}</td>
                                            <td>{grid.vari}</td>
                                            <td>{grid.gli}</td>
                                            <td>{grid.exg}</td>
                                            <td>{grid.nitrogen}</td>
                                            <td>{grid.longitude}</td>
                                            <td>{grid.latitude}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                        ''
                    )}
                    </div>
                }
            </div>

            <div className='grid__actions'>
                <Button
                    style={{
                        textTransform: 'capitalize'
                    }}
                    onClick={()=>setGridChartModal(false)}
                >
                    Close
                </Button>
            </div>
        </div>
    </div>
  )
}
