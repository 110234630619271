import React, { useContext } from 'react'
import Sidebar from '../components/Sidebar'
import { Outlet, useNavigate } from 'react-router-dom'
import './MainAppLayout.css'

import { UserContext, loginContext, AccountDropMenuContext } from '../Context/ContextProvider'
import Cookies from 'universal-cookie'
import { API_CONFIG } from '../config/ApiConfig'
import { MdOutlineKeyboardArrowDown } from "react-icons/md"
import { FaUser } from "react-icons/fa" 
import { IoLogOut } from "react-icons/io5"
import { Button } from '@mui/material'

export default function MainAppLayout() {
  const { userData } = useContext(UserContext)
  const { isLoggedIn } = useContext(loginContext)
  const { accountDropValue, isAccountDropValue } = useContext(AccountDropMenuContext)
  const navigate = useNavigate()
  const cookies = new Cookies()
  
  const toggleButton = (e) =>{
    isAccountDropValue(!accountDropValue)
    //setAnchorEl(e.currentTarget);
  }

  const logout = async (e) =>{
    e.preventDefault();
    const token = cookies.get('bansot_sess');

    try {
        let result = await fetch(`${API_CONFIG.HOST}/logout/`, {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${token}`
            }
        });
        
        result = await result.json();
        
        isLoggedIn(false)
        isAccountDropValue(false)
        cookies.remove("bansot_sess", { path: '/' })

        if(result){
          navigate('/login')
        }
    } catch (error) {
        console.error('Error:', error);
    }
  }

  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  return (
    <div className='main-app-layout'>
      <Sidebar/>
        <Outlet/>
        <div className='user__info__db'>
          <Button
            endIcon={<MdOutlineKeyboardArrowDown/>}
            onClick={toggleButton}
            disableRipple
            variant='raised'
            style={{
              background: 'transparent',
              textTransform: 'capitalize'
            }}
            // aria-controls={open ? 'account-menu' : undefined}
            // aria-haspopup="true"
            // aria-expanded={open ? 'true' : undefined}
          >
            <span>Hello, {userData.first_name}</span>
          </Button>
        </div>

        <div className='account__drop__container'                                           
          style={{ display: accountDropValue ? "flex" : 'none' }} 
        >
          <div className='drop__contents'>
            <ul>
              {
                userData.role === 'Admin' || userData.role === 'Manager' || userData.role === 'Customer' ?  
                  <li className='row__items'>
                    <Button
                      fullWidth
                      style={{
                        color:  'var(--fontColor__secondary)',
                        textTransform: 'capitalize',
                        justifyContent: 'flex-start',
                        padding: '0.8rem 1rem',
                      }}
                      startIcon={<FaUser size={'15px'} />}
                      onClick={()=>navigate('app/accounts')}
                    >
                      Account Manage
                    </Button>
                  </li>
                  :
                  ""
              }

              {/* <li className='row__items'>
                <Button
                  fullWidth
                  style={{
                    color:  'var(--fontColor__secondary)',
                    textTransform: 'capitalize',
                    justifyContent: 'flex-start',
                    padding: '0.8rem 1rem',
                  }}
                  startIcon={<FaUser size={'18px'}/>}
                  onClick={logout}
                >
                  Profile
                </Button>
              </li> */}

              <li className='row__items'>
                <Button
                  fullWidth
                  style={{
                    color:  'var(--fontColor__secondary)',
                    textTransform: 'capitalize',
                    justifyContent: 'flex-start',
                    padding: '0.8rem 1rem',
                  }}
                  startIcon={<IoLogOut size={'18px'}/>}
                  onClick={logout}
                >
                  Logout
                </Button>
              </li>
            </ul>
          </div>
        </div>

        {/* <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'account-menu',
          }}
        >
          <MenuItem onClick={toggleButton}>
            <FaUser /> Account Manage
          </MenuItem>
          <MenuItem onClick={toggleButton}>
            <FaUser /> Profile
          </MenuItem>
          <MenuItem onClick={toggleButton}>
          <IoLogOut/> Logout
          </MenuItem>
        </Menu> */}
    </div>
  )
}
