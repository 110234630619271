import { Button, InputAdornment, TextField } from '@mui/material'
import './css/ChartViewTable.css'
import React from 'react'
import { useChartSearchStore } from '../../store/chartStore'
import { CiSearch } from "react-icons/ci";
import { useGridSearchStore } from '../../store/gridStore';
import { useSearchParams } from 'react-router-dom';

const useChartSearchStoreSelector = (state) => state.setGridSearchValue
const useGridSelector = (state) => state.grid
const useSetGridSearchSelector  = (state) => state.setGridSearch

export default function ChartViewTable({ getGridList }) {
    const setGridSearchValue = useChartSearchStore(useChartSearchStoreSelector)
    const grid = useGridSearchStore(useGridSelector)

    //Implement search functionality
    const filterGrids = getGridList
    ? getGridList.filter((item) => {
        return grid.toLowerCase() === ''
            ? item
            : item.gridName.toLowerCase().includes(grid.toLowerCase());
        })
    : [];

    
  return (
    <div className='chartview__container__all'>
        <SearchGridComponent/>
        <div className='chartview__table__container__parent'>
            <table className='chartview__table__container'>
                <thead>
                    <tr>
                        <th 
                            //style={{width: '100px'}}
                        >
                            Grid
                        </th>
                        <th 
                            //style={{width: '20px'}}
                        >
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {filterGrids?.map(item => (
                        <tr key={item.gridName}>
                            <td>{item.gridName}</td>
                            <td>
                                <Button
                                    style={{
                                        //backgroundColor:  'var(--primary__color)',
                                        textTransform: 'capitalize'
                                    }}
                                    variant="contained"
                                    onClick={()=>setGridSearchValue(item.gridName)}
                                >
                                    view chart
                                </Button>
                            </td>
                        </tr>
                    ))}
                    {!filterGrids?.length &&
                        (<tr><td><label className='raster__records'>No data avaiable.</label></td></tr>)
                    }
                </tbody>
            </table>
        </div>
    </div>
  )
}

const SearchGridComponent = () => {
    const setGridSearch = useGridSearchStore(useSetGridSearchSelector)
    const [query] = useSearchParams()
    //console.log('render parent view content')
    const blockName = query.get('blockName')
    const company = query.get('company')

    return(
        <div className='search__grid__container'>
            <h4>{company} - {blockName} Grids list</h4>
            <TextField 
                fullWidth
                id="search-grid" 
                label="Search Grid..." 
                variant="standard" 
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <CiSearch/>
                        </InputAdornment>
                    )
                }}
                onChange={(e)=>setGridSearch(e.target.value)}
            />
        </div>
    )
}
