import React from 'react'
import './css/DashboardHeader.css'

export default function DashboardHeader() {
  return (
    <div className='dashboard__header__container'>
        <DBHeaderItem
            count = {'1'}
            title = {'Number of Grids'}
        />

        <DBHeaderItem
            count = {'1'}
            title = {'Flight counts'}
        />

        <DBHeaderItem
            count = {'0'}
            title = {'Intervention'}
        />
    </div>
  )
}


const DBHeaderItem = ({ count, title }) => {
    return(
        <div className='dashboard__header__item'>
            <label>{count}</label>
            <h4>{title.toUpperCase()}</h4>
        </div>
    )
}