import React from 'react'
import './css/NDVIComponent.css'
import { Button, styled, TextField } from '@mui/material'
import { ButtonProperty, CloseButton, SubmitButton } from '../../ReusableComponents/Buttons'
import { CiImport } from 'react-icons/ci'
import * as XLSX from 'xlsx';
import { FetchPredictionsNitrogenList, PostNDVI } from '../../api/nutrientAPI'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { getCategoryColor } from '../../utils/fileUtils'

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function NDVIComponent({gridId, showNDVIModal}) {

    const { data: getPredictedNDVI } = FetchPredictionsNitrogenList(gridId)
    console.log('data-', getPredictedNDVI)
    const { mutate: postNDVI } = PostNDVI(gridId)
    const queryClient = useQueryClient()

    const handleUploadNDVI = (e) => {
        const file = e.target.files[0]
        console.log(file)

        if (file) {
            const reader = new FileReader();
      
            reader.onload = (e) => {
            const binaryStr = e.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);
            //setData(jsonData);
            console.log(jsonData)

            const formData = new FormData()
            //console.log("BEFORE PUT ", gridData)
            const ndviInputJSON = JSON.stringify(jsonData) // CONVERT THE ARRAY TO TO JSON STRINGY
                    
            formData.append('ndviInput', ndviInputJSON)
            formData.append('gridId', gridId)

            postNDVI(formData, {
            onSuccess: (data) =>{
                queryClient.invalidateQueries('get-nitrogen-grids')

                toast.success('Successfully set the threshold.', {
                    position: "top-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    pauseOnFocusLoss: false,
                    theme: "light",
                });

            },
            onError: (error) =>{
                toast.error('Invalid threshold.', {
                    position: "top-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    pauseOnFocusLoss: false,
                    theme: "light",
                });
            }
        })


        };
      
            reader.readAsBinaryString(file);
        }

    }
    
  return (
    <div className='ndvi__container'>
        <div className='ndvi__content'>
            <div className='ndvi__header'>
                <h4>NDVI Crop Sensor</h4>

                <Button 
                    component="label"
                    style={{
                        backgroundColor:  'var(--primary__color)',
                        textTransform: 'capitalize'
                    }}
                    tabIndex={-1}
                    startIcon={<CiImport/>}
                    variant="contained"
                    //disabled={!!getNitrogenGrids?.data}
                >
                    Import
                    <VisuallyHiddenInput 
                        type="file" 
                        onChange={handleUploadNDVI}
                    />
                </Button>
            </div>

            <NDVITable 
                getPredictedNDVI = {getPredictedNDVI}
            />

            <div className='ndvi__content__inputs'>
                <TextField 
                    //error={nitrogenError.ndvi ? true : false}
                    id="standard-numbersic" 
                    label="NDVI" 
                    name='ndvi'
                    variant="outlined"
                    sx={{width: ButtonProperty.width}}
                    size={ButtonProperty.size}
                    inputProps={{type: 'number'}}
                    //onChange={handleNitrogenChange}
                    //value={nitrogen.ndvi}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <TextField 
                    //error={nitrogenError.longitude ? true : false}
                    id="outlined-basic" 
                    label="Longitude" 
                    variant="outlined"
                    name='longitude'
                    sx={{width: ButtonProperty.widthLonLat}}
                    size={ButtonProperty.size}
                    inputProps={{type: 'number'}}
                    //onChange={handleNitrogenChange}
                    //value={nitrogen.longitude}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <TextField 
                    //error={nitrogenError.latitude ? true : false}
                    id="outlined-basic" 
                    label="Latitude" 
                    variant="outlined"
                    name='latitude'
                    sx={{width: ButtonProperty.widthLonLat}}
                    size={ButtonProperty.size}
                    inputProps={{type: 'number'}}
                    //onChange={handleNitrogenChange}
                    //value={nitrogen.latitude}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />


            </div>
            
            <div className='ndvi__actions'>
                <CloseButton
                    name={'Close'}
                    onClick={()=>showNDVIModal(false)}
                />
                <SubmitButton
                    name={'Submit'}
                />
            </div>
        </div>
    </div>
  )
}

const NDVITable = ({getPredictedNDVI}) => {
    return(
        <div className='ndvi__table__container'>
            <table className='ndvi__table'>
                <thead>
                    <tr>
                        <th>NDVI</th>
                        <th>Predicted % of N</th>
                        <th>Category</th>
                        <th>Longitude</th>
                        <th>Latitude</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        getPredictedNDVI?.map((item)=>(
                            <tr key={item.pred_ndvi_id}> 
                                <td>{item.ndvi}</td>
                                <td>{item.predicted_nitrogen}</td>
                                <td>
                                    <span
                                        style={{ 
                                            backgroundColor: getCategoryColor(item.predicted_category),
                                            padding: '0.5rem 0.8rem',
                                            borderRadius: '2rem',
                                            width:'70px',
                                            color: 'black',
                                            textTransform: 'capitalize',
                                            fontWeight: '550'
                                        }}
                                    >
                                            {item.predicted_category}
                                    </span>
                                </td>
                                <td>{item.longitude}</td>
                                <td>{item.latitude}</td>
                            </tr>
                        ))
                    }
                    {!getPredictedNDVI  ?.length &&
                        (
                            <tr> 
                                <td colSpan="5"><label className='raster__list__records'>No data avaiable.</label></td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
    )
}
