import React from 'react'

export default function NotFound() {
  return (
    <div 
      style={{
        width: '100%',
        height: '100svh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <h3>404 || Page not found</h3>
    </div>
  )
}
