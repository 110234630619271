import React from 'react'
import './css/ChartViewHeader.css'
import { useNavigate } from 'react-router-dom'
import { BiArrowBack } from 'react-icons/bi'
import {  useChartSearchStore, useChartStore } from '../../store/chartStore'
import { Button } from '@mui/material'

const useChartStoreSelector = (state) => ({
  setChartData: state.setChartData
})

const useChartSearchStoreSelector = (state) => state.setGridSearchValue

export default function ChartViewHeader() {
  const { setChartData } = useChartStore(useChartStoreSelector)
  const setGridSearchValue = useChartSearchStore(useChartSearchStoreSelector)

  const navigate = useNavigate()

  const backToFarm = () => {
    setChartData('')
    setGridSearchValue('')
    navigate(-1)
  }

  return (
    <div className='chartivew__header__container'>
        <div className='back__link__db'>
            <Button onClick={backToFarm}><BiArrowBack size={29} color='black'/></Button>
        </div>
    </div>
  )
}
