import React, { useContext, useState } from 'react'
import './css/Intervention.css'
import { ToggleSidebarContext } from '../Context/ContextProvider';
import { PageTitle } from './Dashboard';
import InterventionInputModal from '../intervention/InterventionInputModal';
import InterventionContent from '../intervention/InterventionContent';
import InterventionHeader from '../intervention/InterventionHeader';

export default function Intervention() {
    const { toggleSidebarValue, isToggleSidebarValue } = useContext(ToggleSidebarContext)
    const [interventionModal, showInterventionModal] = useState(false)
    const [tabOpen, setTabOpen] = useState(0)

    const handleClickModalIntervention = () => {
      showInterventionModal(!interventionModal)
    }

    const toggle = () =>{
        isToggleSidebarValue(!toggleSidebarValue)
    }


  return (
    <div className='intervention__container'>
        <PageTitle
          title = {'Intervention'}
          onMenuClick = {toggle}
          createDisable={false}
          //onCreateClick = { handleClickModalIntervention }
        />

        <InterventionHeader
            tabOpen = { tabOpen }
            setTabOpen = { setTabOpen }
        />

          <InterventionContent
            tabOpen = { tabOpen}
            onClick = { handleClickModalIntervention }
          />

        { 
          interventionModal ?
            <InterventionInputModal
              showInterventionModal = { showInterventionModal }
            />
          :
          ''
        }
        
    </div>
  )
}