import { create } from "zustand";

export const useRasterDateStore = create((set)=>({
    date: '',
    setDateSearch: (data) => {
        set({ date: data})
    }
}))

export const useRasterGridStore = create((set)=>({
    grid: '',
    setGridSearch: (data) => {
        set({ grid: data})
    }
}))

export const useRasterBoundsStore = create((set)=>({
    lonlat: '',
    setLonLat: (data) => {
        set({ lonlat: data})
    }
}))