import React from 'react'
import './css/Legends.css'
import { MdRectangle } from 'react-icons/md'
import { color } from '../../config/mapColor'

export default function Legends() {
  return (
    <div className='legends__container'>
        <h4>Legend</h4> 
        <table className='legend__table'>
            <thead>
                <tr>
                    <th>
                        % Nitrogen
                    </th>
                    <th>
                        Color Code
                    </th>
                    <th>
                        Category
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1% - 3%</td>
                    <td> 
                        <MdRectangle 
                            size={20}
                            color={color.LOW}
                        /> 
                    </td>
                    <td>LOW</td>
                </tr>
                <tr>
                    <td>3% - 4%</td>
                    <td> 
                        <MdRectangle 
                            size={20}
                            color={color.NORMAL}
                        /> 
                    </td>
                    <td>NORMAL</td>
                </tr>
                <tr>
                    <td><span>{`> 4.1%`}</span></td>
                    <td> 
                        <MdRectangle 
                            size={20}
                            color={color.HIGH}
                        /> 
                    </td>
                    <td>HIGH</td>
                </tr>
            </tbody>
        </table>
    </div>
  )
}
