import React, { useContext } from 'react'
import './css/InterventionContent.css'
import { UserContext } from '../Context/ContextProvider'
import { FetchApplications, FetchInterventionActivityLogs } from '../api/interventionAPI'
import { formatDateTime, getStatusColor, toTitleCase } from '../utils/fileUtils'
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material'

export default function InterventionContent({ tabOpen, onClick }) {

  return (
    <div className='intervention__content__container'>
        <div className='intervention__content'>
            {/* SELECTING BETWEEN INTERVENTION AND APPLICATION CONTAINER */}
            {/* INTERVENION CONTAINER */}
            <InterventionPanel
                value={tabOpen}
                index={0}
            >
                <InterventionActivity />
            </InterventionPanel>

            {/* APPLICATION CONTAINER */}
            <InterventionPanel
                value={tabOpen}
                index={1}
            >
            <InterventionApplicationTable 
                onClick = { onClick }
                //listFertilizerApplication = { listFertilizerApplication }
            />
            </InterventionPanel>
            
        </div>
    </div>
  )
}

// INTERVENTION ACTIVITY COMPONENT
const InterventionActivity = () => {
    return(
        <div className='intervention__activity__container'>
            <div className='intervention__application__activity__header'>
                <h3>Activity Logs</h3>
            </div>

            {/* SEARCH INTERVENTION COMPONENT */}
            {/* <InterventionActivitySearch /> */}

            {/*  SEARCH INTERVENTION TABLE COMPONENT */}
            <InterventionActivityTable/>
        </div>
    )
}

// const InterventionActivitySearch = () => {
//     return(
//         <div className='report__search__container'>
//             <div className='report__select__location__container'>
//                 <div className='report__select__params'>
//                     <FormControl fullWidth size='small'>
//                         <InputLabel id="farm-label">Farm</InputLabel>
//                         <Select
//                         labelId="farm-label"
//                         id="farm-select"
//                         value={selectedFarm}
//                         label="Farm"
//                         //onChange={handleFarmChange}
//                         >
//                         {/* <MenuItem value='--ALL--'>--ALL--</MenuItem>
//                         {farms?.map(farm => (
//                             <MenuItem key={farm.name} value={farm.name}>{farm.name}</MenuItem>
//                         ))} */}
//                         </Select>
//                     </FormControl>

//                     <FormControl fullWidth size='small'>
//                         <InputLabel id="block-label">Block</InputLabel>
//                         <Select
//                         labelId="block-label"
//                         id="block-select"
//                         value={selectedBlock}
//                         label="Block"
//                         onChange={handleBlockChange}
//                         disabled={!selectedFarm || selectedFarm === '--ALL--'}
//                         >
//                         <MenuItem value='--ALL--'>--ALL--</MenuItem>
//                         {blocks?.map(block => (
//                             <MenuItem key={block.name} value={block.name}>{block.name}</MenuItem>
//                         ))}
//                         </Select>
//                     </FormControl>

//                     <FormControl fullWidth size='small'>
//                         <InputLabel shrink id="grid-label">Grid</InputLabel>
//                         <Select
//                             notched
//                             labelId="grid-label"
//                             id="grid-select"
//                             value={selectedGrid}
//                             label="Grid"
//                             onChange={handleGridChange}
//                             disabled={!selectedBlock || selectedBlock === '--ALL--' || grids.length === 1}
//                         >
//                         <MenuItem value='--ALL--'>--ALL--</MenuItem>
//                         {grids?.map(grid => (
//                             <MenuItem key={grid.name} value={grid.name}>{grid.name}</MenuItem>
//                         ))}
//                         </Select>
//                         {
//                             grids.length === 1 &&(
//                             <FormHelperText
//                                 style={{margin: '0'}}
//                                 margin='dense'
//                                 error={true}>
//                                     No Grids Found...
//                             </FormHelperText>
//                             )
//                         }
                        
//                     </FormControl>
//                 </div>

//                 <FormControl 
//                     size='small'
//                     sx={{
//                         width: '386px'
//                     }}
//                 >
//                     <InputLabel 
//                         //shrink
//                         id="month-label"
//                     >
//                         Month
//                     </InputLabel>
//                     <Select
//                         //disabled = {isLoading}
//                         //notched
//                         labelId="month-label"
//                         id="month-select"
//                         value = {searchDate}
//                         label="month"
//                         name="month"
//                         onChange={handleMonthChange}
//                     >
//                         <MenuItem value={'--ALL--'}>--ALL--</MenuItem>
//                         <MenuItem value={1}>JANUARY</MenuItem>
//                         <MenuItem value={2}>FEBRUARY</MenuItem>
//                         <MenuItem value={3}>MARCH</MenuItem>
//                         <MenuItem value={4}>APRIL</MenuItem>
//                         <MenuItem value={5}>MAY</MenuItem>
//                         <MenuItem value={6}>JUNE</MenuItem>
//                         <MenuItem value={7}>JULY</MenuItem>
//                         <MenuItem value={8}>AUGUST</MenuItem>
//                         <MenuItem value={9}>SEPTEMBER</MenuItem>
//                         <MenuItem value={10}>OCTOBER</MenuItem>
//                         <MenuItem value={11}>NOVEMBER</MenuItem>
//                         <MenuItem value={12}>DECEMBER</MenuItem>
//                     </Select>
//                 </FormControl>
//             </div>
            
            
            
//             <div className='report__header__actions'>
//                 <Button
//                     disableRipple
//                     style={{
//                         textTransform: 'capitalize',
//                         color: 'black',
//                         background: 'transparent'
//                     }}
//                     onClick={resetSearchParams}
//                 >
//                     Clear
//                 </Button>

//                 <Button
//                     disableRipple
//                     style={{
//                         textTransform: 'capitalize'
//                     }}
//                     variant='contained'
//                     onClick={handleQueryReportV2}
//                 >
//                     Search
//                 </Button>
//             </div>
//         </div>
//     )
// }

const InterventionActivityTable = () => {
    const { userData } = useContext(UserContext)
    
    const { data: listInterventionActivity } = FetchInterventionActivityLogs(userData?.company_id)
    console.log('listInterventionActivity', listInterventionActivity)

    return(
        <div className='intervention__activity__table__container'>
            <table className='intervention__activity__table'>
                <thead>
                    <tr>
                        <th>Location</th>
                        <th>Fertilizer</th>
                        <th>Status</th>
                        <th>Applicators</th>
                        <th>Dispatch Date</th>
                        <th>Date Finish</th>
                        <th>Remarks</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        listInterventionActivity?.map((item)=> (
                            <tr
                                key={item.id}
                            >
                                <td>{item.grid.block.farm.farmName} {item.grid.block.blockName} {item.grid.gridName}</td>
                                <td>{toTitleCase(item.fertilizer.fertilizer_type)}</td>
                                <td>
                                    <span
                                        style={{
                                        backgroundColor: getStatusColor(item.status),
                                        padding: '0.5rem 0.8rem',
                                        borderRadius: '2rem',
                                        width:'70px',
                                        color: 'black',
                                        textTransform: 'capitalize',
                                        fontWeight: '550',
                                        }}
                                    >
                                        {item.status}
                                    </span>
                                </td>
                                <td>{toTitleCase(item.applicator.first_name)} {toTitleCase(item.applicator.first_name)}</td>
                                <td>{formatDateTime(item.date_assigned)}</td>
                                <td>{formatDateTime(item.date_assigned)}</td>
                                <td>{item.remarks}</td>
                            </tr>
                        ))
                    }
                        {!listInterventionActivity?.length &&
                            (
                                <tr>
                                    <td colSpan="7">
                                        <label className='raster__list__records'>No Intervention Logs.</label>
                                    </td>
                                </tr>
                            )
                        }
                </tbody>
            </table>
        </div>
    )
}

// INTERVENTION TABLE COMPONENT
const InterventionApplicationTable = ({ onClick }) => {
    const { userData } = useContext(UserContext)

    const { data: listFertilizerApplication } = FetchApplications(userData?.company_id)

    //console.log('list ', listFertilizerApplication)

    return(
        <div className='intervention__application__container'>
            <div className='intervention__application__header'>
                <h3>Feritlizer Application List</h3>
                <Button
                    variant="contained"
                    disableRipple
                    style={{
                        textTransform: 'capitalize',
                        backgroundColor: 'var(--primary__color)',
                    }}
                    onClick={onClick}
                >
                    Create
                </Button>
            </div>
            
            <table className='intervention__application__table'>
                <thead>
                    <tr>
                        <th>Fertilizer</th>
                        <th>Low (g)</th>
                        <th>Normal (g)</th>
                        <th>High (g)</th>
                        <th>Created At</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        listFertilizerApplication?.map((item)=>(
                            <tr key={item.intervention_id}>
                                <td>{item.fertilizer_type}</td>
                                <td>{item.low}</td>
                                <td>{item.normal}</td>
                                <td>{item.high}</td>
                                <td>{formatDateTime(item.createdAt)}</td>
                            </tr>
                        ))
                    }

                    {
                        !listFertilizerApplication?.length &&
                            (
                                <tr>
                                    <td colSpan="6">
                                        <label className='raster__list__records'>No Applications found.</label>
                                    </td>
                                </tr>
                            )
                    }
                </tbody>
            </table>
        </div>
    )
}


const InterventionPanel = (props) => {
    const { children, value, index, ...other } = props;
  
      return(
          <div
              role="tabpanel"
              hidden={value !== index}
              id={`full-width-tabpanel-${index}`}
              aria-labelledby={`full-width-tab-${index}`}
              {...other}
          >
              {children}
          </div>
      )
  }