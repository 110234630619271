import React from 'react'
import './css/Contact.css'

export default function Contact() {
  return (
    <>
    <div className='contact-container'>
      <div className='contact-header'>
        <h1>We'd love to hear from you!</h1>
      </div>
      <div className='contact-items'>
        <div className='contact-details'>
          <p>
            <strong>Address: </strong>
            USeP Apokon
            <br/>
            <strong>Email: </strong>
            multispecproject@usep.edu.ph
            <br/>
          </p>
        </div>
      </div>
    </div>
    </>
  )
}
