import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { MapContainer, TileLayer, WMSTileLayer, Marker, Popup } from 'react-leaflet'
//import { EditControl } from "react-leaflet-draw"
import 'leaflet/dist/leaflet.css'

import './css/FarmView.css'
// import LegendPlantHealth from './Legend'
import { BiArrowBack } from "react-icons/bi";
//import LoadFetch from '../feature/LoadFetch'

//import RasterDetails from '../feature/RasterDetails'
import FarmViewMenu from '../feature/FarmViewMenu'
import { useMediaQuery } from 'react-responsive'
import { GiHamburgerMenu } from "react-icons/gi";

import { GEO_CONFIG } from '../config/ApiConfig'
import { FetchLayers } from '../api/ApiMethods'
import GeoJSONLoader from '../feature/subfeature/GeoJSONLoader'
import SensorMapDisplay from '../feature/subfeature/SensorMapDisplay'
import GridChartModal from '../feature/Modal/GridChartModal'
import L from 'leaflet'
import { Button } from '@mui/material'
import { useRasterBoundsStore, useRasterDateStore, useRasterGridStore } from '../store/rasterStore';
// import FarmViewStatusDisplay from '../Maps/FarmViewStatusDisplay'
import MapVegetationTable from '../Maps/MapVegetationTable'
import { useMapStore } from '../store/mapStore'

import cardinalDirection from '..//assets/cardinal-direction.png'

// const api = {
//   key: '6c92f61ae911409dbbd12456232206 ',
//   base: 'https://api.weatherapi.com/v1/',
//   loc: "tagum"
// }

const useRasterSetDateSelector = (state)=> state.setDateSearch
const useRasterSetGridSelector = (state)=> state.setGridSearch
const useRasterBoundLonLatSelector = (state)=> state.lonlat

const useMapStoreModalSelector = (state)=> state.modalOpen

function FarmView() {
  const defaultCoordinates = [7.419561215603675, 125.83128936186068] // USEP COORDINATES
  //const mapRef = useRef(null)


  //const [polygonCoords, setPolygonCoords] = useState([]);
  //const [isCreatingPolygon, setIsCreatingPolygon] = useState(false);

  const [query] = useSearchParams()

  const bid = query.get('bid')
  const company = query.get('company')
  const COORDS = {
    'lat': 7.419561215603675,
    'lng': 125.83128936186068
  }

  const setDateStore = useRasterDateStore(useRasterSetDateSelector)
  const setGridStore = useRasterGridStore(useRasterSetGridSelector)
  const lonlat = useRasterBoundsStore(useRasterBoundLonLatSelector)
  const modalOpen = useMapStore(useMapStoreModalSelector)
  

  const { data: getLayers, isSuccess} = FetchLayers(bid)
  //const { data: getNutrients } = GetNutrientThreshold(fid)  
  //console.log('latest_layer grids',getLayers)

  const isMobile = useMediaQuery({ maxWidth: 768 })
  const [mapMenuOpen, isMapMenuOpen] = useState(false)
  const [vegetationStatus, setVegetationStatus] = useState('')

  const [layer, setLayer] = useState()
  const [displayPoints, setPoints] = useState()
  const [gridChartModal, setGridChartModal] = useState(false)
  const [nutriendGridData, setNutrientGridData] = useState()
  
  const [geoLoaderWFS, setGeoLoaderWFS] = useState()
  const [isWeather, setWeather] = useState()
  const [isSensor, setSensor] = useState()
  const [shouldFetch, setShouldFetch] = useState(false)

  const [layerName, setLayerName] = useState()
  const navigate = useNavigate()
  
  //const raster_bounds = getLayers?.data_latest.bounds
  const avgLat = lonlat ? (lonlat.max_lat + lonlat.min_lat) / 2 : COORDS.lat;
  const avgLng = lonlat ? (lonlat.max_lng + lonlat.min_lng) / 2 : COORDS.lng;

  const handleMenuClick = () =>{
    isMapMenuOpen(!mapMenuOpen)
  }

  const getApiUrl = (endpoint) => `${GEO_CONFIG.HOST}:${GEO_CONFIG.PORT}/${endpoint}`;

  //console.log(`Parent container: == ${company}:${layerName}`)
  //console.log('dp', displayPoints)

  // const greenMarker = new L.Icon({
  //   iconUrl: require("../assets/greenMarker.png"),
  //   iconSize: [40,45],
  //   iconAnchor: [12, 41],
  //   popupAnchor: [1, -34],
  // })

  // const redMarker = new L.Icon({
  //   iconUrl: require("../assets/redMarker.png"),
  //   iconSize: [40,45],
  //   iconAnchor: [12, 41],
  //   popupAnchor: [1, -34],
  // })

  // const yellowMarker = new L.Icon({
  //   iconUrl: require("../assets/yellowMarker.png"),
  //   iconSize: [40,45],
  //   iconAnchor: [12, 41],
  //   popupAnchor: [1, -34],
  // })


  const assignMarker = (category) => {
    if(category === 'LOW'){
      return new L.Icon({
        iconUrl: require("../assets/redMarker.png"),
        iconSize: [40,45],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
      })
    }else if(category === 'NORMAL'){
      return new L.Icon({
        iconUrl: require("../assets/yellowMarker.png"),
        iconSize: [40,45],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
      })
    }else if(category === 'HIGH'){
      return new L.Icon({
        iconUrl: require("../assets/greenMarker.png"),
        iconSize: [40,45],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
      })
    }

  }

  const backToFarm = () => {
    navigate('/app/farm')
    setDateStore('')
    setGridStore('')
  }


 return (
   <div className='farmView-container'>
    
    <div id='map'>
      {/* {!loaderMap && avgLat?  */}

       <MapContainer 
        key={JSON.stringify(avgLat)}
        center={avgLat ? [avgLat, avgLng]: defaultCoordinates} 
        zoom={19} 
        scrollWheelZoom={true} 
        zoomControl={false} 
        //onClick={handleMapClick}
        >
        
        { 
          layerName ? 
            <GeoJSONLoader
              layerName={layerName}
              company={company}
              setGeoLoaderWFS={setGeoLoaderWFS}
              geoLoaderWFS={geoLoaderWFS}
            />
          : 
          ''
        }
          
        {/* <LocationFinderDummy/> */}

        <TileLayer
          key={JSON.stringify('test')}
          attribution='&copy; Imagery from USeP Multispectral Project'
          url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
          maxNativeZoom={19}
          maxZoom={25}
        />


            <WMSTileLayer 
              key={`${company}:${layer}:defaultLayer`}
              //url={`${GEO_CONFIG.HOST}/geoserver/${company}/wms`}
              url={`${GEO_CONFIG.HOST}/geoserver/${company}/wms`}
              layers={`${company}:${layer}`}
              maxZoom={25}
              transparent
              format='image/png'
              version='1.1.0'
            />

            {
              displayPoints ?
                displayPoints?.map((item, index) => (
                <Marker
                  key={`marker-${index}-${item.longitude}-${item.latitude}`}
                  position={[item.longitude, item.latitude]}
                  icon={assignMarker(item.predicted_category) }
                >
                  <Popup key={`popup-${index}-${item.longitude}-${item.latitude}`}>
                    <div>
                      <strong>Marker Id :</strong> {item.marker_id}<br />
                      <strong>NDVI :</strong> {item.ndvi}<br />
                      <strong>Predicted % of Nitrogen:</strong> {item.predicted_nitrogen}<br />
                      <strong>Category:</strong> {item.predicted_category}
                    </div>
                  </Popup>
                </Marker>
              ))
              :''
            }
       </MapContainer>
       
       <div className='map__top__container'>
          <div className='map__top__content'>
            <div className='back__link__db'>
              {/* <Link to='/app/farm' ><BiArrowBack size={29} color='black'/></Link> */} 
              <Button 
                style={{ background: 'transparent' }}
                disableRipple 
                onClick={backToFarm}>
                  <BiArrowBack size={29} color='black'/>
                </Button>
            </div>
            
            <div 
              style={{ display: isMobile ? 'flex': 'none'}}
              className='menu__hamburger'>
              <button
                onClick={handleMenuClick}><GiHamburgerMenu size={'1em'}/>
              </button>
            </div>
          </div>
       </div>

        {
          isWeather && (
          <SensorMapDisplay 
            defaultWeather = { getLayers?.weather_first }
            getWeather = { getLayers?.weather }
            isWeather = { isWeather }
            isSensor = { isSensor }
          />
        )}

          {/* <div
            className='maps__config'>
            <MapConfig 
              getLayerOpacity={getLayerOpacity}
              modalConfig={modalConfig}
              isModalConfig={isModalConfig}
              />
          </div> */} 

          <div className='maps__cardinal__directions'>
            <img 
              className='cardinal__image'
              src={ cardinalDirection } alt='cardinal__image'
            />
          </div>

          {/* POP UP CHARTS MODAL */}
          {
            gridChartModal ? 
              <GridChartModal
                nutriendGridData = { nutriendGridData }
                setGridChartModal = { setGridChartModal }
              />
          : 
          ''
          }
    </div>

      { 
        isSuccess ?
          <div 
            key={mapMenuOpen}
            transition={{ duration: 0.1 }}
            className='farmview__side__menubar'
            style={{ display: mapMenuOpen ? isMobile ? 'block' : 'none' : !isMobile ? 'block' : 'none'}}
            >
            <FarmViewMenu
              setLayer = { setLayer }
              setLayerName = { setLayerName }
              isMapMenuOpen = { isMapMenuOpen }
              mapMenuOpen = { mapMenuOpen }
              isMobile = { isMobile }
              setVegetationStatus = { setVegetationStatus }
              vegetationStatus = { vegetationStatus }
              setGridChartModal = { setGridChartModal }
              gridChartModal = { gridChartModal }
              setNutrientGridData = { setNutrientGridData }
              setPoints = { setPoints }
              setGeoLoaderWFS={setGeoLoaderWFS}
              getWeather = { getLayers?.weather }
              setWeather = { setWeather }
              getLayers = { getLayers?.data }
              setShouldFetch = { setShouldFetch }
              shouldFetch = { shouldFetch }
              setSensor = { setSensor }
            />
          </div>
      :
        ""
      }

      {
        modalOpen && (
          <MapVegetationTable/>
        )
      }

   </div>
 )
}

export default FarmView