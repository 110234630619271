import React, { useState } from 'react'
import './css/RasterUploadModal.css'
import { motion } from 'framer-motion'
import { ClipLoader } from 'react-spinners'
import { UploadRaster } from '../api/ApiMethods'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import TextField from '@mui/material/TextField'
import dayjs from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Button from '@mui/material/Button'
import { MdCloudUpload } from "react-icons/md"
import { AiOutlineDelete } from "react-icons/ai"
import { useSearchParams } from 'react-router-dom'

export default function RasterUploadModal({modalValue, company, farmName, blockName}){
    const ButtonProperty = {
        size: 'small',
    }

    const [query] = useSearchParams()
    const bid = query.get('bid')

    const formattedDate = dayjs().format('YYYYMMDDHHmmss');
    //const dateNow = dayjs().format('YYYY-MM-DD')

    const [rasterFile, isRasterFile] = useState()
    const [shapefile, isShapefile] = useState()

    const [rasterForm, setRasterForm] = useState({
        'gridName': '',
        //'block_name': '',
        //'flight_date': null,
    })

    const [rasterError, setRasterError] = useState({
        'gridName': '',
        //'flight_date': '',
        //block_name': '',
    })

    const [flightDate, setFlightDate] = useState(null)
    const [errorrFlightDate, isErrorFlightDate] = useState(false)

    const queryClient = useQueryClient()

    //console.log(flightDate)
    //const { data: getRasterList } = GetFarmRaster(farmID)
    const { mutate: uploadRaster, isLoading } = UploadRaster()

    const handleRaster = (e) =>{
        const rasterFile = e.target.files[0]
        const fileExtension = rasterFile.name.substring(rasterFile.name.lastIndexOf('.'))

        // Replace spaces with underscores and convert to uppercase
        const blockFileName = blockName.replace(/\s+/g, '-').toUpperCase();
        const newGridName = rasterForm.gridName.replace(/\s+/g, '-').toUpperCase();

        const new_rasterName = `${blockFileName}_${newGridName}_${formattedDate}${fileExtension}` // test_companyID_weekISO_dateToday.tif

        const newRasterFile = new File([rasterFile], new_rasterName, { type: rasterFile.type})

        isRasterFile(newRasterFile)
    }

    const handleShapefile = (e) => {
        const files = e.target.files;
        const acceptedExtensions = ['.shp', '.cpg', '.dbf', '.shx', '.prj'];
    
        const fileArray = Array.from(files);
        const newShapefileArray = []
    
        fileArray.forEach((file) => {
            const fileExtension = '.' + file.name.split('.').pop().toLowerCase();
            if (acceptedExtensions.includes(fileExtension)) {
                // Handle the accepted file here
                //console.log('Processing file:', file.name);
                // You can call another function to handle the file, or perform any desired operations
                // Replace spaces with underscores and convert to uppercase
                const blockFileName = blockName.replace(/\s+/g, '-').toUpperCase();
                const newGridName = rasterForm.gridName.replace(/\s+/g, '-').toUpperCase();
                
                const new_shapeFileName = `${blockFileName}_${newGridName}_${formattedDate}${fileExtension}`
                
                const newShapefile = new File([file], new_shapeFileName, { type: file.type })

                newShapefileArray.push(newShapefile)

                isShapefile(newShapefileArray)

            } else {
                toast.error('Invalid file extension.', {
                    position: "top-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    pauseOnFocusLoss: false,
                    theme: "light",
                });
            }
        });
    };

    // const removeRaster = (e) =>{
    //     isRasterFile(null)
    // }

    // submit put request for the raster file
    
    const handleSubmit = async(e) =>{
        e.preventDefault()

        const formData = new FormData()

        for (const key in rasterForm) {
            formData.append(key, rasterForm[key]);
        }

        if(flightDate==null){
            isErrorFlightDate(true)
            return(
            toast.error('Please provide a date.', {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
                pauseOnFocusLoss: false,
                })
            )
        }
        

        const parsedDate = new Date(flightDate);
        // // Extract the date part
        const year = parsedDate.getFullYear();
        const month = parsedDate.getMonth() + 1; // Month is zero-indexed
        const day = parsedDate.getDate();

        // // Format the date in YYYY-MM-DD format
        const finalDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

        formData.append('block_id', bid)
        formData.append('raster_file', rasterFile)
        formData.append('block_name', blockName)
        formData.append('upload_raster_date', finalDate ? finalDate : '')
        formData.append('company', company)
        formData.append('farmName', farmName)

          // Assuming shapefile is an array of files
        if (shapefile && shapefile.length > 0) {
            for (let i = 0; i < shapefile.length; i++) {
            formData.append('area_boundary_boundingbox_shapefile', shapefile[i]);
            }
        } else {
            console.error('No shapefile selected');
        }

        // To console log the FormData content
        // for (const [key, value] of formData.entries()) {
        //     console.log(`${key}: ${value}`);
        // }

        uploadRaster(formData, {
                onSuccess: (data)=>{

                setRasterForm({
                    farm_name: '',
                    block_name: '',
                })

                setRasterError({
                    farm_name: '',
                    block_name: '',
                })

                if(data.error){
                    
                }else{
                    queryClient.invalidateQueries('get-raster')
                    toast.success('Upload successfully.', {
                        position: "top-right",
                        autoClose: 2500,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        pauseOnFocusLoss: false,
                        progress: undefined,
                        theme: "light",
                        });   
                    modalValue(false)
                }
            },
            onError: (error)=>{
                console.log(error.response.data)

                if(error.response.data.farmRequire){
                    toast.error('Please enter Grid Name.', {
                      position: "top-right",
                      autoClose: 2500,
                      hideProgressBar: false,
                      closeOnClick: false,
                      pauseOnHover: false,
                      draggable: false,
                      progress: undefined,
                      pauseOnFocusLoss: false,
                      theme: "light",
                    });
                    setRasterError({
                        farm_name: true,
                        block_name: true,
                    })
                      
                }

                if(error.response.data.rasterRequire){
                    toast.error('Please select a file.', {
                      position: "top-right",
                      autoClose: 2500,
                      hideProgressBar: false,
                      closeOnClick: false,
                      pauseOnHover: false,
                      draggable: false,
                      progress: undefined,
                      theme: "light",
                      pauseOnFocusLoss: false,
                      });
                      
                  }

                  if(error.response.data.flightDateRequired){
                    toast.error('Please provide a date.', {
                      position: "top-right",
                      autoClose: 2500,
                      hideProgressBar: false,
                      closeOnClick: false,
                      pauseOnHover: false,
                      draggable: false,
                      progress: undefined,
                      theme: "light",
                      pauseOnFocusLoss: false,
                      });
                      isErrorFlightDate(true)

                  }
            }
        })
    }

    const handleFormChange = (e) => {
        setRasterForm({
          ...rasterForm,
          [e.target.name]: e.target.value,
        })
  
        setRasterError((prevError) => ({
          ...prevError,
          [e.target.name]: ""
        }));
      }
      
    const handleDateChange = (newVal) => {
        isErrorFlightDate(false)
        setFlightDate(newVal)
    }

    // console.log('nsf--', shapefile)

  return (
    <div className='raster__upload__container'>
        <motion.div 
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3}}
            className='raster__input'>
            <h3>Grid</h3>

            <form className='form__raster' onSubmit={handleSubmit}>
                    <div className='weather__type__container'>
                        <TextField 
                            disabled = { isLoading }
                            error = {rasterError.gridName ? true : false}
                            fullWidth 
                            name = 'gridName'
                            value = {rasterForm.gridName}
                            id = "outlined-basic" 
                            label = "Grid name" 
                            size={ButtonProperty.size}
                            variant = "outlined" 
                            onChange = {handleFormChange}
                        />

                        {/* <TextField 
                            disabled = { isLoading }
                            error = {rasterError.block_name ? true : false}
                            fullWidth 
                            name = 'block_name'
                            value = {rasterForm.block_name}
                            id = "outlined-basic" 
                            label = "Block name" 
                            size={ButtonProperty.size}
                            variant = "outlined" 
                            onChange = {handleFormChange}
                        /> */}

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    disabled = { isLoading }
                                    label='Flight Date'
                                    value={flightDate}
                                    onChange={handleDateChange}
                                    slotProps={{
                                        textField:{
                                            error: errorrFlightDate ? true : false,
                                            size:'small'
                                        }
                                    }}
                                />
                        </LocalizationProvider>
                    </div>

                    <RGBUploadComponent 
                        ButtonProperty={ButtonProperty}
                        isLoading={isLoading}
                        handleRaster={handleRaster}
                        rasterFile={rasterFile}
                        rasterForm ={rasterForm}
                        handleShapefile={handleShapefile}
                        shapefile={shapefile}
                    />


                    {/* {
                        dataCategory === 'RGB' ?
                            
                        :
                        dataCategory === 'Sentinel-2' ?
                            <RGBUploadComponent 
                                ButtonProperty={ButtonProperty}
                                isLoading={isLoading}
                                handleRaster={handleRaster}
                                rasterFile={rasterFile}
                                rasterForm ={rasterForm}
                                handleShapefile={handleShapefile}
                                shapefile={shapefile}
                            />
                        :
                        dataCategory === 'NDVI Crop Sensor' ?
                        ''
                        :
                        ''

                    } */}

                <div className='raster__buttons'>
                    <Button
                        style={{
                            backgroundColor:  'var(--no_background)',
                            color: 'var(--fontColor__secondary)',
                            textTransform: 'capitalize',
                            boxShadow: 'none',
                        }}
                        variant="contained"
                        onClick={()=> modalValue(false)}
                        >
                        <span>Close</span>
                    </Button>

                    <Button 
                        style={{
                        backgroundColor:  'var(--primary__color)',
                        textTransform: 'capitalize'
                        }}
                        disabled={isLoading ? true : false}
                        loading={isLoading.toString()}
                        loadingposition="start" 
                        startIcon={ <ClipLoader color="white" loading={ isLoading } size={15}/>}
                        variant="contained"
                        type='submit'
                        >
                        <span>Upload</span>
                    </Button>
                </div>      
            </form>
        </motion.div>
    </div>
  )
}


export const RGBUploadComponent = ({ButtonProperty, isLoading, handleRaster, rasterFile, rasterForm, handleShapefile, shapefile}) => {
    return(
        <>
            <div className='upload__raster__container'>
                <Button
                    variant='contained'
                    component='label'   
                    size={ButtonProperty.size}
                    disabled={rasterForm.farm_name === '' ? true : false || isLoading }
                    startIcon={<MdCloudUpload/>}
                >
                <span>Select Raster file</span>
                <input 
                    type="file" 
                    hidden 
                    accept='image/*'
                    disabled={ isLoading }
                    onChange={handleRaster}
                />
                </Button>

                <label className='allowed__extension'>Allowed Extension :(.tiff, .geotiff, .tif)</label>
                {
                    rasterFile ?
                        (
                            <div className='list__file__container'>
                                <label className='file__extension__name'>{rasterFile.name}</label>
                                <Button endIcon={<AiOutlineDelete color='red'/>}></Button>
                            </div>
                                        
                        ) 
                            : 
                            ''
                }
            </div>

            <div className='upload__shapefile__container'>
                <Button
                    variant='contained'
                    component='label'   
                    disabled={rasterForm.farm_name === '' ? true : false || isLoading}
                    startIcon={<MdCloudUpload/>}
                    size={ButtonProperty.size}
                >

                <label>Select Shapefile</label>
                <input 
                    type="file" 
                    hidden
                    multiple
                    disabled={isLoading}
                    onChange={handleShapefile}
                />
                </Button>

                <label className='allowed__extension'>Allowed Extension :(.shp, .cpg, .dbf, .prj, .shx)</label>
                {
                    shapefile ?
                        (
                        <div className='list__file__container'>
                            <label className='file__extension__name'>{shapefile?.length} Files selected</label>
                            <Button endIcon={<AiOutlineDelete color='red'/>}></Button>
                        </div>
                        ) 
                        : 
                        <label className='file__extension__name'></label>
                }
            </div>
        </>
    )
}