import React, { useEffect } from 'react'
import { GEO_CONFIG } from '../../config/ApiConfig'
import { GeoJSON } from 'react-leaflet'
import { color } from '../../config/mapColor'

export default function GeoJSONLoader({ layerName, company, setGeoLoaderWFS, geoLoaderWFS }) {
      //console.log(`${company}:${layerName}`)
      //const [selectedVI, setSelectedVI] = useState()
    
      useEffect(() => {
        const wfsUrl = `${GEO_CONFIG.HOST}/geoserver/ows`
        //const wfsUrl = `${GEO_CONFIG.HOST}:${GEO_CONFIG.PORT}/geoserver/ows`
        const wfsParams = new URLSearchParams({
          service: 'WFS',
          version: '1.0.0',
          request: 'GetFeature',
          typeName: `${company}:${layerName}`,
          outputFormat: 'application/json'
        })
    
      //console.log(`Useeffect: ${wfsUrl}?${wfsParams}`)
      
        fetch(`${wfsUrl}?${wfsParams}`)
          .then((response) => response.json())
          .then((data) => {
            //console.log(data)
            if (data.features && data.features.length > 0) {
              setGeoLoaderWFS(data)
              //console.log('WFS data successfully retrieved:', data);
              //console.log('selected vi:', data.features.properties);
            } else {
              console.log('No features found in the WFS response.');
            }
          })
          .catch((error) => {
            console.error('Error fetching WFS data:', error);
          });
    
      },[layerName, company, setGeoLoaderWFS])
      
      // Initialize an array to store mean_avg values
      const meanAvgArray = []
      
      const getColor = (category) => {
        switch (category) {
          case 'LOW':
            return color.LOW;
          case 'NORMAL':
            return color.NORMAL;
          case 'HIGH':
            return color.HIGH;
          default:
            return 'gray';
        }
      };

      const onEachShape = (feature, layer) => {
        const gridBoxId = feature?.properties?.gridbox_id
        const mean_avg = feature?.properties?.mean?.toFixed(3)
        //const layerName = feature.properties.layer
        const area = feature?.properties?.area?.toFixed(2);
        //const VIType = feature.properties.vegetation
        const category = feature?.properties?.category
        //const overAllMean = feature?.properties?.overall_me?.toFixed(3);
        const {min, mean, max, pred_nitro} = feature?.properties

        meanAvgArray.push(mean_avg)
        //console.log('val', feature?.properties)
        
        //layer.bindTooltip(layerName)
        layer.bindPopup(
          `
          <b>Grid Box Id: </b>${gridBoxId}<br>
          <b>Min: </b>${min?.toFixed(3)}<br>
          <b>Mean: </b>${mean?.toFixed(3)}<br>
          <b>Max: </b>${max?.toFixed(3)}<br>
          <b>Category:</b>${category}<br>
          <b>Area: </b>${area} <b>sq/m</b> <br> 
          <b>Predicted % of Nitrogen:</b> ${pred_nitro}`
        )
        
        layer.on({
          click: (e) => {
            //console.log("EVENT: ", e.target.feature)
          }
        })
      }
    
      const stateStyles = (feature) => {
        return {
          fillColor: getColor(feature.properties.category),
          color: 'black',
          fillOpacity: 0.8,
          weight: 2,
        };
      };

      //console.table('state: ', geoLoaderWFS?.features)
    
      return (
        <div>
          <GeoJSON 
            key={JSON.stringify(geoLoaderWFS?.features)}
            data={geoLoaderWFS?.features}
            style={stateStyles}
            onEachFeature={onEachShape}
          />
        </div>
      )
  }
