import './css/Farm.css'
import { useNavigate } from 'react-router-dom'
import React, { useContext, useState } from 'react'
import FarmTable from '../feature/FarmTable';
import { motion } from 'framer-motion';
import { IoAddOutline, IoMenuSharp } from "react-icons/io5";
import { ToggleSidebarContext } from '../Context/ContextProvider'
import Button from '@mui/material/Button'
import { UserContext } from '../Context/ContextProvider';
import AddBlockModal from '../CustomerComponent/AddBlockModal';
import UserFarmTable from '../CustomerComponent/UserFarmTable';
import { InputAdornment, TextField } from '@mui/material';
import { CiSearch } from 'react-icons/ci';
import CreateFarm from '../CustomerComponent/CreateFarm';
import { FetchBlocks } from '../api/ApiMethods';

export default function Farm() {
    
  const { userData } = useContext(UserContext)

  const navigate = useNavigate()
  const [search, isSearch] = useState('')
  const [pageNumber, setPageNumber] = useState(1)
  const { toggleSidebarValue, isToggleSidebarValue } = useContext(ToggleSidebarContext)
  const [blockModal, setBlockModal] = useState(false)
  const [farmModal, setFarmModal] = useState(false)

  const { data: fetchBlocks, isLoading } = FetchBlocks(pageNumber, userData?.company_id)
  
  const toggle = () =>{
    isToggleSidebarValue(!toggleSidebarValue)
  }
    
  const handleSearch = (e) =>{
    isSearch(e.target.value)
    //setPageNumber(1)
  }

  //console.log(fetchBlocks)

  return (
    <div className='farm-container'>
        <div className='farm-title'>
          <Button
            disableRipple
            style={{
              background: 'transparent',
              color: 'black'
            }}
            startIcon={<IoMenuSharp/>}
            onClick={toggle}
          >
          </Button>
            <h2>Farm</h2>
            {
              userData.role === 'Admin' ? 
                <Button 
                  style={{
                    backgroundColor:  'var(--primary__color)',
                    textTransform: 'capitalize',
                    marginLeft: '0.5rem'
                  }}
                  startIcon={<IoAddOutline/>}
                  variant="contained"
                  onClick={()=> navigate('/app/farm/create') }
                  >
                  <span>Create</span>
                </Button>
                :
                ''
            }

            {
              userData.role === 'Admin' || userData.role === 'Manager' ?
              <>
                <Button 
                  style={{
                        backgroundColor:  'var(--primary__color)',
                        textTransform: 'capitalize',
                        marginLeft: '0.5rem'
                  }}
                    startIcon={<IoAddOutline/>}
                    variant="contained"
                    onClick={()=> setFarmModal(true) }
                      >
                      <span>Create farm</span>
                </Button>
                
                <Button 
                  style={{
                    backgroundColor:  'var(--primary__color)',
                    textTransform: 'capitalize',
                    marginLeft: '0.5rem',
                  }}
                  startIcon={<IoAddOutline/>}
                  variant="contained"
                  onClick={()=> setBlockModal(true) }
                >
                  <span>Create Block</span>
                </Button>
              </>
              :
              ''
            }
            
            
        </div>

        <div className='farm-search'>
          {/* <i className="fa-solid fa-magnifying-glass fa-flip-horizontal"></i>
          <input type='search' placeholder='Search Block...'
            value={search}
            onChange={handleSearch}
          /> */}
          <TextField 
            fullWidth
            id="search-block" 
            label="Search block..." 
            variant="standard" 
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <CiSearch/>
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </div>   

        {/* { 
          userData.role === 'Admin' ? */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.8}}
              className='farm-card-container'>
                  <FarmTable
                    search={search}  
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    fetchBlocks={fetchBlocks}
                    isLoading={isLoading}
                  />
            </motion.div>
          { /*:
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.8}}
              className='farm-card-container'>
                  <UserFarmTable
                    search={search}  
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                  />
            </motion.div>
          }
            */}
        {
          blockModal ?
          <AddBlockModal 
            setBlockModal = { setBlockModal }
          />
          : ''
        }

        {
          farmModal ?
          <CreateFarm 
            setFarmModal = { setFarmModal }
          />
          : ''
        }
    </div>
  )
}
