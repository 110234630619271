import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { API_CONFIG } from "../config/ApiConfig";
import Cookies from "universal-cookie";

// Helper function to get the token from cookies
const getAuthToken = () => {
  const cookies = new Cookies()
  return cookies.get('bansot_sess') // Ensure this matches the token name you used
}

// Create Axios instance
export const axiosInstance = axios.create({
  baseURL: `${API_CONFIG.HOST}`,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

// Add a request interceptor to include the bearer token
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    //console.log('token', token)
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// GET GEO WORKSPACE AND LAYERS
export const GetIndividualLayer = (raster_id) =>{
  return useQuery({
    queryKey: ['fetch-workspace', raster_id],
    queryFn: async () =>{
      try{
        const res = axiosInstance.get(`farm/layer/${raster_id}/`)
        return res
      }catch(error){
        //console.log("thus err", error.message)
        return error.response
      }
    },
    retry: false,
    refetchOnWindowFocus: false,
  })
}


// GET GEO WORKSPACE AND LAYERS
export const GetWorkspace = (user_id) =>{
  return useQuery({
    queryKey: ['fetch-workspace', user_id],
    queryFn: async () =>{
      try{
        const res = axiosInstance.get(`gserver/workspace/${user_id}/`)
          return res
      }catch(error){
        console.log("thus err", error)
        return error
      }
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: false
  })
}

// GET REQUEST FOR ALL THE LIST OF USERS
export const FetchUsers = (pageNumber, limit) =>{
  return useQuery({
    queryKey: ['fetch-users',pageNumber],
    queryFn: async () =>{
      // const result = await fetch(getApiUrl(`getalluser/?page=${pageNumber}&limit=8`));
      // const data = await result.json()
      // return data

      const result = await axiosInstance.get(`getalluser/?page=${pageNumber}&limit=${limit}`)
      return result.data; // Axios response has the data in result.data
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true
  })
}

// GET REQUEST FOR ALL THE LIST OF Customers
export const FetchCustomers = (pageNumber, limit) =>{
  return useQuery({
    queryKey: ['fetch-customers',pageNumber],
    queryFn: async () =>{
      const result = await axiosInstance.get(`admin/getalluser?page=${pageNumber}&limit=${limit}`)
      return result.data; 
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true
  })
}

// GET REQUEST FOR ALL THE LIST OF USERS
export const FetchCompanyUsers = (company_id, pageNumber) =>{
  return useQuery({
    queryKey: ['company-users',company_id, pageNumber],
    queryFn: async () =>{
      const result = await axiosInstance.get(`company/user/list/${company_id}?page=${pageNumber}&limit=8`)
      return result.data; // Axios response has the data in result.data
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true
  })
}

// GET REQUEST FOR ALL THE LIST OF USERS
export const FetchCompany = () =>{
  return useQuery({
    queryKey: ['fetch-company'],
    queryFn: async () =>{
      // const result = await fetch(getApiUrl(`getalluser/?page=${pageNumber}&limit=8`));
      // const data = await result.json()
      // return data

      const result = await axiosInstance.get(`company/list`)
      return result.data; // Axios response has the data in result.data
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true
  })
}

// GET REQUEST FOR INDIVIDUAL USER DETAIL
export const FetchUser = (user_id) =>{
  return useQuery({
    queryKey: ['fetch-user', user_id],
    queryFn: async () =>{
      try{
        const res = axiosInstance.get(`getuser/${user_id}`)
          return res
      }catch(error){
        console.log("thus err", error)
        return error
      }
    },
    retry: false,
    refetchOnWindowFocus: false,
  })
}

// GET REQUEST FOR ALL THE LIST OF FARMS
export const FetchFarms = (company_id) =>{
  return useQuery({
    queryKey: ['fetch-farms', company_id],
    queryFn: async () =>{
      const result = await axiosInstance.get(`farm/list/${company_id}`)
      return result.data; // Axios response has the data in result.data
    },
    retry: 1,
    refetchOnWindowFocus: false,
    keepPreviousData: true
  })
}

// GET REQUEST FOR ALL THE LIST OF FARMS
export const FetchBlocks = (pageNumber, company_id) =>{
  return useQuery({
    queryKey: ['fetch-blocks', company_id],
    queryFn: async () =>{
      const result = await axiosInstance.get(`block/list/${company_id}?page=${pageNumber}&limit=8`)
      return result.data; // Axios response has the data in result.data
    },
    retry: 1,
    refetchOnWindowFocus: false,
    keepPreviousData: true
  })
}

// GET REQUEST FOR ALL THE LIST OF FARMS
export const FetchFarmsByUser = (user_id, pageNumber) =>{
  return useQuery({
    queryKey: ['fetch-farms-company', user_id, pageNumber],
    queryFn: async () =>{
      const result = await axiosInstance.get(`farm/lists/${user_id}?page=${pageNumber}&limit=8`)
      return result.data; // Axios response has the data in result.data
    },
    retry: 1,
    refetchOnWindowFocus: false,
    keepPreviousData: true
  })
}

// GET REQUEST FOR ALLT HE LAYERS OF THE FARM
export const FetchLayers = (block_id) =>{
  return useQuery({
    queryKey: ['fetch-layers', block_id],
    queryFn: async () =>{
      try{
        const res = axiosInstance.get(`block/layers/${block_id}/`)
        return res.then(res=>res.data)
      }catch(error){
        return error
      }
    },    
    retry: false,
    refetchOnWindowFocus: false,
  })
}

//  GET REQUEST FOR INDIVIDUAL FARM DETAIL
export const FetchBlockDetails = (block_id) =>{
  return useQuery({
    queryKey: ['fetch-block-details',block_id],
    queryFn: async () =>{
      try{
        const res = axiosInstance.get(`block/details/${block_id}/`)
        return res.then(res=>res.data)
      }catch(error){
        return error
      }
    },    
    retry: false,
    refetchOnWindowFocus: false,
  })
}

// DELETE REQUEST FOR RASTER
export const DeleteRequest = () => {
    
    return useMutation({
      mutationKey: ["deleteRaster"],
      mutationFn: async (deleteFormData) => {
        try{
          const res = axiosInstance.delete(`grid/delete/${deleteFormData.get('grid_id')}/${deleteFormData.get('block_name')}/${deleteFormData.get('farmName')}`, deleteFormData);
          return res
        }catch(error){
          return error
        }
      },
    });
  };

  // DELETE REQUEST FOR FARM
export const DeleteFarmRequest = () => {
    
    return useMutation({
      mutationKey: ["deleteFarm"],
      mutationFn: async (deleteFormData) => {
        try{
          const res = axiosInstance.delete(`farm/delete/${deleteFormData.get('farm_id')}`, deleteFormData);
          return res
        }catch(error){
          return error
        }
      },
    });
  };

// POST REQUEST FOR UPLOADING RATER
export const UploadRaster = () =>{
  return useMutation({
    mutationKey: ['upload-raster'],
    mutationFn: async (farm_data) =>{
      try{
        const res = axiosInstance.post(`raster/upload`, farm_data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          timeout: 12000000 //10mins pooling time
        })
        return res 
      } catch(error){
        console.log(error)
        return error
      }
    }
  })
}

// POST REQUEST FOR ADDING FARM
export const AddFarm = () =>{
  return useMutation({
    mutationKey: ['add-farm'],
    mutationFn: async (farm_data) =>{
      try{
        const res = axiosInstance.post(`farm/create`, farm_data)
        return res 
      } catch(error){
        console.log(error)
        return error
      }
    }
  })
}

// POST REQUEST FOR ADDING FARM
export const AddBlock = () =>{
  return useMutation({
    mutationKey: ['add-block'],
    mutationFn: async (block_data) =>{
      try{
        const res = axiosInstance.post(`block/create`, block_data)
        return res 
      } catch(error){
        console.log(error)
        return error
      }
    }
  })
}

// POST REQUEST FOR ADDING USER
export const AddUser = () =>{
  return useMutation({
    mutationKey: ['add-user'],
    mutationFn: async (user_data)=> {
      try{
        const res = axiosInstance.post(`signup/`, user_data);
        return res
      }catch(error){
        return error
      }
    }
  })
  
}


// PUT REQUEST FOR NUTRIENT THRESHOLD
// export const UpdateNutrientThreshold = () =>{
//   return useMutation({
//     mutationKey: ['update-nutrient-threshold'],
//     mutationFn: async(npkThreshold) =>{
//       try{
//         const res = axiosInstance.put(`update-nutrient-threshold/${npkThreshold.get('farm_id')}`, npkThreshold)
//         return res
//       }catch(error){
//         return error
//       }
//     }
//   })
// }

// // GET REQUEST FOR NUTRIENT THRESHOLD
// export const GetNutrientThreshold = (id) =>{
//    return useQuery({
//       queryKey: ['get-nitrogen-threshold', id],
//       queryFn: async () => {
//         return axiosInstance.get(`get-nitrogen-threshold/${id}`)
//           .then(res=>{return res.data})
//       },onError:(error)=>{
//         return error.response.data.message
//       },
//       retry: false,
//       refetchOnWindowFocus: false,
//    })
// }

//UPDATE REQUEST FOR FARM
export const UpdateBlockDetail = () =>{
  return useMutation({
    mutationKey: ['update-block-detail'],
    mutationFn: async(block) =>{
      try{
        const res = axiosInstance.put(`block/update/${block.get('block_id')}/`, block)
        return res
      }catch(error){
        return error
      }
    }
  })
}

//UPDATE REQUEST FOR FARM RASTER
export const UpdateFarmRaster = () =>{
  return useMutation({
    mutationKey: ['update-farm-raster'],
    mutationFn: async(farm_data)=>{
      return axiosInstance.put(`update-raster-data/${farm_data.get('id')}/`, farm_data)
        .then( res => {return res.data})
          .catch( (error) => { 
            if(error.response){
              return error.response.data
            }
          })
    }
  })
}

//GET REQUEST FOR RASTER UPLOADED
export const GetFarmRaster = (farm_id) =>{
  return useQuery({
    queryKey: ['get-raster',farm_id],
    queryFn: async() =>{
      return axiosInstance.get(`raster/details/${farm_id}/`)
        .then(res=>{return res.data})
        .catch((error)=>{
          if(error.response){
            return error.response.data
          }else if(error.request){
            return error.request
          }else{
          }console.log(error.config);
        })
    },
    retry: false,
    refetchOnWindowFocus: false
  })
}

// UPDATE USER ROLE
//UPDATE REQUEST FOR FARM RASTER
export const UpdateUserRole = () =>{
  return useMutation({
    mutationKey: ['update-user-role'],
    mutationFn: async(role_data)=>{
      return axiosInstance.put(`role/${role_data.get('id')}`, role_data)
        .then( res => {return res.data})
          .catch( (error) => { 
            if(error.response){
              return error.response.data
            }
          })
    }
  })
}

// GET REQUEST FOR THE CURRENT USER ROLE
export const GetRoles = (userId) =>{
  return useQuery({
    queryKey: ['fetch-roles', userId],
    queryFn: async() =>{
      return axiosInstance.get(`get-roles/${userId}`)
        .then(res=>{return res.data})
        .catch((error)=>{
          if(error.response){
            return error.response.data
          }else if(error.request){
            return error.request
          }else{
          }console.log(error.config);
        })
    },
    retry: false,
    refetchOnWindowFocus: false
  })
}


// const useApiQuery = (key, endpoint) => {
//   return useQuery({
//     queryKey: [key, endpoint],
//     queryFn: async () => {
//       try {
//         const res = await axios.get(getApiUrl(endpoint));
//         return res.data;
//       } catch (error) {
//         if (error.response) {
//           throw new Error(error.response.data);
//         } else if (error.request) {
//           throw new Error(error.request);
//         } else {
//           throw new Error("An error occurred");
//         }
//       }
//     },
//     retry: false,
//     refetchOnWindowFocus: false,
//   });
// };

// GET REQUEST FOR THE CATEGORY OF THE FARM
export const GetFarmCategory = (rid) =>{
  return useQuery({
    queryKey: ['fetch-farm-category', rid],
    queryFn: async() =>{
      return axiosInstance.get(`get-category/${rid}`)
        .then(res=>{return res.data})
        .catch((error)=>{
          if(error.response){
            return error.response.data
          }else if(error.request){
            return error.request
          }else{
          }console.log(error.config);
        })
    },
    retry: false,
    refetchOnWindowFocus: false
  })
}

// UPDATE NITROGEN BLOCK AND GRIDS
//UPDATE REQUEST FOR FARM RASTER
export const UpdateNitrogenThreshold = () =>{
  return useMutation({
    mutationKey: ['post-nitrogen'],
    mutationFn: async(nitrogen_data)=>{
      try{
        const res = axiosInstance.post(`nitrogen/create/${nitrogen_data.get('id')}`, nitrogen_data)
        return res
      }catch(error){
        return error
      }
    }
  })
}

// GET REQUEST FOR NITROGEN THRESHOLD ON GRIDS
export const GetNitrogenGrids = (id) =>{
  return useQuery({
     queryKey: ['get-nitrogen-grids', id],
     queryFn: async () => {
      //return axios.get(getApiUrl(`get-nitrogen/grids/${id}`))
      return axiosInstance.get(`nitrogen/grids/${id}`)
         .then(res=>{return res.data})

     },
      onError:(error)=>{
        return error.response.data.message
     },
     retry: false,
     refetchOnWindowFocus: false,
  })
}

// POST SENSOR DATA
export const AddSensor = () =>{
  return useMutation({
    mutationKey: ['add-sensor'],
    mutationFn: async (sensor_data)=> {
      try{
        const res = axiosInstance.post(`add-sensor/${sensor_data.get('id')}`, sensor_data);
        return res
      }catch(error){
        return error
      }
    }
  })
}
 

//GET REQUEST FOR Grids
export const GetGridsList = (block_id) =>{
  return useQuery({
    queryKey: ['get-grids',block_id],
    queryFn: async() =>{
      return axiosInstance.get(`grids/list/${block_id}`)
        .then(res=>{return res.data})
        .catch((error)=>{
          if(error.response){
            return error.response.data
          }else if(error.request){
            return error.request
          }else{
          }console.log(error.config);
        })
    },
    retry: false,
    refetchOnWindowFocus: false
  })
}


//GET REQUEST FOR Grids
export const GetNutrientGridsList = (block_id, query) =>{
  return useQuery({
    queryKey: ['get-grids-nutrient',block_id, query],
    queryFn: async() =>{
      return axiosInstance.get(`nutrient/list/${block_id}/${query}`)
        .then(res=>{return res.data})
        .catch((error)=>{
          if(error.response){
            return error.response.data
          }else if(error.request){
            return error.request
          }else{
          }console.log(error.config);
        })
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: false,
  })
}

//GET REQUEST FOR Grids
export const GetGridLayerDetails = (farm_id, date, gridName, shouldFetch) =>{
  return useQuery({
    queryKey: ['get-gridLayer-details',{farm_id, date, gridName}],
    queryFn: async() =>{
      return axiosInstance.get(`raster/layer/?farmId=${farm_id}&date=${date}&gridName=${gridName}`)
        .then(res=>{return res.data})
        .catch((error)=>{
          if(error.response){
            return error.response.data
          }else if(error.request){
            return error.request
          }else{
          }console.log(error.config);
        })
    },
    retry: false,
    refetchOnWindowFocus: false,
    enabled: shouldFetch && farm_id !== '' && date !=='',
  })
}

//GET REQUEST FOR Grids
export const GetApplicatorList = (cid) =>{
  return useQuery({
    queryKey: ['get-applicator-list', cid],
    queryFn: async() =>{
      return axiosInstance.get(`company/applicators/${cid}`)
        .then(res=>{return res.data})
        .catch((error)=>{
          if(error.response){
            return error.response.data
          }else if(error.request){
            return error.request
          }else{
          }console.log(error.config);
        })
    },
    retry: false,
    refetchOnWindowFocus: false,
  })
}

// POST REQUEST FOR ASSIGNING APPLICATOR
export const AssignApplicator = () =>{
  return useMutation({
    mutationKey: ['add-applicator'],
    mutationFn: async (app_data) =>{
      try{
        const res = axiosInstance.post(`grid/applicators/${app_data.get('gid')}/${app_data.get('apid')}`, app_data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        return res 
      } catch(error){
        console.log(error)
        return error
      }
    }
  })
}

//GET REQUEST FOR Grids
export const QueryReportListFarmBlockGrid = (cid) =>{
  return useQuery({
    queryKey: ['query-report-list-', cid],
    queryFn: async() =>{
      return axiosInstance.get(`report/query/${cid}`)
        .then(res=>{return res.data})
        .catch((error)=>{
          if(error.response){
            return error.response.data
          }else if(error.request){
            return error.request
          }else{
          }console.log(error.config);
        })
    },
    retry: false,
    refetchOnWindowFocus: false,
  })
}

// QUERY SEARCH REPORT V2
export const GenerateReport = (cid, farmName, blockName, gridName, month) =>{
  return useQuery({
    queryKey: ['generate-report', {cid, farmName, blockName, gridName, month}],
    queryFn: async() =>{
      return axiosInstance.get(`report/v2/farm/list/?companyId=${cid}&farmName=${farmName}&blockName=${blockName}&gridName=${gridName}&month=${month}`)
        .then(res=>{return res.data})
        .catch((error)=>{
          if(error.response){
            return error.response.data
          }else if(error.request){
            return error.request
          }else{
          }console.log(error.config);
        })
    },
    retry: false,
    refetchOnWindowFocus: false,
  })
}


export const useGetAssignedApplicator = (grid_id) =>{
  return useQuery({
    queryKey: ['get-assigned-applicator', grid_id],
    queryFn: async() =>{
      return axiosInstance.get(`grid/assigned/applicator/${grid_id}`)
        .then(res=>{return res.data})
        .catch((error)=>{
          if(error.response){
            return error.response.data
          }else if(error.request){
            return error.request
          }else{
          }console.log(error.config);
        })
    },
    retry: false,
    refetchOnWindowFocus: false,
  })
}