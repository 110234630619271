export const API_CONFIG = {
   // HOST: 'http://127.0.0.1',
    //HOST:'http://192.168.192.197',  // BACKEND IP ADDRESS
    //PORT: '8000',
    //HOST: 'http://192.168.1.172',
    //HOST: 'http://192.168.198.196', //local ip
    //HOST: 'http://192.168.1.13',
    //HOST:'https://backend.banatapp.cloud',
    // PORT: '8000'

    HOST:'https://backend.banatapp.cloud',

    //HOST:'http://192.168.192.197:8000' // backend ip locale
    //HOST: 'http://192.168.198.196:8005', //local ip
}

export const GEO_CONFIG = {
    //HOST: 'http://localhost',
    //HOST: 'http://192.168.1.172',
    //HOST: 'http://192.168.192.205', // GEOSERVER IP ADDRESS
    //HOST: 'http://192.168.1.7',
    // PORT: '8080',

    HOST:'https://geoserver.banatapp.cloud'
   // HOST: 'http://192.168.192.205:8080', // GEOSERVER IP ADDRESS
   // HOST:'http://localhost:8080' // local ip locale
} 