import React, { createContext, useState } from 'react';
import jwt_decode from 'jwt-decode'
import Cookies from 'universal-cookie';


export const ToggleSidebarContext = createContext(false);
export const loginContext = createContext(null);
export const UserContext = createContext();
export const TokenAuth = createContext();
export const CompanyContext = createContext();
export const AccountDropMenuContext = createContext();
export const SidebarLinkActivate = createContext();


export const ContextProvider = ({ children }) => {
  const cookies = new Cookies()
  const [toggleSidebarValue, isToggleSidebarValue] = useState(false);
  const [loggedIn, isLoggedIn] = useState(false);
  const [userData, isUserData] = useState(
    cookies.get('bansot_sess') ? jwt_decode(cookies.get('bansot_sess')) : null
  );
  const [tokenAuth, setTokenAuth] = useState(
    cookies.get('bansot_sess') ? cookies.get('bansot_sess') : null
  );
  const [companyID, isCompanyID] = useState();
  const [accountDropValue, isAccountDropValue] = useState(false);
  const [activeLink, isActiveLink] = useState('') // set dafualt /app/dashboard

  return (
    <ToggleSidebarContext.Provider value={{ toggleSidebarValue, isToggleSidebarValue }}>
        <SidebarLinkActivate.Provider value={{ activeLink, isActiveLink}}>
            <CompanyContext.Provider value={{ companyID, isCompanyID }}>
                <AccountDropMenuContext.Provider value={{ accountDropValue, isAccountDropValue }}>
                    <loginContext.Provider value={{ loggedIn, isLoggedIn }}>
                        <UserContext.Provider value={{ userData, isUserData }}>
                            <TokenAuth.Provider value={{ tokenAuth, setTokenAuth }}>
                                {children}
                            </TokenAuth.Provider>
                        </UserContext.Provider>
                    </loginContext.Provider>
                </AccountDropMenuContext.Provider>
            </CompanyContext.Provider>
        </SidebarLinkActivate.Provider>
    </ToggleSidebarContext.Provider>
  );
};
