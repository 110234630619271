import { create } from "zustand";

export const useGridSearchStore = create((set)=>({
    grid: '',
    setGridSearch: (data) => {
        set({ grid: data})
    }
}))

export const useGridResultStore = create((set)=>({
    gridResult: '',
    setGridResult: (data) => {
        set({ gridResult: data})
    }
}))