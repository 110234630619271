import React, { useState } from 'react'
import './css/NutrientThreshold.css'
import { GetNitrogenGrids, UpdateNitrogenThreshold } from '../api/ApiMethods'
import { useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { motion } from 'framer-motion'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { ClipLoader } from 'react-spinners';
import { IoAddOutline } from "react-icons/io5"
import { IconButton, styled } from '@mui/material'
import { MdEdit } from 'react-icons/md'
import NutrientItemUpdate from './Modal/NutrientItemUpdate' 
import { CiImport } from 'react-icons/ci'
import * as XLSX from 'xlsx';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function NutrientThreshold({ modalValue, farmID, rasterId, gridId }) {


    const [grid, setGrid] = useState({
        ndvi: '',
        vari: '',
        gli: '',
        exg: '',
        nitrogen: '',
        longitude: '',
        latitude: '',
    })

    const [gridError, setGridError] = useState({
        ndvi: false,
        vari: false,
        gli: false,
        exg: false,
        nitrogen: false,
        longitude: false,
        latitude: false,
    })

    const [gridData, setGridData] = useState([])
    const [nutrientItemModal, setNutrientItemModal] = useState(false)
    const [nutrientItem, setNutrientItem] = useState()

    const { data: getNitrogenGrids } = GetNitrogenGrids(gridId)

    const queryClient = useQueryClient()
    
    const { mutate: nitrogenUpdate, isLoading } = UpdateNitrogenThreshold()

    const handleNitrogenChange = (e) => {
        setGrid({
            ...grid,
            [e.target.name]: e.target.value,
        })

        setGridError((prevGridError) => ({
            ...prevGridError,
            [e.target.name]: ""
          }));
    }

    const addGridEntry = () => {
        
        
        // Check if any of the fields are empty
        if (!grid.ndvi || !grid.vari || !grid.gli || !grid.exg 
                || !grid.nitrogen || !grid.longitude || !grid.latitude) {
            // Display error prompt
            toast.error('Please fill in all fields.', {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                pauseOnFocusLoss: false,
                theme: "light",
            });

            setGridError({
                ndvi: true,
                vari: true,
                gli: true,
                exg: true,
                nitrogen: true,
                longitude: true,
                latitude: true,
            })

            return; // Exit the function if any field is empty
        }

        const gridEntry = {
            ndvi: grid.ndvi,
            vari: grid.vari,
            gli: grid.gli,
            exg: grid.exg,
            nitrogen: grid.nitrogen,
            longitude: grid.longitude,
            latitude: grid.latitude
        };

        setGridData([...gridData, gridEntry])

        setGrid({
            ndvi: '',
            vari: '',
            gli: '',
            exg: '',
            nitrogen: '',
            longitude: '',
            latitude: ''
        })
    }

    const handleGridsSaveNitrogen = async() => {
        console.log('grids')
        console.log(gridData)

        const formData = new FormData()
        //console.log("BEFORE PUT ", gridData)
        const gridLevelsJSON = JSON.stringify(gridData) // CONVERT THE ARRAY TO TO JSON STRINGY
        
        formData.append('grid_levels', gridLevelsJSON)
        formData.append('id', rasterId)

        nitrogenUpdate(formData, {
            onSuccess: (data) =>{
                queryClient.invalidateQueries('get-nitrogen-grids')

                toast.success('Successfully set the threshold.', {
                    position: "top-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    pauseOnFocusLoss: false,
                    theme: "light",
                });

                setGridData([])
            },
                onError: (error) =>{
                    toast.error('Invalid threshold.', {
                        position: "top-right",
                        autoClose: 2500,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        pauseOnFocusLoss: false,
                        theme: "light",
                    });
                }
        })
    }

    const handleNitrogenUpdate = (grid) => {
        setNutrientItemModal(true)
        setNutrientItem(grid)
    }

    const handleUploadFileDatasets = (e) => {
        const file = e.target.files[0]
        console.log(file)

        if (file) {
            const reader = new FileReader();
      
            reader.onload = (e) => {
            const binaryStr = e.target.result;
            const workbook = XLSX.read(binaryStr, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet);
            //setData(jsonData);
            console.log(jsonData)

            const formData = new FormData()
            //console.log("BEFORE PUT ", gridData)
            const gridLevelsJSON = JSON.stringify(jsonData) // CONVERT THE ARRAY TO TO JSON STRINGY
                
            formData.append('grid_levels', gridLevelsJSON)
            formData.append('id', rasterId)

            nitrogenUpdate(formData, {
                onSuccess: (data) =>{
                    queryClient.invalidateQueries('get-nitrogen-grids')

                    toast.success('Successfully set the threshold.', {
                        position: "top-right",
                        autoClose: 2500,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        pauseOnFocusLoss: false,
                        theme: "light",
                    });

                    setGridData([])
                },
                onError: (error) =>{
                    toast.error('Invalid threshold.', {
                        position: "top-right",
                        autoClose: 2500,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        pauseOnFocusLoss: false,
                        theme: "light",
                    });
                }
            })
        };
      
            reader.readAsBinaryString(file);
          }

    }

  return (
    <div className='nutrient__container'>
        <motion.div 
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3}}
            className='nutrient__content'>

            <div className='nutrient__header'>
                <div className='nutrient__header__title'>
                    <h3>Store New Dataset</h3>
                    <p><i>Input the Lab test result.(% of Nitrogen)</i></p>
                </div>
                <div className='farmview__nutrient__header'>
                    <Button 
                        component="label"
                        style={{
                            backgroundColor:  'var(--primary__color)',
                            textTransform: 'capitalize'
                        }}
                        tabIndex={-1}
                        startIcon={<CiImport/>}
                        variant="contained"
                        disabled={!!getNitrogenGrids?.data}
                    >
                        Import
                        <VisuallyHiddenInput 
                            type="file" 
                            onChange={handleUploadFileDatasets}
                        />
                    </Button>

                    {/* <Button 
                        style={{
                            backgroundColor:  'var(--primary__color)',
                            textTransform: 'capitalize'
                        }}
                        startIcon={<IoAddOutline/>}
                        variant="contained"
                        onClick={addGridEntry}
                    >
                        Add Data
                    </Button> */}
                </div>
            </div>

            <div className='grid__display'>
                {
                    getNitrogenGrids && getNitrogenGrids?.data && getNitrogenGrids?.data.length > 0 ? (
                    <table>
                        <thead>
                            <tr>
                                <th>NDVI</th>
                                <th>VARI</th>
                                <th>GLI</th>
                                <th>EXG</th>
                                <th>Latitude</th>
                                <th>Longitude</th>
                                <th>% of (N)</th>
                                <th>Temperature °C</th>
                                <th>Humidity</th>
                                <th>Rainfall(mm)</th>
                                <th>Moisture %</th>
                                <th>Temp(Moisture) °C</th>
                                <th>pH</th>
                                <th>Temp(pH) °C</th>
                                <th>Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                getNitrogenGrids?.data.map((grid, index) => (
                                    <tr key={index}>
                                        <td>{grid.ndvi}</td>
                                        <td>{grid.vari}</td>
                                        <td>{grid.gli}</td>
                                        <td>{grid.exg}</td>
                                        <td>{grid.latitude}</td>
                                        <td>{grid.longitude}</td>
                                        <td>{grid.nitrogen.toFixed(3)}</td>
                                        <td>{grid.weather.temperature  ?? 'NA'}</td>
                                        <td>{grid.weather.humidity  ?? 'NA'}</td>
                                        <td>{grid.weather.rain  ?? 'NA'}</td>
                                        <td>{grid.sensor.moisture  ?? 'NA'}</td>
                                        <td>{grid.sensor.temperature_moisture  ?? 'NA'}</td>
                                        <td>{grid.sensor.pH  ?? 'NA'}</td>
                                        <td>{grid.sensor.temperature_pH  ?? 'NA'}</td>
                                        <td>
                                            <IconButton
                                                style={{
                                                    backgroundColor:  'var(--bkg__edit)',
                                                    color: 'white',
                                                    zIndex: '0'
                                                }}
                                                size='small'
                                                onClick={ ()=>handleNitrogenUpdate(grid)}
                                                            
                                            >
                                                <MdEdit/>
                                            </IconButton>
                                        </td>
                                    </tr>
                                        ))
                                    }
                            </tbody>
                        </table>

                ) : (
                    <div className='no__datasets__available'>
                        <label className='no__dataset__label'>No available datasets.</label>
                    </div>
                    
                )}
            </div>
            
            {/* {
                !getNitrogenGrids?.data ?            
                    <GridModalInputField 
                        handleNitrogenChange = { handleNitrogenChange }
                        gridData = { gridData }
                        grid = { grid }
                        gridError = { gridError }
                    />
                :
                ''
            } */}
            
            <div className='nutrient__actions'>
                <Button
                    style={{
                        backgroundColor:  'var(--no_background)',
                        color: 'var(--fontColor__secondary)',
                        textTransform: 'capitalize',
                        boxShadow: 'none',
                    }}
                    variant="contained"
                    onClick={()=>modalValue(false)}
                    >
                    <span>Close</span>
                </Button>
                
                {/* <Button 
                    style={{
                        backgroundColor:  'var(--primary__color)',
                        textTransform: 'capitalize'
                    }}
                    disabled={isLoading ? true : false }
                    loading={isLoading.toString()}
                    loadingposition="start" 
                    startIcon={ <ClipLoader color="white" loading={ isLoading } size={15}/>}
                    variant="contained"
                    onClick={handleGridsSaveNitrogen}
                    >
                    <span>Save</span>
                </Button> */}

            </div>
            
            {
                nutrientItemModal ?
                <NutrientItemUpdate 
                    setNutrientItemModal = { setNutrientItemModal }
                    nutrientItem = { nutrientItem }
                />
                :
                ''
            }
        </motion.div>
    </div>
  )
}


const GridModalInputField = ({ handleNitrogenChange, gridData, grid, gridError }) => {
    const ButtonProperty = {
        size: 'small',
        width: '100px',
    }
    
    return (
        <div className='grid__input__container'>
            <div className='grid__input__field'>
                <div className='vegetation__container'>
                    <TextField 
                        error={gridError.ndvi ? true : false}
                        id="standard-numbersic" 
                        label="NDVI" 
                        name='ndvi'
                        variant="outlined"
                        sx={{width: ButtonProperty.width}}
                        size={ButtonProperty.size}
                        inputProps={{type: 'number'}}
                        onChange={handleNitrogenChange}
                        value={grid.ndvi}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField 
                        error={gridError.vari ? true : false}
                        id="standard-numbersic" 
                        label="VARI" 
                        name='vari'
                        variant="outlined"
                        sx={{width: ButtonProperty.width}}
                        size={ButtonProperty.size}
                        inputProps={{type: 'number'}}
                        onChange={handleNitrogenChange}
                        value={grid.vari}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField 
                        error={gridError.gli ? true : false}
                        id="standard-numbersic" 
                        label="GLI" 
                        name='gli'
                        variant="outlined"
                        sx={{width: ButtonProperty.width}}
                        size={ButtonProperty.size}
                        inputProps={{type: 'number'}}
                        onChange={handleNitrogenChange}
                        value={grid.gli}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField 
                        error={gridError.exg ? true : false}
                        id="standard-numbersic" 
                        label="EXG" 
                        name='exg'
                        variant="outlined"
                        sx={{width: ButtonProperty.width}}
                        size={ButtonProperty.size}
                        inputProps={{type: 'number'}}
                        onChange={handleNitrogenChange}
                        value={grid.exg}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>

                <div className='additional__grid__container'>        
                    <TextField 
                        error={gridError.nitrogen ? true : false}
                        id="outlined-basic" 
                        label="Nitrogen" 
                        variant="outlined"
                        name='nitrogen'
                        sx={{width: ButtonProperty.width}}
                        size={ButtonProperty.size}
                        inputProps={{type: 'number'}}
                        onChange={handleNitrogenChange}
                        value={grid.nitrogen}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField 
                        error={gridError.longitude ? true : false}
                        id="outlined-basic" 
                        label="Longitude" 
                        variant="outlined"
                        name='longitude'
                        sx={{width: ButtonProperty.width}}
                        size={ButtonProperty.size}
                        inputProps={{type: 'number'}}
                        onChange={handleNitrogenChange}
                        value={grid.longitude}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField 
                        error={gridError.latitude ? true : false}
                        id="outlined-basic" 
                        label="Latitude" 
                        variant="outlined"
                        name='latitude'
                        sx={{width: ButtonProperty.width}}
                        size={ButtonProperty.size}
                        inputProps={{type: 'number'}}
                        onChange={handleNitrogenChange}
                        value={grid.latitude}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                
            </div>
            
            {gridData?.length === 0 ?
                ''
            :
                <div className='grid__display'>
                    <table>
                        <thead>
                            <tr>
                                <th>NDVI</th>
                                <th>VARI</th>
                                <th>GLI</th>
                                <th>EXG</th>
                                <th>Nitrogen</th>
                                <th>Longitude</th>
                                <th>Latitude</th>
                            </tr>
                        </thead>
                        <tbody>
                            {gridData?.map((entry, index) => (
                                <tr key={index}>
                                    <td>{entry.ndvi}</td>
                                    <td>{entry.vari}</td>
                                    <td>{entry.gli}</td>
                                    <td>{entry.exg}</td>
                                    <td>{entry.nitrogen}</td>
                                    <td>{entry.longitude}</td>
                                    <td>{entry.latitude}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            }
            
        </div>
    )
}
