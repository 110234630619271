import React, { useMemo } from 'react'
import './css/WeatherLineChart.css'
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Legend,
    Tooltip,
    Title,
} from 'chart.js'
import { Line } from 'react-chartjs-2';

ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Legend,
    Tooltip,
    Title,
)

export default function WeatherLineChart({ getNutrientGridsList, tabOpen }) {

    const weatherData = useMemo(() => {
        return getNutrientGridsList
          ?.filter(grid => grid.weather) // Filter out entries without weather data
          .map(grid => ({
            date: grid.upload_raster_date,
            temperature: grid.weather.temperature,
            humidity: grid.weather.humidity,
            rain: grid.weather.rain
          }))
          .sort((a, b) => new Date(a.date) - new Date(b.date)); // Sort by date
      }, [getNutrientGridsList]);

      const chartData = {
        labels: weatherData?.map(data => data.date),
        datasets: [
          {
            label: 'Temperature (°C)',
            data: weatherData?.map(data => data.temperature),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            yAxisID: 'y',
          },
          {
            label: 'Humidity (%)',
            data: weatherData?.map(data => data.humidity),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            yAxisID: 'y',
          },
          {
            label: 'Rain (mm)',
            data: weatherData?.map(data => data.rain),
            borderColor: 'rgb(75, 192, 192)',
            backgroundColor: 'rgba(75, 192, 192, 0.5)',
            yAxisID: 'y1',
          },
        ],
      };
    
      const options = {
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        stacked: false,
        plugins: {
          title: {
            display: true,
            text: 'Weather',
          },
        },
        scales: {
          y: {
            type: 'linear',
            display: true,
            position: 'left',
            title: {
              display: true,
              text: 'Temperature (°C) / Humidity (%)'
            }
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            title: {
              display: true,
              text: 'Rain (mm)'
            },
            grid: {
              drawOnChartArea: false,
            },
          },
        },
        maintainAspectRation: false
      };

  return (
    <div 
      className='weather__lineChart__container'
      hidden ={tabOpen === 1 ? false : true}
      >
        {getNutrientGridsList &&
          (
            <Line options={options} data={chartData} />
          )
        }
    </div>
  )
}
