import { Button } from "@mui/material"


export const ButtonProperty = {
    size: 'small',
    width: '85px',
    widthLonLat: '120px',
}

export const NormalButton = ({name, onClick, isActive}) => {
    return(
        <Button
            variant="outlined"
            size="small"
            disableRipple
            style={{
                textTransform: 'capitalize',
                backgroundColor: isActive ? 'var(--active-bkg__primary)' : 'var(--bkg__primary)',
                color: isActive ? 'white' : 'black',
                borderColor: isActive ? 'white' : 'black',
            }}
            onClick={onClick}
        >
            {name}
        </Button>
    )
}

export const CloseButton = ({name, onClick}) => {
    return(
        <Button
            disableRipple
            style={{
                textTransform: 'capitalize',
                color: 'black',
                borderColor: 'black',
                background: 'transparent'
            }}
            onClick={onClick}
        >
            {name}
        </Button>
    )
}

export const SubmitButton = ({name, onClick}) => {
    return(
        <Button
            variant="contained"
            disableRipple
            style={{
                textTransform: 'capitalize',
                backgroundColor: 'var(--primary__color)',
            }}
            onClick={onClick}
        >
            {name}
        </Button>
    )
}
