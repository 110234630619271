import React from 'react'
import './css/AreaChartPercentage.css'
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { color } from '../../config/mapColor';
import { RiCheckboxBlankFill } from 'react-icons/ri';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export default function AreaChartPercentage({ areaPercentage, areaSize }) {
    //console.log('areaPercentage', areaPercentage)
    //console.log(areaSize)
    const haSizes = Object.values(areaSize).map(value => value / 10000);

    const orderedLabels = ['LOW', 'NORMAL', 'HIGH'];
    const colorMap = {
        'LOW': color.LOW,      // Light Orange
        'NORMAL': color.NORMAL,   // Light Yellow
        'HIGH': color.HIGH      // Light Green
    };

    // Map the data to the ordered labels, ensuring alignment
    const dividedValues = orderedLabels.map(label => areaPercentage[label] ? areaPercentage[label] : 0);
    //console.log(dividedValues)

    const backgroundColors = orderedLabels.map(label => colorMap[label]);
    const borderColors = backgroundColors; // Same as backgroundColors

    const data = {
        labels: orderedLabels,
        datasets: [
          {
            label: 'Area Values',
            data: dividedValues,
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            borderWidth: 1,
          },
        ],
      };
    
      const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
            display: false,
          },
          title: {
            display: true,
            text: 'Area Percentage Distribution',
          },
          datalabels: {
            color: 'white',
            formatter: (value) => `${value.toFixed(2)} %`, // Format the label to two decimal places
            backgroundColor: 'rgba(128, 128, 128, 0.5)',
            //padding: 4, // Optional: to add some padding between the text and border
            font: {
              weight: 'bold', // Optional: make the text bold
              size: '11px'
            },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      };
    
    
  return (
    <div className='area__percentage__container'>
      
        <div className='area__percentage__chart'>
          <Bar data={data} options={options} />
        </div>
       <div className='area__display__chart'>
          <label>
            <RiCheckboxBlankFill color={color.LOW}/> 
            {`${haSizes[0]?.toFixed(3)} ha`  }
          </label>
          <label>
            <RiCheckboxBlankFill color={color.NORMAL}/> 
            {`${haSizes[2]?.toFixed(3)} ha`}
          </label>
          <label>
            <RiCheckboxBlankFill color={color.HIGH}/> 
            {`${haSizes[1]?.toFixed(3)} ha`}
          </label>
       </div>
    </div>
  )
}
