import { useContext, useState } from 'react'
import './css/FarmTable.css'
import { useNavigate } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'; 
//import FarmEditModal from './FarmEditModal'
//import { CompanyContext } from '../Context/ContextProvider'
//import DeleteModalPrompt from './subfeature/DeleteModalPrompt'
import { FetchBlocks, FetchFarms } from '../api/ApiMethods'
import { useMediaQuery } from 'react-responsive';

import { MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
import { FaRegMap } from "react-icons/fa";
//import { MdOutlineEdit } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import { IconButton } from '@mui/material';
import { FaEye } from "react-icons/fa";
import FarmDeleteModal from './Modal/FarmDeleteModal';
import { IoBarChartSharp } from 'react-icons/io5';
import { UserContext } from '../Context/ContextProvider';

export default function FarmTable({search, pageNumber, setPageNumber, fetchBlocks, isLoading}) {
  const navigate = useNavigate()

  const pageConfig = {
    LIMIT: 8
  }

  const { userData } = useContext(UserContext)

  //const [getUser, setUser] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [farmUserId, setFarmUserId ] = useState(null)
  
  //const { isCompanyID } = useContext(CompanyContext)

  const isMobile = useMediaQuery({ maxWidth: 768 });
  //const { data: fetchUser } = FetchUsers()
  //console.log("GETUSERS: ", fetchUser )

  // Implement search functionality
  const filteredFarms = fetchBlocks
    ? fetchBlocks.filter((item) => {
        return search.toLowerCase() === ''
          ? item
          : item.blockName.toLowerCase().includes(search.toLowerCase());
      })
    : [];
  //SORT THE FARM BY ID FIRST BEFORE MAPPING
  const sortedFarms = filteredFarms ? filteredFarms.sort((a, b) => b.id - a.id) : [];

  // CREATE A BASELINE FOR PAGINATION
  const offset = (pageNumber - 1) * pageConfig.LIMIT;
  const displayedFarms = sortedFarms ? sortedFarms.slice(offset, offset + pageConfig.LIMIT) : [];

  // Disable next and previous buttons when searching
  const isSearching = search !== '';
  const disabledNext = isSearching || pageNumber * pageConfig.LIMIT >= fetchBlocks?.length;
  const disabledPrev = pageNumber === 1;

  const handleEditNavigate = (bid, companyName, uid, fid) => {
    navigate(`edit?uid=${uid}&fid=${fid}&bid=${bid}&company=${companyName}`)
  }

  const handleMapNavigate = (fid, bid, companyName, blockName) => {
    navigate(`q?fid=${fid}&bid=${bid}&company=${companyName}&block-name=${blockName}`)
  }

  const handleNavigateChart = (bid, companyName, blockName) => {
    navigate(`chart?bid=${bid}&company=${companyName}&blockName=${blockName}`)
  }
  
  return (
    <>
    {isLoading ? 
      <div className='farm__loading'>
        <BeatLoader color='green' loading={true}/>
      </div>
      :
      <div className='farm__table__container'>
          
          <table>
                <thead>
                  <tr>
                    <th style={{width: isMobile ? '30px' : "100px"}}>Company</th>
                    <th style={{width: isMobile ? '20px' : "100px"}}>Block</th>
                    <th style={{width: isMobile ? '20px' : "100px"}}>Location</th>
                    {
                      !isMobile &&(
                        <>
                          <th style={{width: "30px"}}>Week</th>
                        </>
                      )
                    }
                    <th style={{width: isMobile ? '60px' : "200px"}}>Action</th>
                  </tr>
                </thead>
                <tbody>
                {displayedFarms?.map(item => {
                  // Find the corresponding user object based on user_id
                  //const user = getUser.find(user => user.id === item.user_id);
                  //const companyName = replaceCompanyIdWithCompany(item.user_id);
                  
                  const handleDeleteClick = (id) =>{
                     setFarmUserId((prevFarmUserId) => {
                      return id;
                    })
                    console.log(id) 
                    setDeleteModal(true)
                  }

                  return (
                    <tr key={item.id}>
                      <td>{item.farm.company.company_name}</td>
                      <td>{item.farm.farmName} - {item.blockName}</td>
                      <td>{item.location}</td>
                      {!isMobile && (
                        <>
                          <td>{item.block_created}</td>
                        </>
                      )}
                      <td>
                        <div className='tb__actions'>
                          <IconButton
                            style={{
                              backgroundColor:  'var(--primary__color)',
                              width: '40px',
                            }}
                            size='small'
                            variant="contained"
                            onClick={()=>handleMapNavigate(item.farm.id, item.id, item.farm.company.company_name, item.blockName)}
                            >
                            
                            <FaRegMap/>
                          </IconButton>

                          {/* <IconButton 
                            aria-label="chart"
                            style={{
                              backgroundColor:  'var(--orange)',
                              width: '40px'
                            }}
                            size='small'
                            onClick={()=>handleNavigateChart(item.id, item.farm.company.company_name, item.blockName)}>
                            <IoBarChartSharp/>
                          </IconButton> */}

                          <IconButton
                            style={{
                              backgroundColor:  'var(--bkg__edit)',
                              width: '40px',
                            }}
                            size='small'
                            onClick={()=>handleEditNavigate(item.id, item.farm.company.company_name, item.farm.user, item.farm.id)}
                            >
                            <FaEye/>
                          </IconButton>

                          { userData?.role === 'Admin' || userData?.role === 'Manager' || userData?.role === 'Customer' ?
                          <IconButton 
                            aria-label="delete"
                            style={{
                              backgroundColor:  'var(--red)',
                              width: '40px'
                            }}
                            size='small'
                            onClick={()=>handleDeleteClick(item.id, item.farm.company.company_name)}>
                            <AiOutlineDelete/>
                          </IconButton>
                          :
                          ""}
                        </div>
                      </td>
                    </tr>
                  );
                })}
                </tbody>
          </table>
          {!displayedFarms?.length &&
            (<p className='records__warning'>No records found.</p>)
          }
      </div>
      
    }

    {
      !isSearching && (
        <div className='pagenated__buttons'>
          <button onClick={()=> setPageNumber(page=> page -1 )}
            disabled={disabledPrev}><GrFormPrevious size={'1.5em'} /></button>
          <button onClick={()=> setPageNumber(page=> page + 1 )}
            disabled={disabledNext}><MdNavigateNext size={'1.5em'} /></button>
        </div>
      )
    }
    
    {deleteModal ? 
      <FarmDeleteModal 
        setDeleteModal = { setDeleteModal }
        farmUserId = { farmUserId }
        />
      :
      ''
    }
    </>
  )
}
