import React from 'react'
import './css/About.css'
//import edward from '..//assets/edward.jpg'
import logo from '..//assets/logo.png'

export default function About() {
  return (
    <>
    <div className='about-container'>
        <div className='about-title'>
            <h1>About</h1>
        </div>
        <div className='about-content'>
          <p>Lorem Ipsum is simply dummy text of the printing and 
            typesetting industry. Lorem Ipsum has been the industry's 
            standard dummy text ever since the 1500s, when an unknown printer
            took a galley of type and scrambled it to make a type specimen book. 
            It has survived not only five centuries, but also the leap into 
            electronic typesetting, remaining essentially unchanged. It was 
            popularised in the 1960s with the release of Letraset sheets 
            containing Lorem Ipsum passages, and more recently with desktop 
            publishing software like Aldus PageMaker including versions of Lorem
            Ipsum.</p>
            <p>Lorem Ipsum is simply dummy text of the printing and 
            typesetting industry. Lorem Ipsum has been the industry's 
            standard dummy text ever since the 1500s, when an unknown printer
            took a galley of type and scrambled it to make a type specimen book. 
            It has survived not only five centuries, but also the leap into 
            electronic typesetting, remaining essentially unchanged. It was 
            popularised in the 1960s with the release of Letraset sheets 
            containing Lorem Ipsum passages, and more recently with desktop 
            publishing software like Aldus PageMaker including versions of Lorem
            Ipsum.</p>
        </div>
    </div>

    <div className='team-container'>
        <h1>Meet the Team</h1>
        <div className='card-team-container'>
            <div className='card-holder'>
              <div className='card'>
                  <img src={logo} alt="images" />
                  <div className='card-info'>
                    <h3>James Lasquites</h3>
                    <h4><i>Project Leader</i></h4>
                  </div>
              </div>
              <div className='card'>
                  <img src={logo} alt="images" />
                  <div className='card-info'>
                    <h3>Artermio Hagunos</h3>
                    <h4><i>Research Analyst</i></h4>
                  </div>
              </div>
              <div className='card'>
                  <img src={logo} alt="images" />
                  <div className='card-info'>
                    <h3>Eduardo Bautista</h3>
                    <h4><i>Research Analyst</i></h4>
                  </div>
              </div>
              <div className='card'>
                  <img src={logo} alt="images" />
                  <div className='card-info'>
                    <h3>Ralp Artizo</h3>
                    <h4><i>Research Assistant</i></h4>
                  </div>
              </div>
              <div className='card'>
                  <img src={logo} alt="images" />
                  <div className='card-info'>
                    <h3>Rey de Leon</h3>
                    <h4><i>Project Staff</i></h4>
                  </div>
              </div>
              <div className='card'>
                  <img src={logo} alt="images" />
                  <div className='card-info'>
                    <h3>Gilbert Importante</h3>
                    <h4><i>Project Staff</i></h4>
                  </div>
              </div>
              <div className='card'>
                  <img src={logo} alt="images" />
                  <div className='card-info'>
                    <h3>Leo Ong</h3>
                    <h4><i>Project Staff</i></h4>
                  </div>
              </div>
            </div>
        </div>
  </div> 
    </>
  )
}
