import { axiosInstance } from "./ApiMethods"
import { useMutation, useQuery } from "@tanstack/react-query";


// POST WEATHER DATA
export const AddWeather = () =>{
    return useMutation({
      mutationKey: ['add-weather'],
      mutationFn: async (weather_data)=> {
        try{
          const res = axiosInstance.post(`weather/${weather_data.get('farm_id')}`, weather_data, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          return res
        }catch(error){
          return error
        }
      }
    })
}

// GET Weather Data
export const GetWeatherList = (fid) =>{
    return useQuery({
      queryKey: ['fetch-weather', fid],
      queryFn: async () =>{
        try{
          const res = axiosInstance.get(`weather/list/${fid}`)
          return res
        }catch(error){
          //console.log("thus err", error.message)
          return error.response
        }
      },
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!fid
    })
  }
