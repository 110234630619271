import { color } from "../config/mapColor"

export const changeFileName = (file, company, file_category) => {
    const fileExtension = file.name.substring(file.name.lastIndexOf('.'))
    const newFileName = `${company}_${file_category}${fileExtension}`
    const newFile = new File([file], newFileName, { type: file.type })
  
    return {
      file: newFile,
      extension: fileExtension
    }
}  

// Utility function to convert a string to title case
export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
  });
};

export const fetchAndProcessUsers = ({ users, search, userData }) => {
  let filteredUsers = []

  // Implement search functionality
  if(userData?.role === 'Customer'){
    filteredUsers = users
      ? users.filter((item) => {
          // Filter based on search term for Admin
          return (
            search.toLowerCase() === '' ||
            item.username.toLowerCase().includes(search.toLowerCase())
          );
        })
      : [];

  }else if(userData?.role === 'Manager'){
    filteredUsers = users
      ? users.filter((item) => {
          // Filter based on search term and exclude 'Manager' role users for Manager
          return (
            (search.toLowerCase() === '' ||
            item.username.toLowerCase().includes(search.toLowerCase())) &&
            item.role !== 'Manager' // Exclude users with role 'Manager'
          );
        })
      : [];
  }
  //console.log(filteredUsers)

  // Sort the users by ID in descending order
  const sortedUsers = filteredUsers.sort((a, b) => b.id - a.id);

  // // Calculate the offset for pagination
  // const offset = (pageNumber - 1) * itemsPerPage;
  
  // // Slice the sorted array to get the users for the current page
  // const displayUsers = sortedUsers.slice(offset, offset + itemsPerPage);

  return sortedUsers;
};


export const formatDate = (dateString) => {
  if(dateString === null)
    return ''
  if (dateString === undefined)
    return ''
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, options);
}

export const getStatusColor = (status) => {
  switch (status.toLowerCase()) {
    case 'pending':
      return '#FFF9C4'; // Light yellow
    case 'completed':
      return '#C8E6C9'; // Light green
    default:
      return 'transparent';
  }
}

export const formatDateTime = (timestamp) => {
  const date = new Date(timestamp);

  // Format date as 'YYYY-MM-DD'
  const formattedDate = date.toLocaleDateString('en-GB', {
    month: 'long',
    year: 'numeric',
    day: 'numeric',
  });

  // Format time as 'HH:MM:SS'
  const formattedTime = date.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

  return `${formattedDate} ${formattedTime}`;
}

export const getCategoryColor = (category) => {
  switch (category.toLowerCase()) {
    case 'low':
      return color.LOW;
    case 'normal':
      return color.NORMAL;
    case 'high':
      return color.HIGH;
    default:
      return 'inherit';
  }
};