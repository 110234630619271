import React, { useContext, useState } from 'react'
import './css/RasterListTable.css'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { FaRegMap, FaUserAlt } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import Button from '@mui/material/Button'
import DeleteModalPrompt from '../subfeature/DeleteModalPrompt';
import { IoAddOutline } from "react-icons/io5";
import { ClipLoader } from 'react-spinners';
import { motion } from 'framer-motion';
import { FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { MdOutlineDataset, MdOutlineStackedLineChart } from "react-icons/md";
import NutrientThreshold from '../NutrientThreshold';
import { CiSearch } from 'react-icons/ci';
import { BiSolidLeaf } from "react-icons/bi";
import { useGridSearchStore } from '../../store/gridStore';
import { formatDate, getStatusColor, toTitleCase } from '../../utils/fileUtils';
import { FaRegNoteSticky } from "react-icons/fa6";
import { AssignApplicator, GetApplicatorList, useGetAssignedApplicator } from '../../api/ApiMethods';
import { UserContext } from '../../Context/ContextProvider';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import NDVIComponent from '../Modal/NDVIComponent';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { CloseButton } from '../../ReusableComponents/Buttons';
import { FetchApplications } from '../../api/interventionAPI';

const useGridSelector = (state) => state.grid
const useSetGridSearchSelector  = (state) => state.setGridSearch

export default function RasterListTable({ getFarmDetails, getRasterDetails, modalValue, isLoadingFarm }) {
  
  const { userData } = useContext(UserContext)
  const grid = useGridSearchStore(useGridSelector)

  const [deleteId, setDeleteId] = useState()
  const [nutrientId, setNutrientId] = useState('')
  const [category, setCategory] = useState('')
  const [deleteModal, showDeleteModal] = useState(false)
  const [nutrientModal, showNutrientModal] = useState(false)
  const [applicatorModal, showApplicatorModal] = useState(false)
  const [gridId, setGridId] = useState()
  const [ndviModal, showNDVIModal] = useState(false)

  const navigate = useNavigate()
  const [query] = useSearchParams()
  const fid = query.get('fid')
  const company = query.get('company')
  //console.log('component', isLoadingFarm)

  // console.log('det', getFarmDetails)

  const handleDeleteRaster = (id) => {
    //console.log('id to be delete', id)
    const deleteFormData = new FormData()
    deleteFormData.append('grid_id', id)
    setDeleteId(id)
    showDeleteModal(true)
  }

  // const handleNavigateMap = (gid, bid) =>{
  //   navigate(`/app/farm/q?bid=${bid}&company=${company}&block-name=${getFarmDetails?.blockName}&gid=${gid}`) 
  // }

  const handleAssignApplicator = (gid) => {
    //console.log(gid)
    setGridId(gid)
    showApplicatorModal(!applicatorModal)
  }

  const handleNutrientThreshold = (rid, category) => {
    showNutrientModal(!nutrientModal)
    setNutrientId(rid)
    setCategory(category)
    setGridId(rid)
  }

  const handleInputNDVI = (gridId) => {
    showNDVIModal(!ndviModal)
    setGridId(gridId)
  }

  // SORT THE TABLE TO ASCENDING ORDER BY ID
  const sortedRasterDetails = getRasterDetails?.slice().sort((a, b) => a.id - b.id)
  // console.log(sortedRasterDetails)
  
  // Implement search functionality
  const filterGrids = sortedRasterDetails
  ? sortedRasterDetails.filter((item) => {
      return grid.toLowerCase() === ''
          ? item
          : item.gridName.toLowerCase().includes(grid.toLowerCase());
      })
  : [];

  return (
    <div className='raster__list__container'>
      <div className='raster__header'>
        <Button 
          style={{
            backgroundColor:  'var(--primary__color)',
            textTransform: 'capitalize',
          }}
          startIcon={<IoAddOutline/>}
          variant="contained"
          onClick={()=>modalValue(true)}>
            Add Grid
        </Button>
        <SearchGridComponent/>
      </div>

    {
      !isLoadingFarm ? 
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3}}
          className='raster__container__table'
          >
          <table className='rasters__table__display'>
              <thead>
                  <tr>
                      {/* <th style={{width: '20%'}}>Flight Date</th>
                      <th style={{width: '20%'}}>Grid</th>
                      <th style={{width: '10%'}}>Application status</th>
                      <th style={{width: '30%'}}>Applicator</th>
                      <th style={{width: '25%'}}>Actions</th> */}
                      <th>Flight Date</th>
                      <th>Grid</th>
                      {/* <th>Application status</th> */}
                      <th>Applicator</th>
                      <th>Actions</th>
                  </tr>
              </thead>
              <tbody>
                {filterGrids?.map((item) => (
                  <tr key={item.id}>
                  {/* <td>{item.id}</td> */}
                  <td>{formatDate(item.upload_raster_date)}</td>
                  <td>{item.gridName}</td>
                  {/* <td>{item.applicationStatus ? 'C' : 'X'}</td> */}
                  <td>
                    {
                      item.grid_applicators.length > 0 ? (
                        item.grid_applicators.map((applicator) => (
                          <div key={applicator.id}>
                            {applicator.applicator.first_name} {applicator.applicator.last_name}
                          </div>
                        ))
                      ) : (
                        <span>No Applicators Assigned</span>
                    )}
                  </td>

                  <td>
                    <div className='raster__tb__actions'>
                      {
                      userData?.role === 'Admin' || userData?.role === 'Customer' || userData?.role === 'Manager' ?
                      <>
                      <IconButton
                        style={{
                          backgroundColor:  'var(--primary__color)',
                          color: 'var(--bkg__primary)',
                          padding: '0.6rem',
                          borderRadius: '13px',
                        }}
                        size='small'
                        onClick={()=> handleAssignApplicator(item.id, item.block_id)}
                      >
                        <FaUserAlt/>
                      </IconButton>

                      <IconButton
                        style={{
                          backgroundColor:  'var(--bkg__edit)',
                          color: 'var(--bkg__primary)',
                          padding: '0.6rem',
                          borderRadius: '13px',
                        }}
                        size='small'
                        onClick={()=> handleInputNDVI(item.id)}
                      >
                        <BiSolidLeaf/>
                      </IconButton>

                      <IconButton
                        style={{
                          backgroundColor:  'var(--bkg__edit)',
                          color: 'var(--bkg__primary)',
                          padding: '0.6rem',
                          borderRadius: '13px',
                        }}
                        size='small'
                        onClick={()=> handleNutrientThreshold(item.id, item.farm_category)}
                      >
                        <MdOutlineDataset/>
                      </IconButton>

                      <IconButton
                        style={{
                          backgroundColor:  'var(--red)',
                          color: 'var(--bkg__primary)',
                          padding: '0.6rem',
                          borderRadius: '13px',
                        }}
                        size='small'
                        onClick={()=>handleDeleteRaster(item.id)}
                        >
                        <AiOutlineDelete/>
                      </IconButton>
                      </>
                      :
                      <div style={{padding: '0.6rem'}}>
                        No actions available
                      </div>
                      }
                    </div> 
                  </td>
                  </tr>
                ))}
                
                {!filterGrids?.length &&
                  (<tr><td colSpan="4"><label className='raster__list__records'>No data avaiable.</label></td></tr>)
                }
              </tbody>
          </table>
        </motion.div>
      :
      <div className='raster__loading_spinners'>
        <ClipLoader color="green" loading={ isLoadingFarm } size={30}/>
      </div>
    }

    {
      deleteModal ? 
        <DeleteModalPrompt 
          showDeleteModal = { showDeleteModal }
          rasterId = { deleteId }
          company = { company }
          blockName = { getFarmDetails?.blockName }
          farmName = { getFarmDetails?.farm.farmName }
        /> 
      :
      ''
    }

    
    {
      nutrientModal ?
        <NutrientThreshold
          modalValue={ showNutrientModal }
          rasterId = { nutrientId }
          category = { category }
          gridId = { gridId }
        />
      :
      '' 
    }

    {
      applicatorModal ? 
        <AssignApplicatorModal
          gridId = { gridId }
          showApplicatorModal = { showApplicatorModal }
        />
      :
      ''
    }

    { ndviModal && 
      <NDVIComponent
        showNDVIModal={showNDVIModal}
        gridId={gridId}
      />
    }

    </div>
  )
}


const SearchGridComponent = () => {
  const setGridSearch = useGridSearchStore(useSetGridSearchSelector)
  const [query] = useSearchParams()
  //console.log('render parent view content')
  // const blockName = query.get('blockName')
  // const company = query.get('company')

  return(
      <div className='search__gridraster__container'>
          <TextField 
              id="search-grid" 
              label="Search Grid..." 
              variant="standard" 
              InputProps={{
                  startAdornment: (
                      <InputAdornment position='start'>
                          <CiSearch/>
                      </InputAdornment>
                  )
              }}
              sx={{
                width: '500px'
              }}
              onChange={(e)=>setGridSearch(e.target.value)}
          />
      </div>
  )
}

const AssignApplicatorModal = ({ showApplicatorModal, gridId }) => {
  const { userData } = useContext(UserContext)

  const [applicatorList, showApplicatorList] = useState('')
  const [scheduleDate, setScheduleDate] = useState(null)
  const [fertilizerId, setFertilizerId] = useState('')
  const [errorScheduleDate, setErrorScheduleDate] = useState(false)
  const [errorFertilizer, setErroFertilzier] = useState(false)
  const [inputModal, showInputModal] = useState(false)

  const queryClient = useQueryClient()
  const { data: applicatorLists } = GetApplicatorList(userData?.company_id)
  const { data: assignedApplicator, isLoading } = useGetAssignedApplicator(gridId)
  const { mutate: postApplicator, isLoading: assignedApplicatorLoader } = AssignApplicator()

  
  //console.log('list appli', applicatorLists)

  const handleChange = (e) => {
    showApplicatorList(e.target.value)
  }

  const handleFertilizerChange = (e) => {
    setFertilizerId(e.target.value)
    setErroFertilzier(false)
  }

  const handleAssignApplicator = () => {

    console.log(fertilizerId)
    if(fertilizerId==''){
      setErroFertilzier(true)
      return(
        toast.error('Please select a fertilizer.', {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            pauseOnFocusLoss: false,
            })
        )
    }

    if(scheduleDate==null){
      setErrorScheduleDate(true)
      return(
      toast.error('Please provide a date.', {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
          pauseOnFocusLoss: false,
          })
      )
    }

    const parsedDate = new Date(scheduleDate);

    // // Extract the date part
    const year = parsedDate.getFullYear();
    const month = parsedDate.getMonth() + 1; // Month is zero-indexed
    const day = parsedDate.getDate();

    // // Format the date in YYYY-MM-DD format
    const finalDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
 

    const app_data = new FormData()
    app_data.append('gid', gridId)
    app_data.append('apid', applicatorList)
    app_data.append('schedule', finalDate)
    app_data.append('fertilizer_id', fertilizerId)

    postApplicator(
      app_data, {
        onSuccess: (data) =>{
        console.log("Success", data)

        toast.success('Successfully assign applicator.', {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          pauseOnFocusLoss: false,
          theme: "light",
        });

        //showApplicatorModal(false)
        showInputModal(false)
        
        queryClient.invalidateQueries('fetch-block-details')
        queryClient.invalidateQueries('get-assigned-applicator')
    },
        onError: (error) =>{
        //console.clear()
        toast.error('Applicator is already dispatched.', {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          pauseOnFocusLoss: false,
          theme: "light",
        });
        }
    }
    )
  }

  const handleDateChange = (newVal) => {
    setErrorScheduleDate(false)
    setScheduleDate(newVal)
  }

  return(
    <div className='assign__applicator__container'>
      <div className='assign__applicator__content'>
        <div className='assign__applicator__header'>
          <h4>Assigned Applicator</h4>
          <Button
            style={{
              backgroundColor:  'var(--primary__color)',
              textTransform: 'capitalize',
            }}
            startIcon={<IoAddOutline/>}
            variant='contained'
            onClick={()=>showInputModal(!inputModal)}
          >
            Assign
          </Button>
        </div>
        
        { 
          !isLoading ?
          <AssingApplicatorTable
            assignedApplicator = { assignedApplicator }
          />
          :
          <div className='assign__applicator__table__loader'>
            loading,..
          </div>
        }

        <div className='assign__actions'>
          <Button
            style={{
              background: 'transparent',
              textTransform: 'capitalize',
              color: 'black'
            }}
            disableRipple
            onClick={()=>showApplicatorModal(false)}
          >
            close
          </Button>
        </div>
      </div>
      
      {
        inputModal ? 
          (
          <ApplicatorInputModal 
            handleAssignApplicator = { handleAssignApplicator }
            handleFertilizerChange = { handleFertilizerChange }
            fertilizerId = { fertilizerId }
            applicatorList = { applicatorList }
            scheduleDate = { scheduleDate }
            errorScheduleDate = { errorScheduleDate }
            handleDateChange = { handleDateChange }
            applicatorLists = { applicatorLists }
            showInputModal = { showInputModal }
            handleChange = { handleChange }
            assignedApplicatorLoader = { assignedApplicatorLoader }
            errorFertilizer = { errorFertilizer }
          />
          ) 
          : 
          ''
      }
    </div>
  )
}

const AssingApplicatorTable = ({ assignedApplicator }) => {
  console.log(assignedApplicator)
  return(
    <div className='assign__applicator__table__container'>
      <table className='applicator__table'>
        <thead>
          <tr>
            <th>Applicator</th>
            <th>Status</th>
            <th>Application</th>
            <th>Scedule</th>
            {/* <th>Remarks</th>
            <th>Actions</th> */}
          </tr>
        </thead>
        <tbody>
          {
            assignedApplicator?.map((item)=>(
              <tr key={item.id}>
                <td>{`${toTitleCase(item.applicator.first_name)} ${toTitleCase(item.applicator.last_name)}`}</td>
                <td>
                  <span
                    style={{
                      backgroundColor: getStatusColor(item.status),
                      padding: '0.5rem 0.8rem',
                      borderRadius: '2rem',
                      width:'70px',
                      color: 'black',
                      textTransform: 'capitalize',
                      fontWeight: '550',
                    }}
                  >
                    {item.status}
                  </span>
                </td>
                <td>{toTitleCase(item.fertilizer.fertilizer_type)}</td>
                <td>{formatDate(item.schedule)}</td>
                {/* <td>{item.remarks}</td>
                <td>
                  <IconButton
                    style={{
                      backgroundColor:  'var(--red)',
                      color: 'var(--bkg__primary)',
                      padding: '0.6rem',
                      borderRadius: '13px',
                    }}
                    size='small'
                    //onClick={()=> handleAssignApplicator(item.id, item.block_id)}
                  >
                    <AiOutlineDelete/>
                  </IconButton>
                </td> */}
              </tr>
            ))
          }
          {
            !assignedApplicator?.length &&
              (
                <tr>
                  <td colSpan='6'><label className='raster__list__records'>No assigned applicator.</label></td>
                </tr>
              )
          }
        </tbody>
      </table>
    </div>
  )
}

const ApplicatorInputModal = ({ handleAssignApplicator, showInputModal, applicatorLists, 
  handleDateChange, handleChange, applicatorList, scheduleDate, errorScheduleDate,
  handleFertilizerChange, fertilizerId, assignedApplicatorLoader, errorFertilizer}) => {

  const { userData } = useContext(UserContext)

  const { data: listFertilizerApplication } = FetchApplications(userData?.company_id)

    console.log(applicatorLists)
  return(
    <div className='applicator__input__modal__container'>
      <div className='applicator__input__content'>
          
        <div className='assign__applicator__selector'>
          <h4>Assign Applicator</h4>
          <FormControl 
            size='small'
          >
            <InputLabel id="applicators-label">Applicators</InputLabel>
              <Select
                labelId="applicators-label"
                id="applicators-select"
                value={applicatorList}
                label="Applicators"
                onChange={handleChange}
              >
                {applicatorLists?.map(applicator => (
                  <MenuItem key={applicator.id} value={applicator.id}>{applicator.first_name} {applicator.last_name}</MenuItem>
                ))}
              </Select>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              //disabled = { isLoading }
              label='Schedule Date'
              value={scheduleDate}
              onChange={handleDateChange}
              slotProps={{
                textField:{
                  error: errorScheduleDate ? true : false,
                  size:'small'
              }}}
            />
          </LocalizationProvider>

          <FormControl 
            size='small'
            error={errorFertilizer ? true : false}
          >
            <InputLabel id="fertilizer-label">Fertilizer</InputLabel>
              <Select
                labelId="fertilizer-label"
                id="fertilizer-select"
                value={fertilizerId}
                label="Fertilizer"
                onChange={handleFertilizerChange}
              >
                {listFertilizerApplication?.map(fertilizer => (
                  <MenuItem key={fertilizer.intervention_id} value={fertilizer.intervention_id}>{fertilizer.fertilizer_type}</MenuItem>
                ))}
              </Select>
          </FormControl>
        </div>

        <div className='applicator__assigned__actions'>
          <CloseButton
            name={"Close"}
            onClick={()=>showInputModal(false)}
          />

          <Button
            size='small'
            variant='contained'
            style={{
              textTransform: 'capitalize',
              backgroundColor: 'var(--primary__color)'
            }}
            disabled={assignedApplicatorLoader ? true : false}
            loading={assignedApplicatorLoader.toString()}
            loadingposition="start" 
            startIcon={ <ClipLoader color="white" loading={ assignedApplicatorLoader } size={15}/>}
              onClick={handleAssignApplicator}
          >
              Dispatch
          </Button>
        </div>

      </div>
    </div>
  )
}