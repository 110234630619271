import React from 'react'
import './css/WeatherData.css'
import { Button } from '@mui/material'
import { motion } from 'framer-motion'
import { toast } from 'react-toastify'
import { AddWeather, GetWeatherList } from '../../api/weatherAPI'
import { useSearchParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { CiImport } from "react-icons/ci";
import { changeFileName, formatDate } from '../../utils/fileUtils'

export default function WeatherData({ setWeatherModal, company }) {
  const ButtonProperty = {
    size: 'small',
  }
  const queryClient = useQueryClient()

  const [query] = useSearchParams()

  const fid = query.get('fid')
  console.log('farm id', fid)

  // const [weather, setWeather] = useState()

  const { mutate: postWeather , isLoading } = AddWeather()
  const { data: getWeather,
    error,
   // isLoading: loadingWeather,
  } = GetWeatherList(fid)


  // console.log('weather', typeof(getWeather?.data.data))
  // console.log('weather', getWeather?.data.data)

  const handleUploadWeather = (e) => {
    const file = e.target.files[0]
    const file_type = 'weather'

    const {file: newFile, extension} = changeFileName(file, company, file_type)
    console.log(extension)
    console.log("new file ", newFile)

    if (extension === '.csv' || extension === '.xlsx'){
      //console.log('posting....')
      const formData = new FormData()

      formData.append('weatherData', newFile)
      formData.append('farm_id', fid)

      postWeather(formData, {
        onSuccess: (data) => {
          console.log(data)
          queryClient.invalidateQueries('fetch-weather')
          toast.success('Import successfully.', {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            pauseOnFocusLoss: false,
            progress: undefined,
            theme: "light",
          });   
        },
        onError: (error) => {
          console.log(error)
        }
      })
    }
    else{
      return toast.error('Invalid file extension.', {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        pauseOnFocusLoss: false,
        progress: undefined,
        theme: "light",
      })
    }    
  }

  return (
    <div className='weather__data__container'>
      <motion.div 
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3}}
        className='weather__display'>
        
        
        <div className='weather__header'>
          <h3>Weather List</h3>
          <Button
            //fullWidth
            variant='contained'
            component='label'   
            
            startIcon={<CiImport/>}
            size={ButtonProperty.size}
            style={{
              textTransform: 'capitalize',
            }}
          > 
            <label>Import</label>
            <input 
                type="file" 
                hidden
                disabled={isLoading}
                onChange={handleUploadWeather}
            />
        </Button>
        </div>

        <div className='weather__body'>
          {
            getWeather?.data ? (
          <table className='weather__table__display'>
            <thead>
                <tr>
                    <th style={{width: '20%'}}>Date</th>
                    <th style={{width: '20%'}}>Temperature</th>
                    <th style={{width: '20%'}}>Humidity</th>
                    <th style={{width: '25%'}}>Rainfall</th>
                </tr>
            </thead>
            <tbody>
                {
                  getWeather?.data.data.map((item, index) => (
                    <tr key={item.id}>
                      <td>{formatDate(item.date)}</td>
                      <td>{item.temperature}</td>
                      <td>{item.humidity}</td>
                      <td>{item.rain}</td>
                    </tr>
                ))}
            </tbody>
          </table>
          )
          :
            (
              <div className='display__no__data'>
                {error?.response.data.message}
              </div>
            )
          }
        </div>
        
        <div className='weather__actions'>
          <Button
            style={{
            backgroundColor:  'var(--no_background)',
            color: 'var(--fontColor__secondary)',
            textTransform: 'capitalize',
            boxShadow: 'none',
            }}
            variant="contained"
            onClick={()=>setWeatherModal(false)}
            >
              <span>Close</span>
          </Button>
        </div>
      </motion.div>
    </div>
  )
}