import React, { useContext, useEffect, useState } from 'react'
import './css/ReportHeader.css'
import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import { useReportStore } from '../store/reportStore'
import { MdRectangle } from 'react-icons/md'
import { color } from '../config/mapColor'
import { QueryReportListFarmBlockGrid } from '../api/ApiMethods'
import { UserContext } from '../Context/ContextProvider'


export default function ReportHeader() {
    const { userData } = useContext(UserContext)

    const { data } = QueryReportListFarmBlockGrid(userData?.company_id)

  return (
    <div className='report__header__container'>
        <ReportLegend />
        <ReportSearch 
            data = {data}
        />
    </div>
  )
}

const ReportSearch = ({data}) => {
    // Access actions
    const setFarmName = useReportStore((state) => state.setFarmName);
    const setBlockName = useReportStore((state) => state.setBlockName);
    const setGridName = useReportStore((state) => state.setGridName);
    const setMonth = useReportStore((state) => state.setMonth);

    const [farms, setFarms] = useState([]);
    const [blocks, setBlocks] = useState([]);
    const [grids, setGrids] = useState([]);

    const [selectedFarm, setSelectedFarm] = useState('--ALL--');
    const [selectedBlock, setSelectedBlock] = useState('--ALL--');
    const [selectedGrid, setSelectedGrid] = useState('--ALL--');

    const [searchDate, setSearchDate] = useState('--ALL--')

    // const [searchFarm, setSearchFarm] = useState('--ALL--')
    // const [searchBlock, setSearchBlock] = useState('--ALL--')
    // const [searchGrid, setSearchGrid] = useState('--ALL--')

    useEffect(() => {
        setFarms(data?.map(farm => ({ name: farm.farmName })));
      }, [data]);
    
    const handleFarmChange = (event) => {
        const farmName = event.target.value;
        setSelectedFarm(farmName);
        setSelectedBlock('');
        setSelectedGrid('');
    
        if (farmName === '--ALL--') {
            setBlocks([]);
            setGrids([]);
            setSelectedBlock('--ALL--');
            setSelectedGrid('--ALL--');
        } else {
            const selectedFarmData = data.find(farm => farm.farmName === farmName);
            setBlocks(selectedFarmData.block_list?.map(block => ({ name: block.blockName })));
        }
    };
    
    const handleBlockChange = (event) => {
        const blockName = event.target.value;
        setSelectedBlock(blockName);
        setSelectedGrid('');
    
        if (blockName === '--ALL--') {
            setGrids([]);
            setSelectedGrid('--ALL--');
        } else {
            const selectedFarmData = data.find(farm => farm.farmName === selectedFarm);
            const selectedBlockData = selectedFarmData.block_list.find(block => block.blockName === blockName);
            if (Array.isArray(selectedBlockData.distinct_grid_names)) {
                setGrids(selectedBlockData.distinct_grid_names?.map(gridName => ({ name: gridName })));
            } else {
                setGrids([{ name: selectedBlockData.distinct_grid_names }]);
            }
        }
    };
    
    const handleGridChange = (event) => {
        setSelectedGrid(event.target.value);
    };

    const handleMonthChange = (e) => {
        setSearchDate(e.target.value)
    }

    const handleQueryReportV2 = () => {
        console.log(`Query search is ${selectedFarm}*${selectedBlock}*${selectedGrid} and Month*${searchDate}`)
    
        setFarmName(selectedFarm)
        setBlockName(selectedBlock)
        setGridName(selectedGrid)
        setMonth(searchDate)
    }

    const resetSearchParams = () => {
        setSelectedFarm('--ALL--');
        setSelectedBlock('--ALL--');
        setSelectedGrid('--ALL--');
        setSearchDate('--ALL--');
        setBlocks([]);
        setGrids([]);
        
        // Reset the global state
        setFarmName('--ALL--');
        setBlockName('--ALL--');
        setGridName('--ALL--');
        setMonth('--ALL--');
    }

    return(
        <div className='report__search__container'>
            <div className='report__select__location__container'>
                <div className='report__select__params'>
                    <FormControl fullWidth size='small'>
                        <InputLabel id="farm-label">Farm</InputLabel>
                        <Select
                        labelId="farm-label"
                        id="farm-select"
                        value={selectedFarm}
                        label="Farm"
                        onChange={handleFarmChange}
                        >
                        <MenuItem value='--ALL--'>--ALL--</MenuItem>
                        {farms?.map(farm => (
                            <MenuItem key={farm.name} value={farm.name}>{farm.name}</MenuItem>
                        ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth size='small'>
                        <InputLabel id="block-label">Block</InputLabel>
                        <Select
                        labelId="block-label"
                        id="block-select"
                        value={selectedBlock}
                        label="Block"
                        onChange={handleBlockChange}
                        disabled={!selectedFarm || selectedFarm === '--ALL--'}
                        >
                        <MenuItem value='--ALL--'>--ALL--</MenuItem>
                        {blocks?.map(block => (
                            <MenuItem key={block.name} value={block.name}>{block.name}</MenuItem>
                        ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth size='small'>
                        <InputLabel shrink id="grid-label">Grid</InputLabel>
                        <Select
                            notched
                            labelId="grid-label"
                            id="grid-select"
                            value={selectedGrid}
                            label="Grid"
                            onChange={handleGridChange}
                            disabled={!selectedBlock || selectedBlock === '--ALL--' || grids.length === 1}
                        >
                        <MenuItem value='--ALL--'>--ALL--</MenuItem>
                        {grids?.map(grid => (
                            <MenuItem key={grid.name} value={grid.name}>{grid.name}</MenuItem>
                        ))}
                        </Select>
                        {
                            grids.length === 1 &&(
                            <FormHelperText
                                style={{margin: '0'}}
                                margin='dense'
                                error={true}>
                                    No Grids Found...
                            </FormHelperText>
                            )
                        }
                        
                    </FormControl>
                </div>

                <FormControl 
                    size='small'
                    sx={{
                        width: '386px'
                    }}
                >
                    <InputLabel 
                        //shrink
                        id="month-label"
                    >
                        Month
                    </InputLabel>
                    <Select
                        //disabled = {isLoading}
                        //notched
                        labelId="month-label"
                        id="month-select"
                        value = {searchDate}
                        label="month"
                        name="month"
                        onChange={handleMonthChange}
                    >
                        <MenuItem value={'--ALL--'}>--ALL--</MenuItem>
                        <MenuItem value={1}>JANUARY</MenuItem>
                        <MenuItem value={2}>FEBRUARY</MenuItem>
                        <MenuItem value={3}>MARCH</MenuItem>
                        <MenuItem value={4}>APRIL</MenuItem>
                        <MenuItem value={5}>MAY</MenuItem>
                        <MenuItem value={6}>JUNE</MenuItem>
                        <MenuItem value={7}>JULY</MenuItem>
                        <MenuItem value={8}>AUGUST</MenuItem>
                        <MenuItem value={9}>SEPTEMBER</MenuItem>
                        <MenuItem value={10}>OCTOBER</MenuItem>
                        <MenuItem value={11}>NOVEMBER</MenuItem>
                        <MenuItem value={12}>DECEMBER</MenuItem>
                    </Select>
                </FormControl>
            </div>
            
            
            
            <div className='report__header__actions'>
                <Button
                    disableRipple
                    style={{
                        textTransform: 'capitalize',
                        color: 'black',
                        background: 'transparent'
                    }}
                    onClick={resetSearchParams}
                >
                    Clear
                </Button>

                <Button
                    disableRipple
                    style={{
                        textTransform: 'capitalize'
                    }}
                    variant='contained'
                    onClick={handleQueryReportV2}
                >
                    Search
                </Button>
            </div>
        </div>
    )
}

const ReportLegend = () => {
    return(
        <div className='report__legend__container'>
            <h4>Legend</h4>

            <table className='legend__table'>
                <thead>
                    <tr>
                        <th>
                            % Nitrogen
                        </th>
                        <th>
                            Color Code
                        </th>
                        <th>
                            Category
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1% - 3%</td>
                        <td> 
                            <MdRectangle 
                                size={20}
                                color={color.LOW}
                            /> 
                        </td>
                        <td>LOW</td>
                    </tr>
                    <tr>
                        <td>3% - 4%</td>
                        <td> 
                            <MdRectangle 
                                size={20}
                                color={color.NORMAL}
                            /> 
                        </td>
                        <td>NORMAL</td>
                    </tr>
                    <tr>
                        <td><span>{`> 4.1%`}</span></td>
                        <td> 
                            <MdRectangle   
                                size={20}
                                color={color.HIGH}
                            /> 
                        </td>
                        <td>HIGH</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}