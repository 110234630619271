import React, { useContext, useState } from 'react'
import './css/InterventionInputModal.css'
import { ButtonProperty, CloseButton, SubmitButton } from '../ReusableComponents/Buttons'
import { InputAdornment, TextField } from '@mui/material'
import { UserContext } from '../Context/ContextProvider'
import { AddApplication } from '../api/interventionAPI'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'

export default function InterventionInputModal({showInterventionModal}) {
    const queryClient = useQueryClient()
    const { userData } = useContext(UserContext)
    const [applicationForm, setApplicationForm] = useState({
        fertilizer_type: '',
        target_yield: '',
        plant_population: '',
    })

    const [errorApplicationForm, setErrorApplicationForm] = useState({
        fertilizer_type: false,
        target_yield: false,
        plant_population: false,
    })

    const { mutate: postApplication } = AddApplication()


    const handleInputChange = (e) =>{
        setApplicationForm({
          ...applicationForm,
          [e.target.name]: e.target.value,
        })

        // Clear error for the respective field when the user starts typing
        setErrorApplicationForm((prevError) => ({
          ...prevError,
          [e.target.name]: ""
        }));
    }

    const handleApplicationSubmit = () => {
        console.log(applicationForm)
        const form_data = new FormData()

        for (const key in applicationForm) {
            form_data.append(key, applicationForm[key]);
        }

        form_data.append('company', userData?.company_id)

        postApplication(form_data, {
            onSuccess: (data) =>{
                toast.success('Successfully created application', {
                    position: "top-right",
                    autoClose: 2500,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    pauseOnFocusLoss: false,
                    theme: "light",
                });
        
                setErrorApplicationForm('')
                showInterventionModal(false)
                queryClient.invalidateQueries('fetch-applications')
            },
            onError: (error) => {
                console.log(error)
                //setError(error.response.data)
                toast.error('Please fill up the fields.', {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
                });
            }
        })
    }   


  return (
    <div className='intervention__modal__container'> 
      
      <div className='intervention__modal__content'>
        <h4>Fertilizer Application</h4>

        <div className='intervention__modal__inputs'>
            
            {/* <TextField
                //disabled = {isLoading}
                //fullWidth 
                name = 'intervention-name'
                id = "outlined-basic" 
                label = "Intervention Name" 
                size={ButtonProperty.size}
                variant = "outlined" 
                //value = {formik.values.location}
                //onChange = {formik.handleChange}
            /> */}

            <TextField
                //disabled = {isLoading}
                //fullWidth 
                name = 'fertilizer_type'
                id = "outlined-basic" 
                label = "Fertilizer Type" 
                size={ButtonProperty.size}
                variant = "outlined" 
                //value = {formik.values.location}
                onChange = {handleInputChange}
            />

            <TextField
                //disabled = {isLoading}
                //fullWidth 
                name = 'target_yield'
                id = "outlined-basic" 
                label = "Target Yield" 
                size={ButtonProperty.size}
                variant = "outlined" 
                inputProps={{type: 'number'}}
                InputProps={{
                    endAdornment: <InputAdornment position="start">tons/ha</InputAdornment>,
                }}
                //value = {formik.values.location}
                onChange = {handleInputChange}
            />

            <TextField
                //disabled = {isLoading}
                //fullWidth 
                name = 'plant_population'
                id = "outlined-basic" 
                label = "Population" 
                size={ButtonProperty.size}
                variant = "outlined" 
                inputProps={{type: 'number'}}
                InputProps={{
                    endAdornment: <InputAdornment position="start">/ha</InputAdornment>,
                }}
                //value = {formik.values.location}
                onChange = {handleInputChange}
            />

            {/* <TextField
                //disabled = {isLoading}
                //fullWidth 
                name = 'high'
                id = "outlined-basic" 
                label = "High" 
                size={ButtonProperty.size}
                variant = "outlined" 
                inputProps={{type: 'number'}}
                InputProps={{
                    endAdornment: <InputAdornment position="start">grams</InputAdornment>,
                }}
                //value = {formik.values.location}
                onChange = {handleInputChange}
            /> */}
        </div>

        <div className='intervention__modal__actions'>
            <CloseButton
                name={'Close'}
                onClick={()=>showInterventionModal(false)}
            />
            <SubmitButton
                name={"Submit"}
                onClick={handleApplicationSubmit}
            />
        </div>
      
      </div>
    </div>
  )
}
