import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
//import image from '../../src/assets/logov2.png'
import './css/Navbar.css'
import { Button } from '@mui/material';

export default function Navbar() {
  const navigate = useNavigate()

  const [showLink, setShowLink] = useState(false)

  const handleLinkClick = () => {
    setShowLink(false);
  };

  return (
    <div className='navbar-container'>
        <div className='logo-container'>
              {/*<img src={image} alt="images"></img>*/}
              <h1><Link to="/">BANAT</Link></h1>
          </div>
          <div className='navbar-ul'>
            <ul id={ showLink ? "hidden" : "" }>
                <li><Link to="/" onClick={handleLinkClick}>Home</Link></li>
                <li><Link to="/About" onClick={handleLinkClick}>About</Link></li>
                <li><Link to="/Services" onClick={handleLinkClick}>Services</Link></li>
                <li><Link to="/Contact" onClick={handleLinkClick}>Contact Us</Link></li>
                {/* <li><Link className='launch-btn' to="/login">Launch App</Link></li> */}
                <li>
                  <Button
                    variant='contained'
                    style={{
                      backgroundColor:  'var(--primary__color)',
                    }}
                    onClick={()=>navigate('/login')}
                  >Launch App
                  </Button>
                </li>
            </ul>
            <div className='nav-btn-contain'>
              <button onClick={() => setShowLink(!showLink)} className='nav-btn'><i className="fa-solid fa-bars"></i></button>
            </div>
          </div>
    </div>
  )
}
