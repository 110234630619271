import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import { ClipLoader } from 'react-spinners'
import './css/AddBlockModal.css'
import { motion } from 'framer-motion'
import { UserContext } from '../Context/ContextProvider'
import { AddBlock, FetchFarms } from '../api/ApiMethods'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'

export default function AddBlockModal({ setBlockModal }) {
    const { userData } = useContext(UserContext)
    //console.log(userData)
    const queryClient = useQueryClient()

    const { data: fetchfarms } = FetchFarms(userData?.company_id)
    console.log(fetchfarms)

    const { mutate: addBlock, isLoading } = AddBlock()

    const [errorFields, iserrorFields] = useState({
        farmName: '',
        blockName: ''
      })
    
      const [form, setForm] = useState({
        farmName: "",
        blockName: ""
      })

    const handleInputChanges = (e) => {
        setForm({
          ...form,
          [e.target.name]: e.target.value,
        })
    
        iserrorFields((prevError) => ({
          ...prevError,
          [e.target.name]: ""
        }));
    };

    const postBlockModal = async(e) => {
        e.preventDefault()

        const create_data = new FormData()
        

        for (const key in form) {
            create_data.append(key, form[key]);
        }
        // create_data.append('user', userData.id)
        // create_data.append('company', userData.company_id)

        addBlock(create_data, {
            onSuccess: (data) =>{
              //console.log('onSuccess', data)
              setForm({
                blockName: ""
              })
      
              iserrorFields((prevState) => ({
                ...prevState,
                blockName: ''
              }))

              queryClient.invalidateQueries('fetch-farms')
      
              toast.success('Block added succesfully.', {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
                });

                setBlockModal(false)

            },
            onError: (error) =>{ 
              //console.log('onError', error.response.data)

              if(error.response.data.message){
                iserrorFields((prevState) => ({
                  ...prevState,
                  company: true,
                  farmName: ''
                }))
      
                toast.error('Please Provide Farm', {
                  position: "top-right",
                  autoClose: 2500,
                  hideProgressBar: false,
                  closeOnClick: false,
                  pauseOnHover: false,
                  pauseOnFocusLoss: false,
                  draggable: false,
                  progress: undefined,
                  theme: "light",
                  });
                  
              }
      
              if(error.response.data.blockName){
                iserrorFields((prevState) => ({
                  ...prevState,
                  user: '',
                  blockName: error.response.data.blockName || '',
                }))
      
                toast.error('Please Fill up the fields.', {
                  position: "top-right",
                  autoClose: 2500,
                  hideProgressBar: false,
                  closeOnClick: false,
                  pauseOnHover: false,
                  draggable: false,
                  progress: undefined,
                  theme: "light",
                  });
              }
      
              if(error.response.data.block_exist){
                iserrorFields((prevState) => ({
                  ...prevState,
                  user: '',
                  blockName: error.response.data.block_exist || '',
                }))
      
                toast.error(error.response.data.block_exist, {
                  position: "top-right",
                  autoClose: 2500,
                  hideProgressBar: false,
                  closeOnClick: false,
                  pauseOnHover: false,
                  draggable: false,
                  progress: undefined,
                  theme: "light",
                  });
              }
      
            }
          })
    }


  return (
    <div className='block__modal__container'>
        <motion.div 
            className='block__modal__content'
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3}}
            >
            <h3>Add block</h3>

            <div className='farm__select__container'>
              <FormControl 
                fullWidth
                //error={errorFields.company ? true : false}
              >
                    <InputLabel id="farm-label">Farm</InputLabel>
                    <Select
                        disabled={isLoading}
                        labelId="farm-label"
                        id="farm-select"
                        value = {form.farmName}
                        label="farm"
                        name="farmName"
                        onChange={handleInputChanges}
                        >
                        {
                          fetchfarms?.map( farm => (
                            <MenuItem key={farm.id} value={farm.id}>{farm.farmName}</MenuItem>
                            
                          ))
                        }
                    </Select>
              </FormControl>
            </div>

            <div className='block__modal__fields'>
                <TextField 
                error = {errorFields.blockName ? true : false}
                fullWidth 
                name = 'blockName'
                value = {form?.blockName}
                id = "outlined-basic" 
                label = "Block name" 
                variant = "outlined" 
                onChange = {handleInputChanges}
                />

                {/* <FormControl 
                    fullWidth
                    error={errorFields.farmType ? true : false}
                    >
                    <InputLabel id="farmType-label">Farm Type</InputLabel>
                    <Select
                        //disabled = {isLoading}
                        labelId="farmType-label"
                        id="farmType-select"
                        value = {form.farmType}
                        label="farmType"
                        name="farmType"
                        onChange={handleInputChanges}
                        >
                        <MenuItem value={'Banana'}>Banana</MenuItem>
                        <MenuItem value={'Others'}>Others</MenuItem>
                    </Select>
                </FormControl> */}
            </div>

            <div className='block__modal__actions'>
                <Button
                style={{
                    backgroundColor:  'var(--no_background)',
                    color: 'var(--fontColor__secondary)',
                    textTransform: 'capitalize',
                    boxShadow: 'none',
                }}
                variant="contained"
                onClick={()=> setBlockModal(false)}
                >
                <span>Back</span>
                </Button>

                <Button 
                style={{
                    backgroundColor:  'var(--primary__color)',
                    textTransform: 'capitalize'
                }}
                disabled={isLoading ? true : false}
                loading={isLoading.toString()}
                loadingposition="start" 
                startIcon={ <ClipLoader color="white" loading={ isLoading } size={15}/>}
                variant="contained"
                onClick={postBlockModal}
                >
                <span>Submit</span>
                </Button>
            </div>
        </motion.div>
    </div>
  )
}
