import React, { useContext, useEffect, useState } from 'react'
import './css/Weather.css'
import { motion } from 'framer-motion'
import { ToggleSidebarContext } from '../Context/ContextProvider';
import { Link } from 'react-router-dom';
import { BeatLoader } from 'react-spinners'; 
import { PageTitle } from './Dashboard';

const api = {
  key: '6c92f61ae911409dbbd12456232206',
  base: 'https://api.weatherapi.com/v1/',
  days: 3,
  loc: "tagum"
}



export default function Weather() {
  const [weather, setWeather] = useState({})
  const { toggleSidebarValue, isToggleSidebarValue } = useContext(ToggleSidebarContext)


  const toggle = () =>{
    isToggleSidebarValue(!toggleSidebarValue)
  }

  useEffect(() => {
    const fetchData = async () =>{
      try{

        const result = await fetch(`${api.base}forecast.json?key=${api.key}&q=${api.loc}&days=${api.days}`)
        const json = await result.json()

        if(result.ok){
          setWeather(json)
        }
      }catch(error){
        console.log("error")
      }
      
    }

    const schedulePostRequest = () => {
      // Calculate the time remaining until the end of the day (in milliseconds)
      const now = new Date();
      const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
      const timeUntilEndOfDay = endOfDay.getTime() - now.getTime();
      console.log(timeUntilEndOfDay)
      // // Schedule the POST request to occur at the end of the day
      // setTimeout(() => {
      //   // Perform your POST request here
      //   // You can use the fetch API or any library you prefer to make the POST request
      //   fetch('YOUR_POST_ENDPOINT', {
      //     method: 'POST',
      //     // Add any headers and body data as needed
      //   })
      //   .then(response => {
      //     // Handle the response
      //   })
      //   .catch(error => {
      //     console.error('Error sending POST request:', error);
      //   });
        
      //   // Schedule the next POST request for the next day
      //   schedulePostRequest();
      // }, timeUntilEndOfDay);
    };
  
    // Start the initial scheduling
    schedulePostRequest();

    fetchData()
  }, []);

  const getForecast = (e) =>{
    e.preventDefault()

    const { forecast } = weather
    const { forecastday } = forecast
    console.log(weather)
    console.log(forecastday)

    forecastday.forEach(element =>{
      console.log(element.date)
      console.log(element.day)
    })

  }


  return (
    <div className='weather-container'>
      <PageTitle
          title = {'Weather'}
          onMenuClick = {toggle}
      />
      { typeof weather.current != "undefined" ?
        <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8}}
        className='weather__section'>
          <div className='weather__Section__title'>
            <h3>Weather</h3>
            <Link to='/app/weather/history'>Weather History</Link>
            <button onClick={getForecast}>Get Forecast Data Today</button>
          </div>  
          <div className='db__weather_current'>
            <div className='db__current__left'> 
              <h1>{weather.current.temp_c} °C</h1>
              <h3>{weather.location.name}, {weather.location.region}</h3>
              <h3>{weather.formattedTime}</h3>
            </div>
            <div className='db__current__right'> 
              <div className='db__right__status'>
                <p>{weather.current.condition.text}</p>
                <img src={weather.current.condition.icon} alt='weather'/>
              </div>
              <div className='db__right__contents'>
                <p>Wind Speed: {weather.current.wind_kph} {weather.current.wind_dir}</p>
                <p>Humidity: {weather.current.humidity}</p>
                <p>Last Update: {weather.current.last_updated}</p>
                <p>From: Weather API</p>
              </div>
            </div>
          </div>
          <div className='db__weather_forecast'>
            <div className='db__weather_forecast_title'>
              <h3>Weather forecast</h3>
            </div>
            
              <div className='db__weather__card_contain'>
                {weather.forecast.forecastday.map((day, index) => {
                  const date = new Date(day.date);
                  const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' });

                  return (
                    <div className='db__weather__card' key={index}>
                      <div className='db__weather__title'>
                        <h4>{dayOfWeek}</h4>
                        <img src={day.day.condition.icon} alt="weather-ico" />
                        <h4> {day.day.maxtemp_c} °C</h4>
                        <span>{day.day.condition.text}</span>
                      </div>
                      <div className='db__weather__content'>
                        <span><i className="fa-solid fa-droplet"/> {day.day.avghumidity} %</span>
                        <span><i className="fa-solid fa-cloud-showers-heavy"></i> {day.day.daily_chance_of_rain}%</span>
                        <span><i className="fa-solid fa-wind" /> {day.day.maxwind_kph} kph</span>
                      </div>
                    </div>
                  );
                })}
              </div>
          </div>
      </motion.div>
      :
      <div className='table__spinner'>
        <BeatLoader color="green" loading={ true } size={15}/>
      </div>
        
      }
      
      
    </div>
  )
}
