import React from 'react'
import Navbar from '../main/Navbar'
import Footer from '../main/Footer';
import { Outlet } from 'react-router-dom';

export default function RootLayout() {
  return (
    <div className='root-main'>
        <Navbar />
            <Outlet/>
        <Footer/>
    </div>
  )
}
