import React from 'react'
// import bkg2 from '../../src/assets/bkg2.jpg'
import usep from '../assets/usep.png'
import dost from '../assets/PCARD.png'
import hijo from '../assets/HIJOLOGO.jpg'
//import bkgVid from '../assets/farmvid3.mp4'
import './css/Home.css'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

import Banana from '..//assets/banana1.6.webp'

export default function Home() {
  return (
    <>
    <div className='home-container'>
        {/*<video src={bkgVid} autoPlay loop muted/> */}
        <img src={Banana} alt="banana" loading="lazy"/>
        <div className='home-content'>
            <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1}}
                className='home-items'>
                <h1>Banana Nutritional Aerial Technology</h1>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> 
               
                <Link to="/Contact">Learn More!</Link>
            </motion.div>
        </div>
    </div>
    <div className='partner-container'> 
        <h2>In Parternship with</h2>
        <div className='img-container'>
            <img src={usep} alt="partnerImg"/>
            <img src={dost} alt="partnerImg"/>
            <img src={hijo} alt="partnerImg"/>
        </div>
    </div>
    </>
  )
}
