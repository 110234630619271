import React from 'react'
import './css/SoilSensor.css'
import { Button } from '@mui/material'
import { motion } from 'framer-motion'
import { toast } from 'react-toastify'
import { CiImport } from 'react-icons/ci'
import { GetSensorList, AddSensor } from '../../api/sensorAPI'
import { useQueryClient } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import { changeFileName, formatDate } from '../../utils/fileUtils'

export default function SoilSensor({ setSensorModal }) {
  const ButtonProperty = {
    size: 'small',
  }

  const queryClient = useQueryClient()

  const [query] = useSearchParams()

  const fid = query.get('fid')
  const company = query.get('company')

  const { mutate: postSensor , isLoading } = AddSensor()
  const { data: getSensorList, error } = GetSensorList(fid)

  //console.log('senosr data: ', getSensorList?.data)

  const handleUploadSensor = (e) => {
    const file = e.target.files[0]
    const file_type = 'sensor'

    const {file: newFile, extension} = changeFileName(file, company, file_type)
    console.log(extension)
    console.log("new file ", newFile)

    if (extension === '.csv' || extension === '.xlsx'){
      //console.log('posting....')
      const formData = new FormData()

      formData.append('sensorData', newFile)
      formData.append('farm_id', fid)

      postSensor(formData, {
        onSuccess: (data) => {
          console.log(data)
          queryClient.invalidateQueries('get-sensor-data')
          toast.success('Import successfully.', {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            pauseOnFocusLoss: false,
            progress: undefined,
            theme: "light",
          });   
        },
        onError: (error) => {
          console.log(error)
        }
      })
    }
    else{
      return toast.error('Invalid file extension.', {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        pauseOnFocusLoss: false,
        progress: undefined,
        theme: "light",
      })
    }
  }

  return (
    <div className='weather__data__container'>
      <motion.div 
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3}}
        className='soil__display'>

        <div className='soil__header'>
          <h4>Soil Sensors</h4>
          <Button
            variant='contained'
            component='label'   
            
            startIcon={<CiImport/>}
            size={ButtonProperty.size}
            style={{
              textTransform: 'capitalize',
            }}
          > 
            <label>Import</label>
            <input 
                type="file" 
                hidden
                disabled={isLoading}
                onChange={handleUploadSensor}
            />
        </Button>
        </div>

        <div className='soil__body'>
        {
            getSensorList?.data ? (
          <table className='soil__table__display'>
            <thead>
                <tr>
                    <th style={{width: '30%'}}>Date</th>
                    <th style={{width: '10%'}}>Moisture</th>
                    <th style={{width: '10%'}}>pH</th>
                    <th style={{width: '25%'}}>Temperature(Moisture)</th>
                    <th style={{width: '25%'}}>Temperature(pH)</th>
                </tr>
            </thead>
            <tbody>
              {
                getSensorList?.data.data.map((item, index) => (
                    <tr key={item.id}>
                      <td>{formatDate(item.date)}</td>
                      <td>{item.moisture}</td>
                      <td>{item.pH}</td>
                      <td>{item.temperature_pH}</td>
                      <td>{item.temperature_moisture}</td>
                    </tr>
              ))}           
            </tbody>
          </table>
          )
          :
            (
              <div className='display__no__data'>
                {error?.response.data.message}
              </div>
            )
          }
        </div>
        
        <div className='soil__actions'>
          <Button
            style={{
            backgroundColor:  'var(--no_background)',
            color: 'var(--fontColor__secondary)',
            textTransform: 'capitalize',
            boxShadow: 'none',
            }}
            variant="contained"
            onClick={()=>setSensorModal(false)}
            >
              <span>Close</span>
          </Button>
        </div>
      </motion.div>
    </div>
  )
}
