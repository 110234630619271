import React, { useState } from 'react'
import './css/MapVegetationTable.css'
import { useMapStore } from '../store/mapStore'
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { FetchPredictionList } from '../api/nutrientAPI'
import { useGridResultStore } from '../store/gridStore'
import { color } from '../config/mapColor'
import { RiCheckboxBlankFill } from "react-icons/ri"

const useMapStoreSetModalSelector = (state)=> state.isModalOpen
const useGridResultStoreSelector = (state)=> state.gridResult

export default function MapVegetationTable() {
    const [query] = useSearchParams()
    const gid = query.get('gid') 
    const isModalOpen = useMapStore(useMapStoreSetModalSelector)
    const gridResult = useGridResultStore(useGridResultStoreSelector)

    const { data: predictionList } = FetchPredictionList(gid)

  return (
    <div className='map__vegetation__contaier'>
        <div className='map__vegetation__content'>
            <MapVegetationHeader
                gridResult = { gridResult }
            />
            {/* <TabMenuHeader/> */}

            <VegetatoionTable
                predictionList = { predictionList?.predicted_nitrogen_related }
            /> 

            <div className='map__vegetation__action'>
                <Button
                    disableRipple
                    onClick={()=>isModalOpen(false)} 
                    style={{
                        background: 'transparent',
                        textTransform: 'capitalize',
                        color: 'black',
                    }}
                >
                    close
                </Button>
            </div>
        </div>
        
    </div>
  )
}

const MapVegetationHeader = ({ gridResult }) => {
    
    // Calculate min, max, and mean NDVI
    const ndviValues = gridResult?.predicted_ndvi_related?.map(item => item.ndvi) || []
    const nitrogenValues = gridResult?.predicted_ndvi_related?.map(item => item.predicted_nitrogen) || [];

    const minNDVI = ndviValues > 0 ? Math.min(...ndviValues) : 0
    const maxNDVI = ndviValues > 0 ? Math.max(...ndviValues) : 0
    const meanNDVI = ndviValues.length > 0 ? (ndviValues.reduce((acc, val) => acc + val, 0) / ndviValues.length) : 0;

    const minNitrogen = nitrogenValues.length > 0 ? Math.min(...nitrogenValues) : 0;
    const maxNitrogen = nitrogenValues.length > 0 ? Math.max(...nitrogenValues) : 0;
    const meanNitrogen = nitrogenValues.length > 0 ? (nitrogenValues.reduce((acc, val) => acc + val, 0) / nitrogenValues.length) : 0;

    return(
        <div className='map__vegetation__header'>
            <h3>Summary</h3>
            <div className='vegetation__body'>
                <div className='vegetation__content'>
                    <h4>NDVI</h4>
                    <div className='vegetation__item'>
                        <div className='vegetation__item__mean__index'>
                            <p>Mean Index</p>
                            <p>
                                <RiCheckboxBlankFill color={color.LOW}/> 
                                <b>{minNDVI.toFixed(3)}</b>
                            </p>
                            <p>
                                <RiCheckboxBlankFill color={color.NORMAL}/> 
                                <b>{meanNDVI.toFixed(3)}</b>
                            </p>
                            <p>
                                <RiCheckboxBlankFill color={color.HIGH}/> 
                                <b>{maxNDVI.toFixed(3)}</b>
                            </p>
                        </div>
                        <div className='vegetation__item__mean__nitrogen'>
                            <p>Mean % of (N)</p>
                            <p>
                                <RiCheckboxBlankFill color={color.LOW}/>
                                <b>{minNitrogen.toFixed(3)}</b>
                            </p>
                            <p>
                                <RiCheckboxBlankFill color={color.NORMAL}/>
                                <b>{meanNitrogen.toFixed(3)}</b>
                             </p>
                            <p>
                                <RiCheckboxBlankFill color={color.HIGH}/> 
                                <b>{maxNitrogen.toFixed(3)}</b>
                            </p>
                        </div>
                    </div>
                </div>

                <div className='vegetation__content'>
                    <h4>VARI</h4>
                    <div className='vegetation__item'>
                        <div className='vegetation__item__mean__index'>
                            <p>Mean Index</p>
                            <p>
                                <RiCheckboxBlankFill color={color.LOW}/> 
                                <b>{gridResult?.vari_mean.LOW.toFixed(3)}</b>
                            </p>
                            <p>
                                <RiCheckboxBlankFill color={color.NORMAL}/> 
                                <b>{gridResult?.vari_mean.NORMAL.toFixed(3)}</b>
                            </p>
                            <p>
                                <RiCheckboxBlankFill color={color.HIGH}/> 
                                <b>{gridResult?.vari_mean.HIGH.toFixed(3)}</b>
                            </p>
                        </div>
                        <div className='vegetation__item__mean__nitrogen'>
                            <p>Mean % of (N)</p>
                            <p>
                                <RiCheckboxBlankFill color={color.LOW}/>  
                                <b>{gridResult?.vari_prediction_nitrogen_mean.LOW.toFixed(3)}</b>
                            </p>
                            <p>
                                <RiCheckboxBlankFill color={color.NORMAL}/> 
                                <b>{gridResult?.vari_prediction_nitrogen_mean.NORMAL.toFixed(3)}</b>
                            </p>
                            <p>
                                <RiCheckboxBlankFill color={color.HIGH}/> 
                                <b>{gridResult?.vari_prediction_nitrogen_mean.HIGH.toFixed(3)}</b>
                            </p>
                        </div>
                    </div>
                </div>

                <div className='vegetation__content'>
                    <h4>GLI</h4>
                    
                    <div className='vegetation__item'>
                        <div className='vegetation__item__mean__index'>
                            <p>Mean Index</p>
                            <p>
                                <RiCheckboxBlankFill color={color.LOW}/>  
                                <b>{gridResult?.gli_mean.LOW.toFixed(3)}</b>
                            </p>
                            <p>
                                <RiCheckboxBlankFill color={color.NORMAL}/> 
                                <b>{gridResult?.gli_mean.NORMAL.toFixed(3)}</b>
                            </p>
                            <p>
                                <RiCheckboxBlankFill color={color.HIGH}/> 
                                <b>{gridResult?.gli_mean.HIGH.toFixed(3)}</b>
                            </p>
                        </div>
                        <div className='vegetation__item__mean__nitrogen'>
                        <p>Mean % of (N)</p>
                            <p>
                                <RiCheckboxBlankFill color={color.LOW}/>   
                                <b>{gridResult?.gli_prediction_nitrogen_mean.LOW.toFixed(3)}</b>
                            </p>
                            <p>
                                <RiCheckboxBlankFill color={color.NORMAL}/> 
                                <b>{gridResult?.gli_prediction_nitrogen_mean.NORMAL.toFixed(3)}</b>
                            </p>
                            <p>
                                <RiCheckboxBlankFill color={color.HIGH}/>  
                                <b>{gridResult?.gli_prediction_nitrogen_mean.HIGH.toFixed(3)}</b>
                            </p>
                        </div>
                    </div>
                </div>

                <div className='vegetation__content'>
                    <h4>EXG</h4>
                    
                    <div className='vegetation__item'>
                        <div className='vegetation__item__mean__index'>
                            <p>Mean Index</p>
                            <p>
                                <RiCheckboxBlankFill color={color.LOW}/>  
                                <b>{gridResult?.exg_mean.LOW.toFixed(3)}</b>
                                </p>
                            <p>
                                <RiCheckboxBlankFill color={color.NORMAL}/> 
                                <b>{gridResult?.exg_mean.NORMAL.toFixed(3)}</b>
                                </p>
                            <p>
                                <RiCheckboxBlankFill color={color.HIGH}/> 
                                <b>{gridResult?.exg_mean.HIGH.toFixed(3)}</b>
                                </p>
                        </div>
                        <div className='vegetation__item__mean__nitrogen'>
                            <p>Mean % of (N)</p>
                            <p>
                                <RiCheckboxBlankFill color={color.LOW}/> 
                                <b>{gridResult?.exg_prediction_nitrogen_mean.LOW.toFixed(3)}</b>
                            </p>
                            <p>
                                <RiCheckboxBlankFill color={color.NORMAL}/> 
                                <b>{gridResult?.exg_prediction_nitrogen_mean.NORMAL.toFixed(3)}</b>
                            </p>
                            <p>
                                <RiCheckboxBlankFill color={color.HIGH}/> 
                                <b>{gridResult?.exg_prediction_nitrogen_mean.HIGH.toFixed(3)}</b>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const TabMenuHeader = () => {
    const ButtonProperty = {
        size: 'small',
    }
    // const [tabOpen, isTabOpen] = useState(0)

    // const handleTabOpen = (e, newval) => {
    //     isTabOpen(newval)
    //   }

    return(
        <div className='tab__menuHeader__container'>
            <FormControl 
                fullWidth
                //</div>error={errorFields.company ? true : false}
            >
                <InputLabel 
                    id="vegetation-label"
                    shrink
                >
                    Vegetation Index
                </InputLabel>
                    <Select
                        notched
                        //disabled={isLoading}
                        labelId="vegetation-label"
                        id="vegetation-select"
                        //value = {form.company}
                        label="vegetation-index"
                        name="vegetation-index"
                        size={ButtonProperty.size}
                        //onChange={handleInputChanges}
                    >
                        <MenuItem>NDVI</MenuItem>
                        <MenuItem>VARI</MenuItem>
                        <MenuItem>GLI</MenuItem>
                        <MenuItem>EXG</MenuItem>
                    </Select>
            </FormControl>

            <FormControl 
                fullWidth
                //</div>error={errorFields.company ? true : false}
            >
                <InputLabel 
                    id="category-label"
                    shrink
                >
                    Category
                </InputLabel>
                    <Select
                        notched
                        //disabled={isLoading}
                        labelId="category-label"
                        id="category-select"
                        //value = {form.company}
                        label="category"
                        name="category"
                        size={ButtonProperty.size}
                        //onChange={handleInputChanges}
                    >
                        <MenuItem>LOW</MenuItem>
                        <MenuItem>MEDIUM</MenuItem>
                        <MenuItem>HIGH</MenuItem>
                    </Select>
            </FormControl>
        </div>
    )
}

const VegetatoionTable = ({predictionList}) => {

    const getCategoryColor = (category) => {
        switch (category.toLowerCase()) {
          case 'low':
            return color.LOW;
          case 'normal':
            return color.NORMAL;
          case 'high':
            return color.HIGH;
          default:
            return 'inherit';
        }
      };

      

    return(
        <div className='mag__vegetation__table'>
            <table>
                <thead>
                    <tr>
                        <th style={{ width: '13%'}}>Gridbox Id</th>
                        <th style={{ width: '10%'}}>Min</th>
                        <th style={{ width: '10%'}}>Mean</th>
                        <th style={{ width: '10%'}}>Max</th>
                        <th style={{ width: '11%'}}>Size(ha)</th>
                        <th style={{ width: '12%'}}>Vegetation type</th>
                        <th>Predicited % of (N)</th>
                        <th style={{ width: '17%'}}>Category</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        predictionList?.map((item)=>{
                            const fixedCategory = item.predicted_category.replace('_',' ')
                            return(
                                <tr 
                                    key={item.pred_id}
                                >
                                    <td>{item.grid_box_id}</td>
                                    <td>{item.index_value_min.toFixed(3)}</td>
                                    <td>{item.index_value_mean.toFixed(3)}</td>
                                    <td>{item.index_value_max.toFixed(3)}</td>
                                    <td>{item.area_size.toFixed(3)}</td>
                                    <td>{item.vegetationIndex_type}</td>
                                    <td>{item.predicted_nitrogen}</td>
                                    <td>
                                        <span
                                            style={{ 
                                                backgroundColor: getCategoryColor(fixedCategory),
                                                padding: '0.5rem 0.8rem',
                                                borderRadius: '2rem',
                                                width:'70px',
                                                color: 'black',
                                                textTransform: 'capitalize',
                                                fontWeight: '550'
                                            }}
                                        >
                                            {fixedCategory}
                                        </span>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}