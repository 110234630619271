import { axiosInstance } from "./ApiMethods"
import { useMutation, useQuery } from "@tanstack/react-query";

// PUT REQUEST FOR NUTRIENT THRESHOLD
export const UpdateNitrogen = () =>{
    return useMutation({
      mutationKey: ['update-nitrogen'],
      mutationFn: async(nitrogen_data) =>{
        try{
          const res = axiosInstance.put(`nutrient/update/${nitrogen_data.get('nutrient_id')}`, nitrogen_data)
          return res
        }catch(error){
          return error
        }
      }
    })
  }

// GET THE UNIQUE LIST FOR CHARTS
//GET REQUEST FOR Grids
export const GetNutrientGridsLists = (block_id) =>{
  return useQuery({
    queryKey: ['get-nutrient-grid-list',block_id],
    queryFn: async() =>{
      return axiosInstance.get(`grid/chart/${block_id}`)
        .then(res=>{return res.data})
        .catch((error)=>{
          if(error.response){
            return error.response.data
          }else if(error.request){
            return error.request
          }else{
          }console.log(error.config);
        })
    },
    retry: false,
    refetchOnWindowFocus: false,  
  })
}


// GET REQUEST FOR ALL THE PREDICTION RESULT OF THE GRID
export const FetchPredictionList = (grid_id) =>{
  return useQuery({
    queryKey: ['fetch-predictions', grid_id],
    queryFn: async () =>{
      try{
        const res = axiosInstance.get(`grid/prediction/${grid_id}`)
        return res.then(res=>res.data)
      }catch(error){
        return error
      }
    },    
    retry: false,
    refetchOnWindowFocus: false,
  })
}


// GET THE UNIQUE LIST FOR CHARTS
//GET REQUEST FOR Grids
export const useGenerateFarmReports = (company_id, month_id, year_id) =>{
  return useQuery({
    queryKey: ['generate-farm-reports',{company_id, month_id, year_id}],
    queryFn: async() =>{
      return axiosInstance.get(`report/farm/list/${company_id}/${month_id}/${year_id}`)
        .then(res=>{return res.data})
        .catch((error)=>{
          if(error.response){
            return error.response.data
          }else if(error.request){
            return error.request
          }else{
          }console.log(error.config);
        })
    },
    retry: false,
    refetchOnWindowFocus: false,  
    enabled: !!month_id,
  })
}

export const PostNDVI = () =>{
  return useMutation({
    mutationKey: ['post-nitrogen-ndvi'],
    mutationFn: async(nitrogen_data)=>{
      try{
        const res = axiosInstance.post(`nitrogen/create/ndvi`, nitrogen_data)
        return res
      }catch(error){
        return error
      }
    }
  })
}


// GET REQUEST FOR ALL THE PREDICTION RESULT OF THE GRID
export const FetchPredictionsNitrogenList = (grid_id) =>{
  return useQuery({
    queryKey: ['fetch-predictions-nitorgen', grid_id],
    queryFn: async () =>{
      try{
        const res = axiosInstance.get(`nitrogen/list/ndvi/${grid_id}`)
        return res.then(res=>res.data)
      }catch(error){
        return error
      }
    },    
    retry: false,
    refetchOnWindowFocus: false,
  })
}
