import React from 'react'
import './css/ForecastHeader.css'

export default function ForecastHeader({label}) {
    const icons = ["fa-droplet", "fa-cloud-showers-heavy", "fa-wind"];
      
return (
    <>
        {icons.map((icon, index) => (
        <th key={index} className='weather__items__column'>
            <i className={`fa-solid ${icon}`} />
        </th>
        ))}
    </>
    );
};