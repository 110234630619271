import React, { useState } from 'react'
import './css/SensorMapDisplay.css'
import { Button } from '@mui/material'
import { NormalButton } from '../../ReusableComponents/Buttons'
import { useCommulativeStore } from '../../store/mapStore'
import Legends from './Legends'

export default function SensorMapDisplay({ isWeather, isSensor }) {
  const setDay = useCommulativeStore((state) => state.setDay)

  const [activeButton, setActiveButton] = useState(3);
  const [selectDay, setSelectDay] = useState({
    "weather": isWeather.commulative_three,
    "sensor": isSensor.commulative_three
  })
  

  //console.log(result)
  const handleCommulativeThirdDay = () => {
    const dayThird = 3
    setDay(dayThird)
    setActiveButton(dayThird)
    setSelectDay({
      "weather": isWeather.commulative_three,
      "sensor": isSensor.commulative_three
    })
  }

  const handleCommulativeFifthDay = () => {
    const dayFifth = 5
    setDay(dayFifth)
    setActiveButton(dayFifth)
    setSelectDay({
      "weather": isWeather.commulative_five,
      "sensor": isSensor.commulative_five
    })
  }

  const handleCommulativeSevenDay = () => {
    const daySeven = 7
    setDay(daySeven)
    setActiveButton(daySeven)
    setSelectDay({
      "weather": isWeather.commulative_seven,
      "sensor": isSensor.commulative_seven
    })
  }

  return (
    <div className='sensors__container'>
      <Legends/>
      <div className='sensors__box'>
          <div className='sensor__header'>
            <h4>Commulative Days</h4>
            <div className='commulative__actions'> 
              <NormalButton
                name={7}
                onClick={handleCommulativeSevenDay}
                isActive={activeButton === 7}
              />
              <NormalButton
                name={5}
                onClick={handleCommulativeFifthDay}
                isActive={activeButton === 5}
              />
              <NormalButton
                name={3}
                onClick={handleCommulativeThirdDay}
                isActive={activeButton === 3}
              />
            </div>
          </div>

          <div className='weather__data__icons'>
              <h4>Weather</h4>
              <div className='sensor__items'>
                <label>Temperature:</label> <label><b>{(selectDay?.weather?.average_temperature ?? 0).toFixed(1) }</b></label>
                <label>Humidity:</label> <label><b>{(selectDay?.weather?.average_humidity ?? 0).toFixed(1)}</b></label>
                <label>Rainfall:</label> <label><b>{(selectDay?.weather?.average_rainfall ?? 0).toFixed(1)}</b></label>
              </div>
          </div>

          <div className='soil__sensors__icons'>
            <h4>Soil</h4>
            <div className='sensor__items'>
              <label>Moisture:</label> <label><b>{(selectDay?.sensor?.average_moisture ?? 0).toFixed(1)}</b> </label>
              <label>Temp(Moisture):</label> <label><b>{(selectDay?.sensor?.average_temperature_moisture ?? 0).toFixed(1)}</b> </label>
              <label>pH:</label> <label><b>{(selectDay?.sensor?.average_pH ?? 0).toFixed(1)}</b> </label>
              <label>Temp(pH):</label> <label><b>{(selectDay?.sensor?.average_temperature_pH ?? 0).toFixed(1)}</b></label>
            </div>
          </div>
      </div>
    </div>
  )
}
