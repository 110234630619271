
import './css/Sidebar.css'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion';
import bansot_logo from '..//assets/bansot_logo.png'
import { useContext } from 'react';
import { 
  //UserContext, 
  AccountDropMenuContext,
  ToggleSidebarContext, 
  SidebarLinkActivate, 
  UserContext} 
  from '../Context/ContextProvider'
import { useMediaQuery } from 'react-responsive';
import { Tooltip as ReactToolTip } from 'react-tooltip';
import { MdDashboard } from "react-icons/md"
import { PiLeafFill } from "react-icons/pi"
import { FaCloud, FaCalendar, FaRegMap } from "react-icons/fa"
import { HiDocumentReport } from "react-icons/hi"

  
export default function Sidebar() {

  const { toggleSidebarValue, isToggleSidebarValue } = useContext(ToggleSidebarContext)
  const { isAccountDropValue } = useContext(AccountDropMenuContext)
  const { userData } = useContext(UserContext)
  const { activeLink, isActiveLink} = useContext(SidebarLinkActivate)

  //console.log(activeLink)
  ///console.log("USER: ",userData)
  const menuItems = [
    { 
      to: "/app/dashboard", 
      icon: <MdDashboard size={'25px'} />, 
      text: "Dashboard", 
      id: 'tooltip__dashboard',
      roles: [''] // Both 'user' and 'admin' can access
    },
    { 
      to: "/app/farm", 
      icon: <PiLeafFill size={'25px'} />, 
      text: "Farm", 
      id: 'tooltip__farm',
      roles: ['Admin', 'Manager', 'Supervisor','Customer', 'Drone Operator'] // Only 'admin' can access
    },
    { 
      to: "/app/weather", 
      icon: <FaCloud size={'25px'} />, 
      text: "Weather", 
      id: 'tooltip__weather',
      roles: ['Admin'] // Both 'user' and 'admin' can access
    },
    { 
      to: "/app/intervention", 
      icon: <FaCalendar size={'25px'} />, 
      text: "Intervention", 
      id: 'tooltip__intervention',
      roles: ['Admin', 'Manager', 'Customer'] // Only 'admin' can access
    },
    { 
      to: "/app/reports", 
      icon: <HiDocumentReport size={'25px'} />, 
      text: "Report", 
      id: 'tooltip__reports',
      roles: ['Admin', 'Manager', 'Supervisor','Customer'] // Both 'user' and 'admin' can access
    },
    // { 
    //   to: "/app/survey", 
    //   icon: <FaRegMap size={'25px'} />, 
    //   text: "Survey", 
    //   id: 'tooltip__survey',
    //   roles: ['Admin', 'Manager', 'Supervisor','Customer'] // Both 'user' and 'admin' can access
    // },
  ];

  const handleClickLink = (to) => {
    isActiveLink(to)
    isAccountDropValue(false)
    isToggleSidebarValue(false)
  }
  
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const displayStyle = toggleSidebarValue ? 'block' : 'none';
  const toggleAnimate = toggleSidebarValue ? isMobile ? '100%' : '18%' :  '70px' 

  return (
    <motion.div
      key={toggleSidebarValue}
      style={{display: isMobile && !toggleSidebarValue ? 'none'  : 'flex'}}
      initial={{ width: isMobile ? '0px' : '70px' }}
      animate={{ width:  toggleAnimate }}
      transition={{ duration: 0.1 }}
      className='sidebar-container'
    >
      <div className='sidebar__wrapper'>
      <div className='sidebar-logo-container'>
        <img 
          className='bansot__logo'
          src={ bansot_logo } alt='bansot_alt'
        />
        <h1 style={{ display: toggleSidebarValue ? '' : 'none'}}>BANAT App</h1>
      </div>
      <div className='sidebar-container-items'>
        <ul>
        {menuItems
          .filter(item => item.roles.includes(userData.role)) // Filter items based on role
          .map((item) => (
            <li key={item.to} className='row-items'>
              <Link to={item.to} 
                    onClick={() => handleClickLink(item.to)} 
                    id={`${item.to === activeLink ? "active" : ""}`} 
                    data-tooltip-id={item.id}
                    data-tooltip-content={item.text}
                > 
                <div id='row-icon'>
                  {item.icon}
                </div>
                <div id='row-title' className='row__sidebar__title' 
                      style={{ display: displayStyle }}>
                  {item.text}
                </div>
              </Link>
              {!isMobile && (
                <ReactToolTip 
                  place='right'
                  id={item.id}/>
                )}
            </li>
        ))}
      </ul>
      </div>
      </div>
    </motion.div>
  )
}
