import React, { useContext, useState } from 'react'
import './css/ModalAccountFields.css'
import { toast } from 'react-toastify'
import { BeatLoader } from 'react-spinners'
import { AddUser } from '..//../api/ApiMethods'
import { useQueryClient } from '@tanstack/react-query'
import { motion } from 'framer-motion'
import { PiWarningCircleFill } from "react-icons/pi";
import Button from '@mui/material/Button'
import { ClipLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom'
import { TextField } from '@mui/material'
import { UserContext } from '../../Context/ContextProvider'

//import { useQueryClient } from '@tanstack/react-query'

export default function ModalAccountFields({isAccountModal}) {
    const { userData } = useContext(UserContext)
    console.log(userData)
    const navigate = useNavigate()
    const ButtonProperty = {
      size: 'small',
      width: '85px',
      widthLonLat: '120px',
  }

    const [form, setForm] = useState({
        first_name: "",
        last_name: "",
        company: "",
        username: "",
        email: "",
        password: ""
      })

    const [error, setError] = useState({
      first_name: "",
      last_name: "",
      company: "",
      username: "",
      email: "",
      password: ""
    });

    const { mutate: addUserPost, isLoading } = AddUser()
    const queryClient = useQueryClient()


    const handleInputChange = (e) =>{
        setForm({
          ...form,
          [e.target.name]: e.target.value,
        })

        // Clear error for the respective field when the user starts typing
        setError((prevError) => ({
          ...prevError,
          [e.target.name]: ""
        }));
      }

    const submitform = async(e) =>{
        e.preventDefault()
        const user_data = new FormData()

        // LOGIC IS IF ROLE IS NOT ADMIN USE THE INPUT 
        for (const key in form) {
          if (key === 'company' && userData?.role != 'Admin') {
            user_data.append(key, userData?.company);
          } else {  
            user_data.append(key, form[key]);
          }
        }
  
        // Loop to display the FormData contents
        for (const pair of user_data.entries()) {
          console.log(pair[0] + ': ' + pair[1]);  // pair[0] is the key, pair[1] is the value
        }
        addUserPost(user_data, {
          onSuccess: (data) =>{
            console.log("Success", data)
            setForm({
              first_name: "",
              last_name: "",
              company: "",
              username: "",
              email: "",
              password: ""
            })
  
            toast.success('Successfully created user', {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
              });
  
              setError('')
              isAccountModal(false)
              queryClient.invalidateQueries('fetch-users')
          },
          onError: (error) =>{
            //console.clear()
            console.log(`error -  ${JSON.stringify(error.response.data)}`)
            setError(error.response.data)
            toast.error('Please fill up the fields.', {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "light",
              });
          }
        })
      }

  return (
    <motion.div 
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3}}
        className='account__fields'>
          <div className='title__header'>
            <h3>Create Account</h3>
          </div>

          <form onSubmit={submitform}>

            <div className='form__fields__items'>
              
              <div className='form__row'>
                <TextField
                  error = {error?.first_name ? true : false}
                  fullWidth 
                  //disabled = {isLoading}
                  name = 'first_name'
                  value = {form.first_name}
                  id = "outlined-basic" 
                  label = "First Name" 
                  variant = "outlined" 
                  size={ButtonProperty.size}
                  onChange = {handleInputChange}
                />
              </div>

              <div className='form__row'>
                <TextField
                  error = {error?.last_name ? true : false}
                  fullWidth 
                  //disabled = {isLoading}
                  name = 'last_name'
                  value = {form.last_name}
                  id = "outlined-basic" 
                  label = "Last Name" 
                  variant = "outlined" 
                  size={ButtonProperty.size}
                  onChange = {handleInputChange}
                />
              </div>

              { 
              userData?.role === 'Admin' ?
                <div className='form__row'>
                  <TextField
                    error = {error?.company ? true : false}
                    fullWidth 
                    //disabled = {isLoading}
                    name = 'company'
                    value = {form.company}
                    id = "outlined-basic" 
                    label = "Company" 
                    variant = "outlined" 
                    size={ButtonProperty.size}
                    onChange = {handleInputChange}
                  />
                </div>
                :
                ''
              }


              <div className='form__row'>
                <TextField
                  error = {error?.username ? true : false}
                  fullWidth 
                  //disabled = {isLoading}
                  name = 'username'
                  value = {form.username}
                  id = "outlined-basic" 
                  label = "Username" 
                  variant = "outlined" 
                  size={ButtonProperty.size}
                  onChange = {handleInputChange}
                />
              </div>

              <div className='form__row'>
                <TextField
                  error = {error?.email ? true : false}
                  fullWidth 
                  //disabled = {isLoading}
                  name = 'email'
                  value = {form.email}
                  id = "outlined-basic" 
                  label = "Email" 
                  variant = "outlined" 
                  size={ButtonProperty.size}
                  onChange = {handleInputChange}
                />
              </div>

              <div className='form__row'>
                <TextField
                  error = {error?.password ? true : false}
                  fullWidth 
                  //disabled = {isLoading}
                  name = 'password'
                  value = {form.password}
                  id = "outlined-basic" 
                  label = "Password" 
                  variant = "outlined" 
                  size={ButtonProperty.size}
                  onChange = {handleInputChange}
                />
              </div>
            </div>

            <div className='form__buttons'>
              <Button
                style={{
                  backgroundColor:  'var(--no_background)',
                  color: 'var(--fontColor__secondary)',
                  textTransform: 'capitalize',
                  boxShadow: 'none',
                }}
                variant="contained"
                onClick={()=>isAccountModal(false)}
                >
                <span>Back</span>
              </Button>

            <Button 
              style={{
                backgroundColor:  'var(--primary__color)',
                textTransform: 'capitalize'
              }}
              disabled={isLoading ? true : false}
              loading={isLoading.toString()}
              loadingposition="start" 
              startIcon={ <ClipLoader color="white" loading={ isLoading } size={15}/>}
              variant="contained"
              type='submit'
              >
              <span>Submit</span>
            </Button>
            </div>
          </form> 
        </motion.div>
  )
}
