import React, { useContext, useState } from 'react'
import './css/RoleModal.css'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { motion } from 'framer-motion'
import { UpdateUserRole } from '../../api/ApiMethods';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { UserContext } from '../../Context/ContextProvider';

const roleHierarchy = {
    Admin: ['Admin', 'Customer', 'Manager', 'Applicator', 'Drone Operator'],
    Customer: ['Manager', 'Applicator', 'Drone Operator'],
    Manager: ['Applicator', 'Drone Operator'],
    Applicator: [],
    Drone_Operator: [],
};

export default function RoleModal({ isRoleModal, userId, role }) {
    const [updateRole, setUpdateRole] = useState(role)
    const { userData } = useContext(UserContext)
    const assignableRoles = roleHierarchy[userData?.role] || [];
    console.log(assignableRoles)
    const { mutate: UpdateRole, isLoading } = UpdateUserRole()
    const queryClient = useQueryClient()

    const handleChange = (e) => {
        setUpdateRole(e.target.value)
    };

    const handleUpdateRole = async(e) => {
        e.preventDefault()
        const role_data = new FormData()

        role_data.append('id', userId)
        role_data.append('role', updateRole)
        
        UpdateRole(
            role_data, {
                onSuccess: (data) =>{
                console.log("Success", data)
  
                toast.success('Successfully created user', {
                position: "top-right",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                pauseOnFocusLoss: false,
                theme: "light",
                });

                queryClient.invalidateQueries('fetch-users')
                isRoleModal(false)
            },
                onError: (error) =>{
                //console.clear()
                console.log(error)
                }
            }
        )
    }

  return (
    <div className='role__container'>
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3}}
        className='role__content'>
            <h4>Update role</h4>
            <div className='role__item'>
                <FormControl fullWidth>
                    <InputLabel id="role-label">Role</InputLabel>
                    <Select
                        labelId="role-label"
                        id="role-select"
                        value={updateRole || 'Customer'}
                        label="Role"
                        onChange={handleChange}
                        >
                        {assignableRoles.map(role => (
                            <MenuItem key={role} value={role}>{role}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div className='role__actions'>
                    <button 
                        id='role__close'
                        onClick={ ()=> isRoleModal(false) }>Close</button>
                    <button 
                        id='role__update'
                        onClick={ handleUpdateRole }>{isLoading ? 'loading' : 'Update'}</button>
                </div>
            </div>
      </motion.div>
    </div>
  )
}
