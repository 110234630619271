import React, { useContext } from 'react'
import './css/WeatherHistory.css'
import { Link } from 'react-router-dom'
import { ToggleSidebarContext } from '../Context/ContextProvider';
import ForecastHeader from './subfeature/ForecastHeader';

export default function WeatherHistory() {
   
    const { toggleSidebarValue, isToggleSidebarValue } = useContext(ToggleSidebarContext)
    const toggle = () =>{
        isToggleSidebarValue(!toggleSidebarValue)
      }

  return (
    <div className='weather__history__container'>
        <div className='weather-title'>
          <i
            className="fa-solid fa-bars toggle__menu"
            onClick={toggle}>
          </i>
        <h2>Weather</h2>
        </div>

        <div className='history__contents'>
            <div className='history__header'>
                <Link to="/app/weather"><i className="fa-solid fa-chevron-left fa-lg"></i></Link>
                <h3>History</h3>
            </div>
            <div className='history__body'>
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th colSpan={9}>Forecasted Data</th>    
                        </tr>
                        <tr>
                            <th></th>
                            <th colSpan={3}>June 6, 2023</th>
                            <th colSpan={3}>June 7, 2023</th>
                            <th colSpan={3}>June 8, 2023</th>
                        </tr>
                        <tr>
                            <th></th>
                            <ForecastHeader />
                            <ForecastHeader />
                            <ForecastHeader />
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>June 6, 2028</th>
                            <th>23</th>
                            <th>23</th>
                            <th>23</th>
                            <th>23</th>
                            <th>23</th>
                            <th>23</th>
                            <th>23</th>
                            <th>23</th>
                            <th>23</th>
                        </tr>
                        <tr>
                            <th>June 7, 2028</th>
                            <th>23</th>
                            <th>23</th>
                            <th>23</th>
                            <th>23</th>
                            <th>23</th>
                            <th>23</th>
                            <th>23</th>
                            <th>23</th>
                            <th>23</th>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  )
}
