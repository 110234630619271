import React, { useContext, useEffect, useState } from 'react'
import './css/AccountManage.css'
import { AccountDropMenuContext, SidebarLinkActivate, UserContext } from '../Context/ContextProvider'
//import { useNavigate } from 'react-router-dom'
import { FetchCompanyUsers, FetchCustomers, FetchUsers } from '../api/ApiMethods'
import ListAccount from './subfeature/ListAccount'
import { ToggleSidebarContext } from '../Context/ContextProvider';
import ModalAccountFields from './subfeature/ModalAccountFields'
import Button from '@mui/material/Button'
import { IoAddOutline } from "react-icons/io5";
import UsersTable from './subfeature/UsersTable'
import { InputAdornment, TextField } from '@mui/material'
import { CiSearch } from 'react-icons/ci'
import { GrFormPrevious } from 'react-icons/gr'
import { MdNavigateNext } from 'react-icons/md'
import { fetchAndProcessUsers } from '../utils/fileUtils'

export default function AccountManage() {
  const { userData } = useContext(UserContext)
  //console.log(userData?.role)
  const [accountModal, isAccountModal] = useState(false)

  const { isAccountDropValue } = useContext(AccountDropMenuContext)
  const { isActiveLink } = useContext(SidebarLinkActivate)
    
  const { toggleSidebarValue, isToggleSidebarValue } = useContext(ToggleSidebarContext)
  
    
  useEffect(()=>{
    isActiveLink("")
    isAccountDropValue(false)
  },[isAccountDropValue,isActiveLink])

  const toggle = () =>{
    isToggleSidebarValue(!toggleSidebarValue)
  }

  const handleCreateModal = () =>{
    isAccountModal(!accountModal)
  }

  return (
    <div className='account__container'>
      <div className='page__title__am'>
              <i
                className="fa-solid fa-bars toggle__menu"
                onClick={toggle}>
              </i>
        <h2>Account Manage</h2>
        <Button 
              style={{
              backgroundColor:  'var(--primary__color)',
              textTransform: 'capitalize'
              }}
              startIcon={<IoAddOutline/>}
              variant="contained"
              onClick={handleCreateModal}
              >
              <span>Create</span>
          </Button>
      </div>

      <div className='account__body__content'> 
        {userData.role === 'Admin' && <Admin />}
        {userData.role === 'Manager' || userData?.role === 'Customer' ? <Manager />: ''}

        {/* conditional render for the account fields modal */}
        {
          accountModal ?
          <div className='modal__account__container'>
            <ModalAccountFields 
              isAccountModal={isAccountModal}/>
          </div>
          : ''
        }
      </div>
    </div>
  )
}


const Admin = () => {
  const [search, isSearch] = useState('')

  const { data: getUsers, isLoading } = FetchCustomers()

  //console.log('fetch user',getUsers)
  //console.log(pageNumber)
    // FILTER UTILITY FUNCTION
    const displayUsers = fetchAndProcessUsers({
      users: getUsers,
      search: search, // Replace with your actual search term
    })
    

    const handleSearch = (e) =>{
      //setPageNumber(1)
      isSearch(e.target.value)
    }

    //console.log(displayUsers)
    
    if(isLoading){
      return <h4>Loading...</h4>
    }

  return(
    <div className='manager__container'>
      <TextField 
        id="search-username" 
        label="Search username..." 
        variant="standard" 
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <CiSearch/>
            </InputAdornment>
          ),
      }}
      style={{width: '70%'}}
      onChange={handleSearch}
      />
      <UsersTable
        displayUsers = { displayUsers }
      />
    </div>
  )
}

const Manager = () => {
  const [pageNumber, setPageNumber] = useState(1)
  const [search, isSearch] = useState('')

  const { userData } = useContext(UserContext)

  const { data: getUsers } = FetchCompanyUsers(userData.company_id)

  //console.log(getUsers)
  // FILTER UTILITY FUNCTION
  const displayUsers = fetchAndProcessUsers({
    users: getUsers,
    search: search, // Replace with your actual search term
    pageNumber: 1, // Replace with your actual page number
    itemsPerPage: 5, // Replace with your desired items per page
    userData,
  })
  
  // Disable next and previous buttons when searching
  const isSearching = search !== '';
  
  const disabledNext = isSearching || pageNumber * 5 >= getUsers?.length;
  const disabledPrev = pageNumber === 1;

  //console.log(displayUsers)

  const handleSearch = (e) =>{
    setPageNumber(1)
    isSearch(e.target.value)
  }

  return(
    <div className='manager__container'>
      <TextField 
        id="search-username" 
        label="Search username..." 
        variant="standard" 
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <CiSearch/>
            </InputAdornment>
          ),
      }}
      style={{width: '70%'}}
      onChange={handleSearch}
      />

      <UsersTable
        pageNumber = { pageNumber }
        setPageNumber = { setPageNumber }
        displayUsers = { displayUsers }
      />
      
      {
        !search && (
          <div className='manager__table__actions'>  
            <button 
              onClick={()=> setPageNumber(page => page -1 )}
              hidden={disabledPrev}
            >
              <GrFormPrevious size={'1.5em'} />
            </button>

            <button 
              onClick={()=> setPageNumber(page => page + 1 )}
              hidden={disabledNext}
            >
              <MdNavigateNext size={'1.5em'} />
            </button>
          </div>
        )
      }

    </div>
  )
}